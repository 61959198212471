import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { MeetingRoomSchedule } from '../../common/MeetingRoomSchedule/MeetingRoomSchedule';
import { ScheduleDatePicker } from '../../common/ScheduleDatePicker/ScheduleDatePicker';
import { useEnhancedNavigate } from '../../../hooks/useEnhancedNavigate';
import { ADMIN_ROUTES, APP_ROUTES } from '../../../constants';
import { meetingRoomBookingApi } from '../../../api/meetingRoomBookingApi';
import { useSnackbarContext } from '../../common/SnackbarProvider/SnackbarProvider';
import { MeetingRoomBooking } from '../../../types';

type AllMeetingRoomsScheduleLayoutProps = {
    adminPage?: boolean;
};

export const AllMeetingRoomsScheduleLayout: React.FC<AllMeetingRoomsScheduleLayoutProps> = ({
    adminPage,
}) => {
    const [queryParams] = useSearchParams();

    const initialDate = queryParams.get('date');

    const [date, setDate] = useState<Dayjs>(initialDate ? dayjs(initialDate) : dayjs());
    const [deleteMeetingRoomBooking] = meetingRoomBookingApi.useDeleteMutation();

    const { showSnackbar } = useSnackbarContext();
    const { scrollNavigate } = useEnhancedNavigate();

    const handleCalendarValueChange = (value: Dayjs | null) => {
        if (value) setDate(value);
    };

    const handleCellClick = (startTime: string, endTime: string, meetingRoomName: string) => {
        const formattedDate = date.format('YYYY-MM-DD');
        const formattedStartTime = dayjs(`${formattedDate} ${startTime}`).format('HH:mm');
        const formattedEndTime = dayjs(`${formattedDate} ${endTime}`).format('HH:mm');

        const pathname = adminPage
            ? ADMIN_ROUTES.MANAGE_MR_BOOKINGS
            : APP_ROUTES.ALL_MEETING_ROOMS_SCHEDULE_ROUTE;

        scrollNavigate({
            top: 0,
            left: 0,
            behavior: 'smooth',
            path: APP_ROUTES.BOOK_MEETING_ROOM,
            prevPagePathname: pathname,
            queryParams: {
                bookingStartTime: formattedStartTime,
                bookingEndTime: formattedEndTime,
                bookingDate: formattedDate,
                meetingRoomName,
                // Required right now, but will not be in the future, so this is
                // just a placeholder that does not affect the functionality.
                meetingRoomId: '1',
            },
        });
    };

    const handleEditClick = (booking: MeetingRoomBooking, meetingRoomName?: string) => {
        const formattedDate = date.format('YYYY-MM-DD');
        const formattedStartTime = dayjs(`${formattedDate} ${booking.startTime}`).format('HH:mm');
        const formattedEndTime = dayjs(`${formattedDate} ${booking.endTime}`).format('HH:mm');

        scrollNavigate({
            top: 0,
            left: 0,
            path: APP_ROUTES.BOOK_MEETING_ROOM,
            queryParams: {
                bookingStartTime: formattedStartTime,
                bookingEndTime: formattedEndTime,
                meetingRoomId: booking.spaceId,
                editedMeetingRoomBookingId: booking.id,
                isMyBookings: 'true',
                meetingRoomName: meetingRoomName || '',
            },
            replace: true,
        });
    };

    const handleDeleteClick = async (bookingId: string) => {
        deleteMeetingRoomBooking({ ids: [bookingId] })
            .unwrap()
            .then(() => {
                showSnackbar('Meeting Room Booking successfully deleted', 'success');
            })
            .catch(() => {
                showSnackbar('Error whily deteleting meeting room booking', 'error');
            });
    };

    return (
        <Box p={3} height="100vh" overflow="auto">
            <ScheduleDatePicker date={date} onChange={handleCalendarValueChange} />
            <MeetingRoomSchedule
                editable={adminPage}
                date={date}
                onEditClick={handleEditClick}
                onCellClick={handleCellClick}
                onDeleteClick={handleDeleteClick}
            />
        </Box>
    );
};
