import React, { useState } from 'react';
import { Box, Card, Divider, Fade, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { useTelegram } from '../../../hooks/useTelegram';

export type BookingsListProps = {
    items: {
        bookingId: string;
        startDate: string;
        endDate?: string | null;
        user: {
            firstName: string;
            lastName: string;
        };
        displayName: string;
    }[];
    onEditClick: (bookingId: string) => void;
    onDeleteClick: (bookingId: string) => void;
};

export const BookingsList: React.FC<BookingsListProps> = ({
    items,
    onEditClick,
    onDeleteClick,
}) => {
    const [deletedItems, setDeletedItems] = useState<typeof items>([]);

    const { colorScheme } = useTelegram();

    const handleEditClick = (bookingId: string) => {
        onEditClick(bookingId);
    };

    const handleDeleteClick = (item: (typeof items)[number]) => {
        setDeletedItems((prev) => prev.concat([item]));
        setTimeout(() => {
            onDeleteClick(item.bookingId);
        }, 300);
    };

    return (
        <Box
            color={colorScheme === 'dark' ? '#fff' : '#000'}
            sx={{ overflowY: 'auto', scrollbarWidth: 'none', paddingX: 1 }}
        >
            {items.length === 0 && (
                <Stack py={2} alignItems="center" gap={2}>
                    <SentimentDissatisfiedIcon />
                    <Typography textAlign="center">
                        Unfortunately there are no results matching your request
                    </Typography>
                </Stack>
            )}
            {items.length > 0 &&
                items.map((item) => (
                    <Fade
                        key={item.bookingId}
                        in={!deletedItems.some((i) => i.bookingId === item.bookingId)}
                        timeout={300}
                    >
                        <Card elevation={2} sx={{ borderRadius: '20px', marginBottom: 2 }}>
                            <Stack direction="row" py="10px">
                                <Stack
                                    px={1}
                                    justifyContent="center"
                                    alignItems="center"
                                    flex="80px"
                                    flexGrow={0}
                                >
                                    <Typography variant="body2">
                                        {dayjs(item.startDate).format('DD MMM')}
                                    </Typography>
                                    {item.endDate && (
                                        <Typography variant="body2">
                                            {dayjs(item.endDate).format('DD MMM')}
                                        </Typography>
                                    )}
                                </Stack>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Box flex={1} px={1}>
                                    <Stack>
                                        <Typography variant="body2">
                                            {item.user.firstName} {item.user.lastName}
                                        </Typography>
                                        <Typography variant="body2">Desk</Typography>
                                        <Typography variant="body2">{item.displayName}</Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Divider orientation="vertical" />
                                </Box>
                                <Stack
                                    px={1}
                                    justifyContent="center"
                                    alignItems="center"
                                    flex="40px"
                                    flexGrow={0}
                                    direction="row"
                                    gap={2}
                                >
                                    <EditIcon
                                        onClick={() => handleEditClick(item.bookingId)}
                                        sx={{ cursor: 'pointer' }}
                                    />
                                    <DeleteIcon
                                        onClick={() => handleDeleteClick(item)}
                                        sx={{ cursor: 'pointer' }}
                                    />
                                </Stack>
                            </Stack>
                        </Card>
                    </Fade>
                ))}
        </Box>
    );
};
