/** @jsxImportSource @emotion/react */
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BaseTextFieldProps, TextField } from '@mui/material';
import { SerializedStyles } from '@emotion/react';
import { useTelegram } from '../../../hooks/useTelegram';
import { useStyles } from '../../../hooks/useStyles';

import styles from './styles';

interface InputProps extends BaseTextFieldProps {
    errorMsg?: any;
    label?: string;
    name: string;
    id?: string;
    register?: UseFormRegister<any>;
    InputProps?: any;
    variant?: 'standard' | 'outlined' | 'filled';
    boxStyles?: SerializedStyles;
    className?: string;
    maxLength?: number;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            errorMsg,
            label,
            register,
            name,
            id,
            variant = 'standard',
            boxStyles,
            className,
            maxLength,
            ...props
        },
        ref,
    ) => {
        const { colorScheme } = useTelegram();

        const classes = useStyles({ colorScheme });

        return (
            <TextField
                className={classes.root}
                inputProps={{ maxLength }}
                css={styles.inputStyles}
                id={id}
                label={label}
                variant={variant}
                {...(register && register(name))}
                error={!!errorMsg}
                helperText={errorMsg || null}
                {...props}
            />
        );
    },
);
