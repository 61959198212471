/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const timePickerWrapperStyles = ({
    colorScheme,
    isEdited,
}: {
    colorScheme: string;
    isEdited: boolean;
}) => {
    return css({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '16px',
        marginTop: '24px',
        '& .inputLabel': {
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiFormHelperText-root': {
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiInputBase-root': {
            color: colorScheme === 'dark' ? '#FFFFFF !important' : '#000000 !important',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
            height: '50px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            padding: '0px 20px',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorScheme === 'dark' ? '#383B40' : '#C4C4C4',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
        },

        '& .MuiFormLabel-root': {
            color: colorScheme === 'dark' ? '#383B40 !important' : '#000000 !important',
        },

        '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: isEdited ? 'none !important' : '#3366FF !important',
        },

        '& .Mui-disabled .MuiOutlinedInput-input': {
            '-webkit-text-fill-color':
                colorScheme === 'dark' ? '#383B40 !important' : '#000000 !important',
        },

        '& .MuiSvgIcon-root': {
            display: isEdited ? 'none !important' : 'initial !important',
        },

        '& .MuiPopover-paper': {
            width: '94px !important',
            minWidth: '94px !important',
            maxWidth: '94px !important',
            padding: '5px 6px !important',
        },
    });
};

const menuItemStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                background: '#3366FF',
                color: '#FFFFFF',
            },
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        width: '100%',
        padding: '5px',
        height: '22px !important',
        minHeight: '22px !important',
        maxHeight: '22px !important',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: `Montserrat !important`,
        fontStyle: 'normal !important',
        borderRadius: '3px',
    });
};

const errorTextStyles = css({
    marginTop: '5px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#EF3340',
});

const styles = {
    timePickerWrapperStyles,
    menuItemStyles,
    errorTextStyles,
};

export default styles;
