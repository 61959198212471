/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface DeleteInfoModalProps {
    open: boolean;
    onClose: () => void;
    isRepeated: boolean;
    onDeleteWorkPlaceHandler: () => void;
}

export function DeleteInfoModal({
    open,
    onClose,
    isRepeated,
    onDeleteWorkPlaceHandler,
}: DeleteInfoModalProps): ReactElement {
    const { colorScheme } = useTelegram();

    const modalDescription = isRepeated
        ? 'This is a recurring event. All subsequent bookings will also be deleted'
        : 'Your booking has been deleted';

    return (
        <Dialog onClose={onClose} open={open} css={styles.dialogStyles({ colorScheme })}>
            <div css={styles.modalWrapperStyles}>
                <div css={styles.headerWrapperStyles}>
                    <div css={styles.closeBtnWrapperStyles}>
                        <IconButton css={styles.closeBtnStyles({ colorScheme })} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <p css={styles.modalInfoTextStyles({ colorScheme })}>{modalDescription}</p>
                </div>
                <div css={styles.btnWrapperStyles}>
                    {isRepeated && (
                        <div css={styles.repeatedBtnWrapperStyles}>
                            <button
                                type="button"
                                css={styles.deleteBtnStyles({ colorScheme })}
                                onClick={onDeleteWorkPlaceHandler}
                            >
                                Delete
                            </button>
                            <button type="button" css={styles.cancelBtnStyles} onClick={onClose}>
                                Cancel
                            </button>
                        </div>
                    )}
                    {!isRepeated && (
                        <button type="button" css={styles.okBtnStyles} onClick={onClose}>
                            OK
                        </button>
                    )}
                </div>
            </div>
        </Dialog>
    );
}
