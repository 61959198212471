/** @jsxImportSource @emotion/react */
import React from 'react';
import { SerializedStyles } from '@emotion/react';

function RepeatIcon({
    color,
    repeatIconStyles,
}: {
    color: string;
    repeatIconStyles: SerializedStyles;
}) {
    return (
        <svg css={repeatIconStyles} viewBox="0 0 512 512" fill={color} height="1em" width="1em">
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M320 120l48 48-48 48"
            />
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M352 168H144a80.24 80.24 0 00-80 80v16M192 392l-48-48 48-48"
            />
            <path
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={32}
                d="M160 344h208a80.24 80.24 0 0080-80v-16"
            />
        </svg>
    );
}

export default RepeatIcon;
