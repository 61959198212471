/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const accordionTitleStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '22px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        marginRight: '10px',
    });
};

const accordionSummaryStyles = css({
    height: '50px',
    boxShadow: 'none',
    padding: '0px !important',
    '& .MuiAccordionSummary-content': {
        alignItems: 'center',
    },
});

const accordionStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '&::before': {
            opacity: 0,
        },
        boxShadow: 'none',
        padding: '0px !important',
        marginBottom: '30px',
        background: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
    });
};

const dividerStyles = ({
    colorScheme,
    isDetails,
}: {
    colorScheme: string;
    isDetails?: boolean;
}) => {
    let marginRight;
    let height;

    if (!isDetails) {
        marginRight = '10px';
        height = '12px';
    } else {
        height = '30px';
    }
    return css({
        width: '1px',
        height,
        background: colorScheme === 'dark' ? '#383B40' : '#C4C4C4',
        marginRight,
    });
};

const loadMoreBtnWrapperStyles = css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
});

const editBtnStyles = css({
    '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
            backgroundColor: '#3366FF',
            color: '#ffffff',
            opacity: '0.7',
            transition: 'all 0.3s ease-out',
        },
    },
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',
    width: '100%',
    height: '36px',
    backgroundColor: '#3366FF',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#ffffff',
    textTransform: 'none',
    transition: 'all 0.3s ease-out',
});

const styles = {
    accordionTitleStyles,
    accordionSummaryStyles,
    accordionStyles,
    dividerStyles,
    loadMoreBtnWrapperStyles,
    editBtnStyles,
};

export default styles;
