import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { OfficeMap } from '../../common/OfficeMap/OfficeMap';
import { useTelegram } from '../../../hooks/useTelegram';
import { DeskManagementDrawer } from './DeskManagementDrawer';

export function DeskManagementLayout() {
    const [selectedSpace, setSelectedSpace] = useState<string>('');
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const { colorScheme } = useTelegram();

    const handleOfficeSpaceClick = (value: string) => {
        setSelectedSpace(value);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const handleOpenDrawer = () => {
        setIsDrawerOpen(true);
    };

    return (
        <Box p={3} bgcolor={colorScheme === 'dark' ? '#1F2023' : '#FFFFFF'} height="100vh">
            <Stack direction="column" justifyContent="space-between" height="100%">
                <Stack direction="column" gap={3} flex={1}>
                    <Box
                        p={1}
                        color={colorScheme === 'dark' ? '#FFF' : '#000'}
                        bgcolor={colorScheme === 'dark' ? '#27292D' : '#E8E8E8'}
                    >
                        <Typography textAlign="center">Update Desk Settings</Typography>
                    </Box>
                    <OfficeMap
                        key={selectedSpace}
                        onOfficeSpaceClick={handleOfficeSpaceClick}
                        selectedSpace={selectedSpace}
                    />
                </Stack>
                {!!selectedSpace && (
                    <Button onClick={handleOpenDrawer} variant="contained">
                        Update Desk {selectedSpace}
                    </Button>
                )}
            </Stack>
            <DeskManagementDrawer
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
                value={selectedSpace}
            />
        </Box>
    );
}
