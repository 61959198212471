import { Box, CircularProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUsersMutation } from '../../../api/rootApi';
import { GetUsersResponse } from '../../../api/types';
import { USER_STATUS } from '../../../constants';
import { useDebounce } from '../../../hooks/useDebounce';
import { useTelegram } from '../../../hooks/useTelegram';
import { Table } from '../../common/table';

const LIMIT = 10;

export function UsersListLayout() {
    const [getUsers, { isLoading }] = useGetUsersMutation();

    const [search, setSearch] = useState('');
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0);

    const [tableRows, setTableRows] = useState<TableData>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);

    const { colorScheme } = useTelegram();

    const navigate = useNavigate();

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setCurrentPage(0);
        setOffset(0);
    };

    const debouncedHandleSearch = useDebounce(handleSearchChange);

    const handlePageChange = (page: number) => {
        setOffset(page * LIMIT);
        setCurrentPage(page);
    };

    const handleRowClick = (row: Record<string, string | null>) => {
        const { id } = row;

        return navigate(id!);
    };

    const fetchUsers = useCallback(async () => {
        const response = await getUsers({
            limit: LIMIT,
            offset,
            search,
            includeCount: true,
            searchFields: ['name', 'surname'],
            filters: {
                status: [USER_STATUS.ACTIVE, USER_STATUS.PENDING],
            },
        });
        if ('data' in response) {
            const mappedResponse = mapUsersListResponseToTable(response.data);
            setTableRows(mappedResponse);
            setCount(response.data.metadata.count);
        }
    }, [offset, search]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <Box p={2.5} maxHeight="100vh" sx={{ overflowY: 'auto' }}>
            <Typography variant="h6" color={colorScheme === 'dark' ? '#fff' : '#000'}>
                Select User
            </Typography>
            <Stack gap={2}>
                <TextField
                    size="small"
                    name="Search"
                    variant="outlined"
                    placeholder="Search by First, Last Name"
                    onChange={debouncedHandleSearch}
                />
                <Paper>
                    {isLoading ? (
                        <Stack justifyContent="center" alignItems="center" py={2}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Table
                            headers={tableHeaders}
                            rows={tableRows}
                            count={count}
                            currentPage={currentPage}
                            pageSize={LIMIT}
                            onPageChange={handlePageChange}
                            onRowClick={handleRowClick}
                        />
                    )}
                </Paper>
            </Stack>
        </Box>
    );
}

function mapUsersListResponseToTable(response: GetUsersResponse) {
    return response.data.map((item) => ({
        id: String(item.telegramUserId),
        name: item.name,
        surname: item.surname,
        team: item.team?.join(', ') ?? null,
    }));
}

type TableData = { name: string; surname: string; team: string | null }[];

const tableHeaders = [
    { text: 'Surname', dataKey: 'surname' },
    { text: 'Name', dataKey: 'name' },
    { text: 'Team', dataKey: 'team' },
];
