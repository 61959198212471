/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface ConfirmModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}

export function ConfirmModal({
    setOpen,
    open,
    onCancel,
    onConfirm,
}: ConfirmModalProps): ReactElement {
    const { colorScheme } = useTelegram();

    return (
        <Dialog
            onClose={() => setOpen(false)}
            open={open}
            css={styles.dialogStyles({ colorScheme })}
        >
            <div css={styles.wrapperStyles}>
                <div css={styles.headerWrapperStyles}>
                    <IconButton css={styles.closeBtnStyles({ colorScheme })} onClick={onCancel}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div>
                    <p css={styles.modalInfoTextStyles({ colorScheme })}>
                        You have already booked this meeting room on this date. If you want to book
                        this Meeting Room for all day, your current booking will be deleted and a
                        new one will be created. Please confirm the action.
                    </p>
                </div>
                <div css={styles.btnWrapperStyles}>
                    <button css={styles.btnStyles} type="button" onClick={onConfirm}>
                        OK
                    </button>
                </div>
            </div>
        </Dialog>
    );
}
