import { z } from 'zod';

export const userManagementFormSchema = z.object({
    name: z.string(),
    surname: z.string(),
    isAdmin: z.boolean(),
    team: z.array(z.string()).nullable(),
});

export type UserManagementFormSchema = z.infer<typeof userManagementFormSchema>;
