import React from 'react';

function NextDateIcon() {
    return (
        <svg
            width="6"
            height="12"
            viewBox="0 0 6 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 11L5.11616 6.88384C5.60227 6.39773 5.60227 5.60227 5.11616 5.11616L1 1"
                stroke="white"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default NextDateIcon;
