/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { MenuItem, TextField } from '@mui/material';
import { TIMES } from '../../../../constants';
import { isTimeDisabled } from '../../../../utils/isTimeDisabled';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useStyles } from '../../../../hooks/useStyles';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { MeetingRoomUser } from '../../../../store/reducers/meetingRooms';

import styles from './styles';

interface StartTimePickerProps {
    setStartTimeValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
    startTimeValue: Dayjs | null | undefined;
    endTimeValue: Dayjs | null | undefined;
    chosenDateState?: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    startTimeError: string;
    editModeActive: boolean;
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    meetingRoomUsers: MeetingRoomUser[];
    disabled?: boolean;
}

function StartTimePicker({
    startTimeValue,
    setStartTimeValue,
    chosenDateState,
    endTimeValue,
    setError,
    startTimeError,
    editModeActive,
    checked,
    setChecked,
    meetingRoomUsers,
    disabled,
}: StartTimePickerProps) {
    const { colorScheme, tgUserId } = useTelegram();

    const editedMeetingRoomBooking = useAppSelector(
        (store) => store.meetingRoomsReducer.editedUserMeetingRoom,
    );

    const allMeetingRoomUsers = useAppSelector(
        (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
    ).filter(
        (m) =>
            m.telegramUserId === tgUserId &&
            m.userMeetingRoomInfo.id !== editedMeetingRoomBooking?.userMeetingRoomInfo.id,
    );

    const classes = useStyles({ colorScheme });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            const nowDay = dayjs().format('YYYY-MM-DD');

            const newStartTime = dayjs(`${nowDay} ${e.target.value}:00`);

            setStartTimeValue(newStartTime);
            setChecked(false);
        }
    };

    return (
        <div
            css={styles.timePickerWrapperStyles({
                colorScheme,
                isEdited: !!disabled,
            })}
        >
            <TextField
                className={classes.root}
                select
                label="Start Time"
                disabled={disabled}
                value={startTimeValue?.format('HH:mm')}
                variant="outlined"
                onChange={handleChange}
                SelectProps={{
                    MenuProps: {
                        PopoverClasses: classes,
                        autoFocus: false,
                    },
                }}
                InputLabelProps={{
                    className: 'inputLabel',
                    shrink: true,
                }}
            >
                {TIMES.map((t) => {
                    const isDisabled = isTimeDisabled(t, meetingRoomUsers);
                    const isDisabledInAllMeetingRoomUsers = isTimeDisabled(t, allMeetingRoomUsers);
                    return (
                        <MenuItem
                            autoFocus={t === startTimeValue?.format('HH:mm')}
                            value={t}
                            disabled={isDisabled || isDisabledInAllMeetingRoomUsers}
                            key={t}
                            css={styles.menuItemStyles({ colorScheme })}
                        >
                            {t}
                        </MenuItem>
                    );
                })}
            </TextField>
            {startTimeError && <p css={styles.errorTextStyles}>{startTimeError}</p>}
        </div>
    );
}

export default StartTimePicker;
