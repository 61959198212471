/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const accordionDetailsStyles = css({
    padding: '0px',
    marginBottom: '10px',
});

const bookingWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: '10px 0px 10px 0px',
        background: colorScheme === 'dark' ? '#27292D' : '#E8E8E8',
        /*   border: colorScheme === 'dark' ? '1px solid #004ED7' : '1px solid #004ED7', */
        borderRadius: '5px',
        height: '50px',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '12px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    });
};

const bookingDateWrapperStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '60px',
    padding: '0px 8px 0px 8px',
});

const dividerStyles = ({
    colorScheme,
    isDetails,
    marginLeft,
}: {
    colorScheme: string;
    isDetails?: boolean;
    marginLeft?: string;
}) => {
    let marginRight;
    let top;
    let height;

    if (!isDetails) {
        marginRight = '10px';
        top = '5px';
        height = '12px';
    } else {
        height = '30px';
    }
    return css({
        position: 'relative',
        top,
        width: '1px',
        height,
        background: colorScheme === 'dark' ? '#383B40' : '#C4C4C4',
        marginRight,
        marginLeft,
    });
};

const bookingInfoWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    width: 'calc(100% - 115px - 2px)',
    padding: '0px 20px 0px 10px',
});

const bookingInfoTextStyles = ({
    colorScheme,
    marginBottom,
}: {
    colorScheme: string;
    marginBottom?: string;
}) => {
    return css({
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '12px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        marginBottom,
    });
};

const editIconStyles = css({
    cursor: 'pointer',
    '&: hover .editIcon': {
        stroke: '#004ED7',
    },
});

const checkboxWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    width: '50px',
    paddingRight: '15px',
});

const removeIconWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '55px',
    padding: '0px 8px 0px 8px',
});

const removeIconStyles = css({
    cursor: 'pointer',
    '&: hover .removeIcon': {
        stroke: '#004ED7',
    },
});

const editBtnStyles = css({
    background: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    width: '17px',
    height: '17px',
});

const removeBtnStyles = css({
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px',
    width: '17px',
    height: '17px',
});

const dateTextStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '12px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    });
};

const repeatIconsStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        position: 'relative',
        top: '5px',
        fontSize: '16px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        margin: '0px 5px',
        left: '1px',
    });
};

const styles = {
    bookingWrapperStyles,
    bookingDateWrapperStyles,
    bookingInfoWrapperStyles,
    bookingInfoTextStyles,
    removeIconWrapperStyles,
    editIconStyles,
    removeIconStyles,
    dividerStyles,
    accordionDetailsStyles,
    checkboxWrapperStyles,
    editBtnStyles,
    removeBtnStyles,
    dateTextStyles,
    repeatIconsStyles,
};

export default styles;
