import React from 'react';
import { useTelegram } from '../hooks/useTelegram';

function BackArrowIcon() {
    const { colorScheme } = useTelegram();
    return (
        <svg
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="arrowIcon"
                d="M7.07 1L1 7.07L7.07 13.14"
                stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                className="arrowIcon"
                d="M17.9999 7.07007H1.16992"
                stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default BackArrowIcon;
