import * as Yup from 'yup';
import { NAME_MAX_LENGTH, SURNAME_MAX_LENGTH } from '../../constants';

const nameSurname = /(?=.*^[A-Za-zА-Яа-я]+$)/;

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(nameSurname, 'Only letters are available')
        .required('Name is required')
        .max(NAME_MAX_LENGTH, 'Name must be maximum 11 character'),
    surname: Yup.string()
        .matches(nameSurname, 'Only letters are available')
        .required('Surname is required')
        .max(SURNAME_MAX_LENGTH, 'Surname must be maximum 14 characters'),
});
