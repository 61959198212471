/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTelegram } from '../../../../hooks/useTelegram';
import MeetingRoom from '../meetingRoom';

import styles from './styles';

interface OfficeTopPartProps {
    chosenMeetingRoomDate?: string;
    isMeetingRoom: boolean;
}

function OfficeTopPart({ chosenMeetingRoomDate, isMeetingRoom }: OfficeTopPartProps) {
    const { colorScheme } = useTelegram();

    return (
        <div css={styles.wrapperStyles}>
            <div css={styles.topManagementWrapperStyles}>
                <div css={styles.topManagementRoomStyles({ colorScheme })}>
                    <p>TOP M.</p>
                </div>
                <MeetingRoom
                    roomName="MR1"
                    chosenMeetingRoomDate={chosenMeetingRoomDate}
                    isMeetingRoom={isMeetingRoom}
                />
            </div>
            <div css={styles.enterStyles({ colorScheme })}>
                <p className="enterText">Enter</p>
            </div>
            <div css={styles.wcStyles({ colorScheme })}>W/C</div>
            <div css={styles.kitchenLawAccWrapperStyles}>
                <div css={styles.kitchenStyles({ colorScheme })}>Kitchen</div>
                <div css={styles.lawAccStyles({ colorScheme })}>
                    <p>L&A</p>
                </div>
                <MeetingRoom
                    roomName="MR2"
                    chosenMeetingRoomDate={chosenMeetingRoomDate}
                    isMeetingRoom={isMeetingRoom}
                />
                <MeetingRoom
                    roomName="MR3"
                    chosenMeetingRoomDate={chosenMeetingRoomDate}
                    isMeetingRoom={isMeetingRoom}
                />
            </div>
        </div>
    );
}

export default OfficeTopPart;
