/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface BookInfoModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    meetingRoomName: string;
    bookedMeetingRoomName: string;
    chosenDate: string;
    setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function BookInfoModal({
    setOpen,
    open,
    meetingRoomName,
    bookedMeetingRoomName,
    chosenDate,
    setChecked,
}: BookInfoModalProps): ReactElement {
    const { colorScheme } = useTelegram();

    const onCloseHandler = () => {
        setOpen(false);
        if (setChecked) {
            setChecked(false);
        }
    };

    const meetingRoomNumber = bookedMeetingRoomName.split('')[2];

    return (
        <Dialog
            onClose={() => setOpen(false)}
            open={open}
            css={styles.dialogStyles({ colorScheme })}
        >
            <div css={styles.modalWrapperStyles}>
                <div css={styles.headerWrapperStyles}>
                    <div css={styles.closeBtnWrapperStyles}>
                        <IconButton
                            css={styles.closeBtnStyles({ colorScheme })}
                            onClick={onCloseHandler}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h3 css={styles.headerTextStyles}>
                            {meetingRoomName} - {chosenDate}
                        </h3>
                    </div>
                </div>
                <div>
                    <p css={styles.modalInfoTextStyles({ colorScheme })}>
                        You have already booked meeting room {meetingRoomNumber} at this time.
                        Please check your booking: Menu - My bookings - Meeting Room
                    </p>
                </div>
            </div>
        </Dialog>
    );
}
