/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const formStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '& .inputLabel': {
            fontWeight: 600,
            fontSize: '16px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiFormHelperText-root': {
            fontWeight: 600,
            fontSize: '16px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiInputBase-root': {
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '18px',
            height: '50px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            padding: '0px 14px',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorScheme === 'dark' ? '#383B40' : '#C4C4C4',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '18px',
        },

        '& .MuiFormLabel-root': {
            color: colorScheme === 'dark' ? '#383B40 !important' : '#000000 !important',
        },

        '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorScheme === 'dark' ? '#3366FF !important' : '#3366FF !important',
        },

        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100vh',
        padding: '20px 20px 20px 20px',
        backgroundColor: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
    });
};

const inputStyles = css({
    width: '100%',
    padding: '10px',
    marginTop: '15px',
    color: 'var(--tg-theme-text-color)',
});

const inputWrapperStyles = css({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    margin: '15px 0px',
});

const errorTextStyles = css({
    marginTop: '5px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '14px',
    color: '#EF3340',
});

const setPeriodBtnStyles = css({
    '&:disabled': {
        cursor: 'default',
        backgroundColor: 'gray',
    },
    '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
            opacity: '0.7',
        },
    },
    padding: ' 15px 15px',
    backgroundColor: 'var(--tg-theme-button-color)',
    border: 'none',
    borderRadius: '4px',
    color: 'var(--tg-theme-button-text-color)',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    fontSize: '18px',
    textTransform: 'uppercase',
});

const datePickerStyles = css({});

const btnWrapperStyles = css({
    marginTop: '10px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
});

const headerTextStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
        marginBottom: '20px',
    });
};

const styles = {
    formStyles,
    inputStyles,
    inputWrapperStyles,
    errorTextStyles,
    setPeriodBtnStyles,
    datePickerStyles,
    btnWrapperStyles,
    headerTextStyles,
};

export default styles;
