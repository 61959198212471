/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const wrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    position: 'relative',
    justifyContent: 'space-between',
});

const firstColumnWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
});

const spaceRightColumnStyles = css({
    display: 'flex',
    flexDirection: 'column',
});

const spaceLeftColumnStyles = css(
    mq({
        display: 'flex',
        flexDirection: 'column',
        marginRight: ['4.15px', '3.65px', '5px', '5px'],
    }),
);

const secondColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    }),
);

const thirdColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    }),
);

const fourthColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    }),
);

const emptyRowStyles = css(
    mq({
        width: ['29.89px', '26.88px', '35px', '35px'],
        height: ['56.49px', '41.47px', '55px', '55px'],
        backgroundColor: 'inherit',
        marginBottom: ['4.15px', '3.45px', '5px', '5px'],
    }),
);

const fifthColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100%',
        alignItems: 'flex-end',
    }),
);

const sixthColumnWrapperStyles = css({
    marginLeft: ['20px', '24px', '20px', '20px'],
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100%',
    alignItems: 'flex-end',
});

const seventhColumnWrapperStyles = css({
    marginLeft: ['20px', '24px', '20px', '20px'],
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    alignItems: 'flex-end',
});

const meetingRoomStyles = ({ isMeetingRoom }: { isMeetingRoom: boolean }) => {
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                opacity: isMeetingRoom ? '0.7' : 'none',
            },
        },
        cursor: isMeetingRoom ? 'pointer' : 'default',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '50px',
        minHeight: '50px',
        backgroundColor: '#e0d5f5',
        color: 'black',
        border: isMeetingRoom ? '1px solid #8342ff' : '1px solid white',
    });
};

const thirdMeetingSpaceStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '50px',
    minHeight: '50px',
    backgroundColor: '#e0d5f5',
    color: 'black',
    border: '1px solid white',
    borderTop: 'none',
});

const bottomEnterStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    backgroundColor: '#51ad51',
    color: 'white',
    border: '1px solid white',
    borderRight: '1px solid white',
    height: '50px',
    marginTop: '50px',
    minWidth: '50px',
    fontSize: '12px',
});

const closeBtnStyles = css({
    position: 'absolute',
    top: '-50px',
    left: '-10px',
});

const headerWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minWidth: '100%',
});

const topManagementWrapperStyles = css(
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: ['72px', '70px', '80px', '80px'],
    }),
);

const topManagementRoomStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            '& p': {
                textAlign: 'center',
                fontWeight: 600,
                fontSize: ['8px', '8px', '10px', '10px'],
                lineHeight: '12px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            width: ['41.49px', '36.47px', '50px', '50px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            borderRadius: '5px',
        }),
    );
};

const enterStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            '& .enterText': {
                transform: 'rotate(-90deg)',
                fontWeight: 600,
                fontSize: ['8px', '8px', '10px', '10px'],
                lineHeight: '12px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            width: ['16.6px', '21.88px', '30px', '30px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            borderRadius: '5px',
            marginRight: ['4.15px', '3.53px', '5px', '5px'],
        }),
    );
};

const wcStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            minWidth: ['202.98px', '192.94px', '220px', '220px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            fontSize: ['8px', '8px', '10px', '10px'],
            fontWeight: 600,
            lineHeight: '12px',
            marginRight: ['4.15px', '3.53px', '5px', '5px'],
            borderRadius: '5px',
        }),
    );
};

const kitchenLawAccWrapperStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
});

const kitchenStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            width: ['142.98px', '132.94px', '160px', '160px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            fontWeight: 600,
            fontSize: ['8px', '8px', '10px', '10px'],
            lineHeight: '12px',
            borderRadius: '5px',
        }),
    );
};

const lawAccStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            '& p': {
                textAlign: 'center',
                fontWeight: 600,
                fontSize: ['8px', '8px', '10px', '10px'],
                lineHeight: '12px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            width: ['41.49px', '36.47px', '50px', '50px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            borderRadius: '5px',
            marginTop: ['4.15px', '3.53px', '5px', '5px'],
        }),
    );
};

const officeMapWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            '::-webkit-scrollbar': {
                display: 'none',
            },
            display: 'flex',
            padding: '10px',
            maxWidth: ['505px', '480px', '570px', '570px'],
            maxHeight: ['487px', '436px', '575px', '575px'],
            flexDirection: 'column',
            cursor: 'auto',
            overflow: 'scroll',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            backgroundColor: colorScheme === 'dark' ? '#27292D' : '#E8E8E8',
            marginBottom: '20px',
        }),
    );
};

const windowWrapperStyles = css(
    mq({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: ['483px', '460px', '550px', '550px'],
    }),
);

const windowStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'row',
            height: ['25px', '22px', '30px', '30px'],
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            backgroundColor: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            padding: '11px 0px',
            fontWeight: 600,
            fontSize: ['8px', '8px', '10px', '10px'],
            lineHeight: '12px',
            textTransform: 'uppercase',
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        }),
    );
};

const officeMapContainerStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            padding: '20px 20px 20px 20px',
            height: '100vh',
            background: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
            overflowY: 'scroll',
            overflowX: 'hidden',
        }),
    );
};

const styles = {
    wrapperStyles,
    firstColumnWrapperStyles,
    spaceRightColumnStyles,
    spaceLeftColumnStyles,
    secondColumnWrapperStyles,
    thirdColumnWrapperStyles,
    fourthColumnWrapperStyles,
    emptyRowStyles,
    fifthColumnWrapperStyles,
    sixthColumnWrapperStyles,
    seventhColumnWrapperStyles,
    meetingRoomStyles,
    thirdMeetingSpaceStyles,
    bottomEnterStyles,
    closeBtnStyles,
    headerWrapperStyles,
    topManagementWrapperStyles,
    topManagementRoomStyles,
    enterStyles,
    wcStyles,
    kitchenLawAccWrapperStyles,
    kitchenStyles,
    lawAccStyles,
    officeMapWrapperStyles,
    windowStyles,
    windowWrapperStyles,
    officeMapContainerStyles,
};

export default styles;
