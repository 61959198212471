/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import { useTelegram } from '../../../hooks/useTelegram';

import styles from './styles';

const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

export const MINUTES_IN_HOUR = 60;

interface TimeRowProps {
    time: number;
    allMeetingRooms?: boolean;
}

function TimeRow({ time, allMeetingRooms }: TimeRowProps) {
    const { colorScheme } = useTelegram();

    const formattedTime = dayjs().set('hours', time).format('h a');

    return (
        <Grid container css={styles.timeRowStyles()}>
            <div
                className="formattedTimeCell"
                css={styles.formattedTimeCellStyles({
                    formattedTime,
                    colorScheme,
                })}
            >
                {formattedTime}
            </div>
            <div
                className="timeCell"
                css={styles.timeCellStyles({
                    formattedTime,
                    colorScheme,
                    allMeetingRooms,
                    meetingRoomName: 'MR1',
                })}
            />
            {allMeetingRooms && (
                <>
                    <div
                        className="timeCell"
                        css={styles.timeCellStyles({
                            formattedTime,
                            colorScheme,
                            allMeetingRooms,
                            meetingRoomName: 'MR2',
                        })}
                    />
                    <div
                        className="timeCell"
                        css={styles.timeCellStyles({
                            formattedTime,
                            colorScheme,
                            allMeetingRooms,
                            meetingRoomName: 'MR3',
                        })}
                    />
                </>
            )}
        </Grid>
    );
}

export default TimeRow;
