import React from 'react';

function CloseIcon() {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.51465"
                width="12"
                height="2"
                rx="1"
                transform="rotate(45 1.51465 0)"
                fill="#C4C4C4"
            />
            <rect
                x="9.9082"
                y="1.41431"
                width="12"
                height="2"
                rx="1"
                transform="rotate(135 9.9082 1.41431)"
                fill="#C4C4C4"
            />
        </svg>
    );
}

export default CloseIcon;
