/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const wrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '20px 20px 20px 20px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        backgroundColor: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
    });
};

const goBackWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: '10px',
});

const backIconStyles = css({
    position: 'relative',
    left: '4px',
    color: `var(--tg-theme-text-color)`,
});

const titleStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
    });
};

const styles = {
    wrapperStyles,
    goBackWrapperStyles,
    backIconStyles,
    titleStyles,
};

export default styles;
