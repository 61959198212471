/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

export enum REMOVE_RADIO_VALUES {
    ALL = 'all',
    CURRENT = 'current',
    UPCOMING = 'upcoming',
}

interface RemoveRepeatBookingModalProps {
    open: boolean;
    onClose: () => void;
    onDeleteWorkPlaceHandler: () => void;
    removeRadioValue: REMOVE_RADIO_VALUES;
    setRemoveRadioValue: (value: REMOVE_RADIO_VALUES) => void;
}

export function RemoveRepeatBookingModal({
    open,
    onClose,
    onDeleteWorkPlaceHandler,
    removeRadioValue,
    setRemoveRadioValue,
}: RemoveRepeatBookingModalProps): ReactElement {
    const { colorScheme } = useTelegram();

    const onRemoveRadioValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRemoveRadioValue((event.target as HTMLInputElement).value as REMOVE_RADIO_VALUES);
    };

    return (
        <Dialog onClose={onClose} open={open} css={styles.dialogStyles({ colorScheme })}>
            <div css={styles.modalWrapperStyles}>
                <div css={styles.headerWrapperStyles}>
                    <div css={styles.closeBtnWrapperStyles}>
                        <IconButton css={styles.closeBtnStyles({ colorScheme })} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div css={styles.contentWrapperStyles}>
                    <RadioGroup value={removeRadioValue} onChange={onRemoveRadioValueChange}>
                        <FormControlLabel
                            value={REMOVE_RADIO_VALUES.CURRENT}
                            control={<Radio />}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontWeight: 600,
                                    fontSize: '10px',
                                    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
                                },
                            }}
                            label="Delete current (selected)"
                        />
                        <FormControlLabel
                            value={REMOVE_RADIO_VALUES.ALL}
                            control={<Radio />}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontWeight: 600,
                                    fontSize: '10px',
                                    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
                                },
                            }}
                            label="Delete everything"
                        />
                        <FormControlLabel
                            value={REMOVE_RADIO_VALUES.UPCOMING}
                            control={<Radio />}
                            sx={{
                                '& .MuiFormControlLabel-label': {
                                    fontWeight: 600,
                                    fontSize: '10px',
                                    color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
                                },
                            }}
                            label="Delete all upcoming ones (past ones are saved in history)"
                        />
                    </RadioGroup>
                </div>
                <div css={styles.btnWrapperStyles}>
                    <div css={styles.repeatedBtnWrapperStyles}>
                        <button
                            type="button"
                            css={styles.deleteBtnStyles}
                            onClick={onDeleteWorkPlaceHandler}
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            css={styles.cancelBtnStyles({ colorScheme })}
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
