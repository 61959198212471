/* eslint-disable @typescript-eslint/no-magic-numbers */
export const APP_ROUTES = {
    HOME_PAGE: '/',
    REGISTRATION_PAGE: '/registration',
    USER_PROFILE_PAGE: '/user-profile',
    BOOK_PERIOD_PAGE: '/book-period',
    MEETING_ROOM_TIMETABLE_PAGE: '/meeting-room-timetable',
    BOOK_MEETING_ROOM: '/book-meeting-room',
    MY_BOOKINGS_ROUTE: '/my-bookings',
    ALL_MEETING_ROOMS_SCHEDULE_ROUTE: '/all-meeting-rooms-schedule',
    SIGN_IN: '/sign-in',
    CONFIRM_GOOGLE_LOGIN: '/confirm-google-login',
};

export const ADMIN_ROUTES = {
    USERS_LIST: '/users',
    USER_MANAGEMENT_PROFILE: '/users/:id',
    UPDATE_DESK_SETTINGS: '/update-desk-settings',
    MANAGE_DESK_BOOKINGS: '/manage-desk-bookings',
    MANAGE_MR_BOOKINGS: '/manage-meeting-room-bookings',
};

export const API_ROUTE = process.env.REACT_APP_API_URL;

export const API_V1_ROUTE = `${API_ROUTE}/api/v1`;

export const USERS_ROUTE = '/users';

export const USERS_ROUTES = {
    GET_USER_ROUTE: `${USERS_ROUTE}/get-user`,
    CHANGE_USER_ROUTE: `${USERS_ROUTE}/change-user`,
    REGISTER_USER_ROUTE: `${USERS_ROUTE}/registration`,
    GET_USERS: `${USERS_ROUTE}/list`,
};

export const OFFICE_SPACES_ROUTE = '/office-spaces';

export const OFFICE_SPACES_ROUTES = {
    CREATE_BOOKING_PERIOD_ROUTE: `${OFFICE_SPACES_ROUTE}/create-booking-period`,
    GET_MEETING_ROOM_USERS_ROUTE: `${OFFICE_SPACES_ROUTE}/meeting-room-users`,
    BOOK_MEETING_ROOM_ROUTE: `${OFFICE_SPACES_ROUTE}/book-meeting-room`,
    GET_BOOKED_OFFICE_SPACES_ROUTE: `${OFFICE_SPACES_ROUTE}/booked-office-spaces`,
    BOOK_OFFICE_SPACE_ROUTE: `${OFFICE_SPACES_ROUTE}/book-space`,
    REMOVE_OFFICE_SPACE_ROUTE: `${OFFICE_SPACES_ROUTE}/remove-space`,
    EDIT_OFFICE_SPACE_ROUTE: `${OFFICE_SPACES_ROUTE}/edit-space`,
    GET_ALL_MEETING_ROOMS_USERS_ROUTE: `${OFFICE_SPACES_ROUTE}/all-meeting-rooms-users`,
    GET_PARTICULAR_BOOKING_ROUTE: `${OFFICE_SPACES_ROUTE}/particular-booking`,
    REMOVE_MEETING_ROOM_BOOKING_ROUTE: `${OFFICE_SPACES_ROUTE}/remove-meeting-room-booking`,
    EDIT_MEETING_ROOM_BOOKING_ROUTE: `${OFFICE_SPACES_ROUTE}/edit-meeting-room-booking`,
    GET_PARTICULAR_MEETING_ROOM_BOOKING_ROUTE: `${OFFICE_SPACES_ROUTE}/particular-meeting-room-booking`,
    GET_ALL_BOOKINGS_ROUTE: `${OFFICE_SPACES_ROUTE}/all-bookings`,
    REMOVE_ALL_BOOKINGS_ROUTE: `${OFFICE_SPACES_ROUTE}/remove-all-bookings`,
    UPDATE_OFFICE_SPACE_ROUTE: `${OFFICE_SPACES_ROUTE}/update`,
    LIST: `${OFFICE_SPACES_ROUTE}/list`,
};

export const GOOGLE_ROUTE = '/google';

export const GOOGLE_ROUTES = {
    GOOGLE_AUTH_ROUTE: `${GOOGLE_ROUTE}/auth`,
    GET_GOOGLE_AUTH_TOKEN: `${GOOGLE_ROUTE}/auth-token`,
    GOOGLE_LOGOUT_ROUTE: `${GOOGLE_ROUTE}/logout`,
};

export const MEETING_ROOMS_TIMES = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];

export const NAME_MAX_LENGTH = 11;

export const SURNAME_MAX_LENGTH = 14;

export const MAX_TODAY_TIME = 23;

export const MAX_TIME = 23;

export const MIN_TIME = 7;

export const MEETING_ROOMS = [
    { id: '0933743d-069f-40b9-a5dd-764564d6e5db', name: 'Meeting Room 1' },
    { id: 'a0234d55-d86b-4897-a777-60ef58451a7f', name: 'Meeting Room 2' },
    { id: '4e1bcf94-6d2f-499e-9a71-ea9b5f22cc94', name: 'Meeting Room 3' },
];

export const STORE_TAGS = {
    USER: 'USER',
    OFFICE_SPACES: 'OFFICE_SPACES',
    MEETING_ROOM: 'MEETING_ROOM',
    ALL_MEETING_ROOMS: 'ALL_MEETING_ROOMS',
    ALL_BOOKINGS: 'ALL_BOOKINGS',
    USERS: 'USERS',
};

export const BREAKPOINTS = [391, 467, 510, 560];

const HOURS = [
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];

const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

export const TIMES: string[] = [];

HOURS.forEach((h) => {
    MINUTES.forEach((m) => {
        if (Number(h) === 23 && m === '00') {
            TIMES.push(`${h}:${m}`);
        }
        if (Number(h) > 12 && Number(h) < 23) {
            TIMES.push(`${h}:${m}`);
        }
        if (Number(h) <= 12) {
            TIMES.push(`${h}:${m}`);
        }
    });
});

export const MIN_BOOKING_MINUTES_VALUE = 15;

export const DESK_NUMBERS = new Array(39).fill(0).map((_, i) => String(i + 1));

export enum TAB_VALUES {
    ALL = 'All',
    MEETING_ROOM = 'Meeting room',
    DESK = 'Desk',
}

export enum REPEAT_VALUE {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
}

export enum STATUS_CODE {
    UNAUTHORIZED = 401,
}

export enum USER_TEAM {
    TECHNICAL = 'Technical',
    ADMINISTRATIVE = 'Administrative',
}

export enum USER_STATUS {
    ACTIVE = 'active',
    PENDING = 'pending',
    ARCHIVED = 'archived',
}
