export const getObjectDifferences = (payload: any, defaultValues: any, whitelistKeys: string[]) => {
    const filteredKeys = Object.keys(payload).filter((key) => whitelistKeys.includes(key));
    const differences: any = {};

    filteredKeys.forEach((key) => {
        if (Array.isArray(payload[key])) {
            const areEqual = compareArrays(payload[key], defaultValues[key]);
            if (areEqual) return;
            differences[key] = payload[key];
        }

        if (payload[key] !== defaultValues[key]) {
            differences[key] = payload[key];
        }
    });

    return differences;
};

function compareArrays(arr1: unknown[], arr2: unknown[]) {
    if (arr1?.length !== arr2?.length) return false;

    const sortedArr1 = arr1.sort();
    const sortedArr2 = arr2.sort();

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
