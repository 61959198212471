import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Control, FieldValues, Path, Controller } from 'react-hook-form';

type ControlledSelectProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    options: { label: string; value: string }[];
    disabled?: boolean;
    label?: string;
};

export function ControlledSelect<T extends FieldValues>({
    control,
    name,
    options,
    label,
    disabled,
}: ControlledSelectProps<T>) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <FormControl fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select {...field} disabled={disabled} label={label}>
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    );
}
