import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REPEAT_VALUE } from '../../constants';

export interface MyBooking {
    id: string;
    spaceName: string;
    spaceId: string;
    startDate?: string;
    endDate?: string | null;
    isExpired: boolean;
    isMeetingRoom: boolean;
    date?: string;
    startTime?: string;
    endTime?: string;
    isRepeated?: boolean;
    repeatValue?: REPEAT_VALUE | null;
    repeatEndDate?: string | null;
    isRepeatAlways: boolean;
    repeatBookingId?: string | null;
}

interface AllBookingsState {
    allBookings: MyBooking[];
    expiredAllBookings: MyBooking[];
    officeSpaceBookings: MyBooking[];
    expiredOfficeSpaceBookings: MyBooking[];
    meetingRoomBookings: MyBooking[];
    expiredMeetingRoomBookings: MyBooking[];
    allBookingsCount: number;
    officeSpaceBookingsCount: number;
    meetingRoomBookingsCount: number;
    expiredAllBookingsCount: number;
    expiredOfficeSpaceBookingsCount: number;
    expiredMeetingRoomBookingsCount: number;
}

interface SetAllBookingsPayload {
    allBookings: MyBooking[];
    expiredAllBookings: MyBooking[];
    officeSpaceBookings: MyBooking[];
    expiredOfficeSpaceBookings: MyBooking[];
    meetingRoomBookings: MyBooking[];
    expiredMeetingRoomBookings: MyBooking[];
    allBookingsCount: number;
    officeSpaceBookingsCount: number;
    meetingRoomBookingsCount: number;
    expiredAllBookingsCount: number;
    expiredOfficeSpaceBookingsCount: number;
    expiredMeetingRoomBookingsCount: number;
}

interface RemoveMeetingRoomBookingPayload {
    removeRepeatBooking: MyBooking;
    removeAll: boolean;
    removeUpcoming: boolean;
}

const initialState: AllBookingsState = {
    allBookings: [],
    expiredAllBookings: [],
    officeSpaceBookings: [],
    expiredOfficeSpaceBookings: [],
    meetingRoomBookings: [],
    expiredMeetingRoomBookings: [],
    allBookingsCount: 0,
    officeSpaceBookingsCount: 0,
    meetingRoomBookingsCount: 0,
    expiredAllBookingsCount: 0,
    expiredOfficeSpaceBookingsCount: 0,
    expiredMeetingRoomBookingsCount: 0,
};

export const allBookingsSlice = createSlice({
    name: 'officeSpaces',
    initialState,
    reducers: {
        setAllBookings: (
            state,
            {
                payload: {
                    allBookings,
                    expiredAllBookings,
                    officeSpaceBookings,
                    expiredOfficeSpaceBookings,
                    meetingRoomBookings,
                    expiredMeetingRoomBookings,
                    allBookingsCount,
                    officeSpaceBookingsCount,
                    meetingRoomBookingsCount,
                    expiredAllBookingsCount,
                    expiredOfficeSpaceBookingsCount,
                    expiredMeetingRoomBookingsCount,
                },
            }: PayloadAction<SetAllBookingsPayload>,
        ) => {
            state.allBookings = allBookings;
            state.expiredAllBookings = expiredAllBookings;
            state.officeSpaceBookings = officeSpaceBookings;
            state.expiredOfficeSpaceBookings = expiredOfficeSpaceBookings;
            state.meetingRoomBookings = meetingRoomBookings;
            state.expiredMeetingRoomBookings = expiredMeetingRoomBookings;
            state.allBookingsCount = allBookingsCount;
            state.officeSpaceBookingsCount = officeSpaceBookingsCount;
            state.meetingRoomBookingsCount = meetingRoomBookingsCount;
            state.expiredAllBookingsCount = expiredAllBookingsCount;
            state.expiredOfficeSpaceBookingsCount = expiredOfficeSpaceBookingsCount;
            state.expiredMeetingRoomBookingsCount = expiredMeetingRoomBookingsCount;
        },
        deleteMeetingRoomBooking: (
            state,
            { payload }: PayloadAction<RemoveMeetingRoomBookingPayload>,
        ) => {
            const { removeRepeatBooking, removeAll, removeUpcoming } = payload;

            const removedBooking = state.allBookings.find(
                (b) => b.id === removeRepeatBooking.id && b.date === removeRepeatBooking.date,
            );

            if (removeAll) {
                state.allBookings = state.allBookings.filter((b) => {
                    if (
                        removeRepeatBooking.repeatBookingId &&
                        b.repeatBookingId &&
                        b.repeatBookingId !== removeRepeatBooking.repeatBookingId
                    ) {
                        return true;
                    }

                    if (
                        removeRepeatBooking.repeatBookingId === null &&
                        removeRepeatBooking.id &&
                        (b.repeatBookingId !== removeRepeatBooking.id ||
                            b.id !== removeRepeatBooking.id)
                    ) {
                        return true;
                    }

                    if (
                        removeRepeatBooking.repeatBookingId &&
                        b.repeatBookingId === null &&
                        b.repeatBookingId !== removeRepeatBooking.repeatBookingId &&
                        b.id !== removeRepeatBooking.repeatBookingId
                    ) {
                        return true;
                    }

                    return false;
                });
            }

            if (removeUpcoming) {
                state.allBookings = state.allBookings.filter((b) => {
                    if (
                        (removeRepeatBooking.repeatBookingId &&
                            b.repeatBookingId &&
                            b.repeatBookingId !== removeRepeatBooking.repeatBookingId &&
                            b.id === removeRepeatBooking.repeatBookingId &&
                            removeRepeatBooking.date &&
                            b.date &&
                            new Date(b.date) < new Date(removeRepeatBooking.date)) ||
                        (b.repeatBookingId === removeRepeatBooking.repeatBookingId &&
                            removeRepeatBooking.date &&
                            b.date &&
                            new Date(b.date) < new Date(removeRepeatBooking.date))
                    ) {
                        return true;
                    }
                    if (
                        (removeRepeatBooking.id &&
                            removeRepeatBooking.repeatBookingId === null &&
                            b.repeatBookingId !== removeRepeatBooking.id &&
                            b.id === removeRepeatBooking.id &&
                            removeRepeatBooking.date &&
                            b.date &&
                            new Date(b.date) < new Date(removeRepeatBooking.date)) ||
                        (b.repeatBookingId === removeRepeatBooking.id &&
                            removeRepeatBooking.date &&
                            b.date &&
                            new Date(b.date) < new Date(removeRepeatBooking.date))
                    ) {
                        return true;
                    }

                    return false;
                });
            }

            if (!removeAll && !removeUpcoming) {
                state.allBookings = state.allBookings.filter((b) => b !== removedBooking);
                state.meetingRoomBookings = state.allBookings.filter((b) => b !== removedBooking);
            }
        },
    },
});

export const {
    reducer: allBookingsReducer,
    actions: { setAllBookings, deleteMeetingRoomBooking },
} = allBookingsSlice;
