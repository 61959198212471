import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { DeskManagementFormSchema, deskManagementFormSchema } from './deskManagementForm.schema';
import { USER_TEAM } from '../../../../../constants';
import { ControlledCheckboxGroup } from '../../../../common/ControlledCheckboxGroup/ControlledCheckboxGroup';
import { ControlledSwitch } from '../../../../common/ControlledSwitch/ControlledSwitch';
import { useTelegram } from '../../../../../hooks/useTelegram';
import { OfficeSpaceListResponse } from '../../../../../api/types';
import { officeSpaceApi } from '../../../../../api/officeSpaceApi';
import { useSnackbarContext } from '../../../../common/SnackbarProvider/SnackbarProvider';

type DeskManagementFormProps = {
    data: {
        id: string;
        name: string;
        bookingTeams: USER_TEAM[] | null;
        users?: OfficeSpaceListResponse['data'][number]['users'];
    };
};

export function DeskManagementForm({ data }: DeskManagementFormProps) {
    const {
        formState: { dirtyFields },
        handleSubmit,
        control,
        watch,
        setValue,
        reset,
    } = useForm<DeskManagementFormSchema>({
        resolver: zodResolver(deskManagementFormSchema),
        defaultValues: {
            bookingTeams: data.bookingTeams,
        },
    });

    const [updateOfficeSpace, { isLoading }] = officeSpaceApi.useUpdateOfficeSpaceMutation();

    const { showSnackbar } = useSnackbarContext();

    const { tg } = useTelegram();

    const onSubmit: SubmitHandler<DeskManagementFormSchema> = async (formData) => {
        await updateOfficeSpace({ id: data.id, bookingTeams: formData.bookingTeams })
            .then(() => {
                showSnackbar('Office space successfully updated', 'success');
            })
            .catch(() => {
                showSnackbar('Error occured while updating the office space', 'error');
            });
    };

    const sendData = handleSubmit(onSubmit);

    useEffect(() => {
        tg.onEvent('mainButtonClicked', sendData);

        return () => {
            tg.offEvent('mainButtonClicked', sendData);
        };
    }, []);

    useEffect(() => {
        const isDirty = Object.keys(dirtyFields).length;
        if (isDirty) {
            tg.MainButton.show();
        } else {
            tg.MainButton.hide();
        }
    }, [Object.keys(dirtyFields).length]);

    useEffect(() => {
        if (isLoading) {
            tg.MainButton.showProgress(true);
        } else {
            tg.MainButton.hideProgress();
        }
    }, [isLoading]);

    useEffect(() => {
        tg.MainButton.setText('Update Office Space');
    }, []);

    useEffect(() => {
        reset({
            bookingTeams: data?.bookingTeams,
        });
    }, [data]);

    const isBooked = isDeskBooked(data);

    return (
        <form>
            <ControlledSwitch
                name="outOfService"
                control={control}
                disabled
                label="Out Of Service"
            />
            <ControlledCheckboxGroup
                options={checkboxOptions}
                name="bookingTeams"
                control={control}
                setValue={setValue}
                watch={watch}
                disabled={isBooked}
            />
            {isBooked && (
                <Typography>
                    The desk has active bookings. Please remove all bookings first.
                </Typography>
            )}
        </form>
    );
}

const checkboxOptions = [
    { value: USER_TEAM.TECHNICAL, label: USER_TEAM.TECHNICAL },
    { value: USER_TEAM.ADMINISTRATIVE, label: USER_TEAM.ADMINISTRATIVE },
];

const isDeskBooked = (data: DeskManagementFormProps['data']): boolean => {
    const hasBookings = !!data.users?.length;

    if (!hasBookings) return false;

    const isBookedNow = !!data.users?.some((user) => {
        const now = new Date();
        const isPermanentBooking = user.booking?.startDate && !user.booking.endDate;

        if (isPermanentBooking) return true;

        return (
            dayjs(now).isAfter(user.booking?.startDate) &&
            dayjs(now).isBefore(user.booking?.endDate)
        );
    });

    return isBookedNow;
};
