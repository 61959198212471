import { Alert, Snackbar } from '@mui/material';
import React, { useCallback, useContext, useMemo, useState } from 'react';

type Severity = 'success' | 'error' | 'info' | 'warning';

type SnackbarContextState = {
    showSnackbar: (message: string, severity: Severity) => void;
    closeSnackbar: () => void;
};

const SnackbarContext = React.createContext<SnackbarContextState | null>(null);

export const useSnackbarContext = (): SnackbarContextState => {
    const context = useContext(SnackbarContext);

    if (!context)
        throw Error(
            'Snackbar context is not set. Make sure that you call useSnackbarContext within the context provider.',
        );

    return context;
};

export const SnackbarProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [severity, setSeverity] = useState<Severity>('success');

    const handleClose = () => {
        setOpen(false);
    };

    const showSnackbar = useCallback((msg: string, sev: Severity) => {
        setMessage(msg);
        setSeverity(sev);
        setOpen(true);
    }, []);

    const closeSnackbar = useCallback(() => {
        setMessage('');
        setOpen(false);
    }, []);

    const contextValue = useMemo(
        () => ({
            showSnackbar,
            closeSnackbar,
        }),
        [],
    );

    return (
        <SnackbarContext.Provider value={contextValue}>
            {children}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
