/* eslint-disable @typescript-eslint/no-magic-numbers */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const bookedRowStyles = ({
    isMoreThanHour,
    colorScheme,
    isOneHour,
    isMyBook,
    first,
    last,
    allMeetingRooms,
    meetingRoomName,
    bookingMinutes,
}: {
    isMoreThanHour: boolean;
    colorScheme: string;
    isOneHour: boolean;
    isMyBook: boolean;
    first: boolean;
    last: boolean;
    allMeetingRooms?: boolean;
    meetingRoomName: string;
    bookingMinutes: number;
}) => {
    let paddingTop;
    let justifyContent;
    let alignItems;
    let bgColor;
    let borderColor;
    let color;
    let paddingLeft;
    let borderRadius;
    let hoverObj;
    let flexDirection;
    let flexWrap;
    const fontSize = allMeetingRooms ? ['3px', '8px', '8px', '8px'] : '8px';
    const lineHeight = allMeetingRooms ? ['5px', '10px', '10px', '10px'] : '10px';

    if (isMoreThanHour) {
        paddingTop = allMeetingRooms ? ['10px', '5px', '10px', '10px'] : '20px';
        paddingLeft = allMeetingRooms ? ['3px', '3px', '5px', '5px'] : '10px';
    } else {
        paddingTop = allMeetingRooms ? '5px' : '0px';
        paddingLeft = allMeetingRooms ? ['3px', '3px', '5px', '5px'] : '10px';
    }

    if (isOneHour) {
        paddingTop = allMeetingRooms ? ['10px', '5px', '10px', '10px'] : '0px';
    }

    if (isMyBook) {
        bgColor = '#3366FF';
        borderColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
        color = '#FFFFFF';
        hoverObj = {
            transition: 'all 0.3s ease-out',
            background: '#5f87ff',
        };
    } else if (colorScheme === 'dark') {
        bgColor = '#27292D';
        borderColor = '#383B40';
        color = '#FFFFFF';
    } else {
        color = '#000000';
        bgColor = '#D9D9D9';
        borderColor = '#C4C4C4';
    }

    if (first && !allMeetingRooms) {
        borderRadius = '0px 5px 0px 0px';
    }
    if (last && !allMeetingRooms) {
        borderRadius = '0px 0px 5px 0px';
    }

    if (first && allMeetingRooms && meetingRoomName === 'MR3') {
        borderRadius = '0px 5px 0px 0px';
    }

    if (last && allMeetingRooms && meetingRoomName === 'MR3') {
        borderRadius = '0px 0px 5px 0px';
    }

    if (!isMyBook) {
        hoverObj = {};
    }

    if (allMeetingRooms) {
        flexDirection = bookingMinutes < 40 ? 'row' : 'row';
        alignItems = 'flex-start';
        justifyContent = 'space-between';
        flexWrap = bookingMinutes > 30 ? ['wrap', 'wrap', 'wrap', 'nowrap'] : undefined;
        if (bookingMinutes === 15) {
            paddingTop = ['5px', '5px', '2px', '2px'];
        }
        if (bookingMinutes === 20) {
            paddingTop = ['7px', '7px', '7px', '5px'];
        }
        if (bookingMinutes === 25) {
            paddingTop = ['9px', '9px', '9px', '8px'];
        }
        if (bookingMinutes === 30) {
            paddingTop = ['3px', '7px', '3px', '2px'];
        }
        if (bookingMinutes === 35) {
            paddingTop = ['3px', '2px', '2px', '2px'];
        }
    } else {
        flexDirection = 'row';
    }

    return css(
        mq({
            '@media (hover: hover) and (pointer: fine)': {
                '&:hover': hoverObj,
            },
            position: 'absolute',
            backgroundColor: bgColor,
            color,
            paddingLeft,
            paddingTop,
            paddingRight: '5px',
            zIndex: 1,
            cursor: isMyBook ? 'pointer' : 'default',
            display: 'flex',
            alignItems,
            flexDirection,
            justifyContent,
            fontStyle: 'normal',
            fontWeight: 600,
            flexWrap,
            fontSize,
            lineHeight,
            border: `0.5px solid ${borderColor}`,
            borderRight: `1px solid ${borderColor}`,
            borderTop: `1px solid ${borderColor}`,
            borderRadius,
            opacity: '1',
            transition: 'all 0.3s ease-out',
        }),
    );
};

const bookedTimeWrapper = ({
    isMoreThanHour,
    allMeetingRooms,
    bookingMinutes,
    isRepeated,
}: {
    isMoreThanHour: boolean;
    allMeetingRooms?: boolean;
    bookingMinutes: number;
    isRepeated: boolean;
}) => {
    let fontSize = ['8px', '8px', '8px', '8px'];
    let lineHeight = ['10px', '10px', '10px', '10px'];
    let overflow;
    let whiteSpace;
    let textOverflow;
    let width;
    let display = 'flex';

    if (allMeetingRooms) {
        fontSize = ['5px', '5px', '6px', '8px'];
        lineHeight = ['7px', '7px', '8px', '10px'];
        whiteSpace = 'nowrap';
        if (bookingMinutes === 55) {
            lineHeight = ['6px', '7px', '8px', '10px'];
        }
        if (bookingMinutes < 25) {
            overflow = 'hidden';
            display = 'inline-block';
            width = isRepeated
                ? ['52px', '50px', '65px', '100%']
                : ['25px', '50px', '65px', '85px'];
        }
    }
    return css(
        mq({
            fontWeight: 600,
            fontSize,
            lineHeight,
            overflow,
            whiteSpace,
            textOverflow,
            display,
            width,
        }),
    );
};

const nameTextStyles = ({
    isMoreThanHour,
    allMeetingRooms,
    bookingMinutes,
    isRepeated,
}: {
    isMoreThanHour: boolean;
    allMeetingRooms?: boolean;
    bookingMinutes: number;
    isRepeated: boolean;
}) => {
    let fontSize = ['8px', '8px', '8px', '8px'];
    let lineHeight = ['10px', '10px', '10px', '10px'];
    let overflow;
    let whiteSpace;
    let textOverflow;
    let width;
    let display = 'flex';
    let marginRight = '5px';

    if (allMeetingRooms) {
        fontSize = ['5px', '5px', '6px', '8px'];
        lineHeight = ['7px', '7px', '8px', '10px'];
        if (bookingMinutes === 30) {
            lineHeight = ['6px', '6px', '8px', '10px'];
        }
        if (bookingMinutes === 55) {
            lineHeight = ['6px', '7px', '8px', '10px'];
        }
        if (bookingMinutes < 40) {
            overflow = 'hidden';
            whiteSpace = 'wrap';
            /*     textOverflow = 'ellipsis'; */
            display = 'inline-block';
            if (bookingMinutes < 25) {
                width = isRepeated
                    ? ['20px', '20px', '20px', '200px']
                    : ['40px', '50px', '55px', '60px'];
                marginRight = '2px';
            } else if (bookingMinutes === 25) {
                width = isRepeated
                    ? ['45px', '70px', '90px', '90px']
                    : ['90px', '100px', '110px', '140px'];
                marginRight = '2px';
            } else if (bookingMinutes === 30) {
                width = isRepeated
                    ? ['45px', '60px', '90px', '80px']
                    : ['90px', '100px', '110px', '140px'];
                marginRight = '2px';
            } else {
                width = isRepeated
                    ? ['70px', '80px', '90px', '90px']
                    : ['70px', '90px', '110px', '140px'];
            }
        }
    }
    return css(
        mq({
            display,
            fontWeight: 600,
            fontSize,
            lineHeight,
            marginRight,
            overflow,
            whiteSpace,
            textOverflow,
            width,
        }),
    );
};

const repeatIconsStyles = ({
    colorScheme,
    allMeetingRooms,
    isMoreThanHour,
    bookingMinutes,
}: {
    colorScheme: string;
    allMeetingRooms?: boolean;
    isMoreThanHour: boolean;
    bookingMinutes: number;
}) => {
    const fontSize = ['10px', '10px', '10px', '16px'];
    let bottom = ['5px', '5px', '5px', '5px'];
    let marginLeft;
    let marginRight;
    let margin;
    let position = 'static';
    let left;

    if (isMoreThanHour) {
        bottom = allMeetingRooms ? ['4px', '1px', '1px', '1px'] : ['2px', '2px', '2px', '2px'];
    }

    if (bookingMinutes === 15 && allMeetingRooms) {
        bottom = ['3px', '3px', '2px', '2px'];
        position = 'relative';
        left = ['2px', '0px', '0px', '0px'];
    }

    if (bookingMinutes === 20 && allMeetingRooms) {
        bottom = ['2px', '2px', '2px', '2px'];
        position = 'relative';
        left = ['1px', '0px', '0px', '0px'];
    }

    if (bookingMinutes === 25 && allMeetingRooms) {
        bottom = ['2px', '2px', '1px', '3px'];
        position = 'relative';
        left = ['1px', '0px', '0px', '0px'];
    }

    if (bookingMinutes === 30 && allMeetingRooms) {
        bottom = ['2px', '2px', '1px', '1px'];
        position = 'relative';
        left = ['1px', '0px', '0px', '0px'];
    }

    if (bookingMinutes > 30 && bookingMinutes < 45 && allMeetingRooms) {
        bottom = ['2px', '2px', '0px', '1px'];
        position = 'relative';
        left = ['0px', '0px', '0px', '0px'];
    }

    if (bookingMinutes === 45 && allMeetingRooms) {
        bottom = ['2px', '1px', '0px', '1px'];
        position = 'relative';
    }

    if (bookingMinutes > 45 && bookingMinutes < 60 && allMeetingRooms) {
        bottom = ['2px', '0px', '0px', '0px'];
        position = 'relative';
    }

    if (bookingMinutes === 60 && allMeetingRooms) {
        bottom = ['1px', '1px', '0px', '1px'];
        position = 'relative';
    }

    if (allMeetingRooms) {
        marginLeft = '0px';
        marginRight = '3px';
    } else {
        margin = ['0px 2px', '0px 5px', '0px 5px', '0px 5px'];
    }

    return css(
        mq({
            position,
            bottom,
            fontSize,
            color: '#FFFFFF',
            margin,
            marginLeft,
            marginRight,
            left,
        }),
    );
};

const bookInfoWrapperStyles = ({
    isMoreThanHour,
    isOneHour,
    allMeetingRooms,
    bookingMinutes,
}: {
    isMoreThanHour: boolean;
    isOneHour: boolean;
    allMeetingRooms?: boolean;
    bookingMinutes: number;
}) => {
    let justifyContent;
    let alignItems;
    let flexDirection: 'column' | 'row' = 'row';

    if (isMoreThanHour) {
        justifyContent = 'flex-start';
        alignItems = 'flex-start';
    } else {
        justifyContent = 'flex-start';
        alignItems = 'center';
    }

    if (isOneHour) {
        justifyContent = 'center';
        alignItems = 'flex-start';
    }

    if (allMeetingRooms && !isMoreThanHour && !isOneHour) {
        justifyContent = 'center';
        alignItems = 'flex-start';
    }

    if (isMoreThanHour || isOneHour || allMeetingRooms) {
        flexDirection = 'column';
        if (bookingMinutes < 25) {
            flexDirection = 'row';
        }
    }
    return css({
        display: 'flex',
        flexDirection,
        justifyContent,
        alignItems,
    });
};

const repeatInfoWrapperStyles = ({
    isMoreThanHour,
    isOneHour,
    allMeetingRooms,
    bookingMinutes,
}: {
    isMoreThanHour: boolean;
    isOneHour: boolean;
    allMeetingRooms?: boolean;
    bookingMinutes: number;
}) => {
    let justifyContent;
    let alignItems;
    let paddingTop;
    let paddingBottom;

    if (isMoreThanHour) {
        justifyContent = 'flex-start';
        alignItems = 'flex-start';
    } else {
        justifyContent = 'center';
        alignItems = 'center';
    }

    if (isOneHour) {
        justifyContent = allMeetingRooms ? 'flex-start' : 'center';
        alignItems = allMeetingRooms ? 'flex-start' : 'center';
        paddingBottom = allMeetingRooms ? undefined : ['10px', '10px', '10px', '10px'];
        paddingTop = '0px';
    }

    if (allMeetingRooms && !isMoreThanHour && !isOneHour) {
        justifyContent = 'flex-start';
        alignItems = 'flex-start';
    }
    return css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent,
        alignItems,
        height: '100%',
        paddingBottom,
        paddingTop,
    });
};

const repeatValueTextStyles = ({
    isMoreThanHour,
    allMeetingRooms,
    bookingMinutes,
}: {
    isMoreThanHour: boolean;
    allMeetingRooms?: boolean;
    bookingMinutes: number;
}) => {
    let fontSize = ['8px', '8px', '8px', '8px'];
    let lineHeight = ['10px', '10px', '10px', '10px'];
    let paddingTop;

    if (allMeetingRooms) {
        fontSize = ['5px', '5px', '6px', '8px'];
        lineHeight = ['7px', '7px', '8px', '10px'];
        if (bookingMinutes < 25) {
            paddingTop = ['0px', '0px', '0px', '0px'];
        } else if (bookingMinutes === 25) {
            paddingTop = ['0px', '0px', '0px', '0px'];
        } else {
            paddingTop = ['0.5px', '2px', '1px', '2px'];
        }
    } else if (isMoreThanHour) {
        paddingTop = ['0.5px', '3px', '0px', '2px'];
    } else {
        paddingTop = ['0.5px', '2px', '1px', '1px'];
        if (bookingMinutes < 25) {
            paddingTop = ['0px', '0px', '0px', '0px'];
        }
    }

    return css(
        mq({
            display: 'flex',
            fontWeight: 600,
            fontSize,
            lineHeight,
            paddingTop,
        }),
    );
};

const styles = {
    bookedRowStyles,
    bookedTimeWrapper,
    nameTextStyles,
    repeatIconsStyles,
    bookInfoWrapperStyles,
    repeatInfoWrapperStyles,
    repeatValueTextStyles,
};

export default styles;
