/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { Tooltip } from '@mui/material';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface MeetingRoomProps {
    id: string;
    disabled?: boolean;
    onClick?: (id: string) => void;
}

export function MeetingRoom({ id, disabled, onClick }: MeetingRoomProps) {
    const { colorScheme } = useTelegram();

    return (
        <Tooltip
            enterTouchDelay={0}
            title={disabled && !onClick ? 'This meeting room is unavailable on this day' : ''}
            placement="bottom-end"
            leaveTouchDelay={3000}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
                        color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                        fontWeight: 400,
                        fontSize: '8px',
                        marginTop: '2px !important',
                    },
                },
            }}
        >
            {onClick && !disabled ? (
                <div
                    onClick={() => {
                        onClick?.(id);
                    }}
                    css={styles.meetingRoomStyles({
                        isMeetingRoom: !!onClick,
                        colorScheme,
                        isMRDisable: !!disabled,
                    })}
                >
                    <p>{id}</p>
                </div>
            ) : (
                <div
                    css={styles.meetingRoomStyles({
                        isMeetingRoom: !!onClick,
                        colorScheme,
                        isMRDisable: !!disabled,
                    })}
                >
                    <p>{id}</p>
                </div>
            )}
        </Tooltip>
    );
}
