/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const checkboxWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '20px',
    });
};

const checkboxLabelStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    });
};

const checkboxStyles = ({
    colorScheme,
    isChecked,
    disabled,
}: {
    colorScheme: string;
    isChecked: boolean;
    disabled?: boolean;
}) => {
    let bgColor;

    if (isChecked) {
        bgColor = '#004ED7';
    } else {
        bgColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
    }

    return css({
        '-webkit-appearance': 'none',
        appearance: 'none',
        width: '20px',
        height: '20px',
        borderRadius: '5px',
        background: bgColor,
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? '0.2' : '1',
    });
};

const checkboxTitleStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '12px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        marginLeft: '5px',
    });
};

const iconStyles = ({ showLabel }: { showLabel?: boolean }) => {
    let left;

    if (showLabel) {
        left = '5px';
    }
    return css({
        position: 'absolute',
        top: '6.5px',
        left,
    });
};

const styles = {
    checkboxWrapperStyles,
    checkboxStyles,
    checkboxLabelStyles,
    checkboxTitleStyles,
    iconStyles,
};

export default styles;
