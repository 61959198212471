import { TelegramWebApps } from 'telegram-webapps-types';

declare global {
    interface Window {
        Telegram: TelegramWebApps.SDK;
    }
}

const tg = window.Telegram.WebApp;

export function useTelegram() {
    const onClose = () => {
        tg.close();
    };

    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    };

    return {
        tg,
        onClose,
        onToggleButton,
        // @ts-ignore
        user: tg.initDataUnsafe?.user?.username,
        tgUserId: tg.initDataUnsafe?.user?.id,
        queryId: tg.initDataUnsafe?.query_id,
        viewportHeight: tg.viewportHeight,
        colorScheme: tg.colorScheme,
        isExpanded: tg.isExpanded,
    };
}
