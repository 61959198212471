/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import styles from './styles';

interface CalendarProps {
    value: Dayjs | null | undefined;
    onClose?: () => void;
    onChange: (value: Dayjs | null) => void;
}

function Calendar({ value, onClose, onChange }: CalendarProps) {
    return (
        <div css={styles.datePickerWrapperStyles}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                    maxDate={dayjs().add(1, 'year')}
                    minDate={dayjs()}
                    views={['day']}
                    disablePast
                    onAccept={onClose}
                    css={styles.calendarStyles}
                    displayStaticWrapperAs="desktop"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
}

export default Calendar;
