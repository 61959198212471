import { Checkbox, FormControlLabel } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type ControlledCheckboxProps<T extends FieldValues> = {
    control: Control<T>;
    name: Path<T>;
    label?: string;
};

export function ControlledCheckbox<T extends FieldValues>({
    control,
    name,
    label,
}: ControlledCheckboxProps<T>) {
    return (
        <FormControlLabel
            label={label}
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => <Checkbox {...field} />}
                />
            }
        />
    );
}
