/** @jsxImportSource @emotion/react */
import { CircularProgress, Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import EmptyRow from '../timeRow/emptyRow';
import styles from '../tableHeader/styles';
import { useTelegram } from '../../../hooks/useTelegram';
import BookedTimeCell from '../bookedTimeCell';
import { meetingRoomBookingApi } from '../../../api/meetingRoomBookingApi';
import { MEETING_ROOM_BOOKING_LIST_INCLUDE } from '../../../api/types';
import { ScheduleTimeline } from './ScheduleTimeline/ScheduleTimeline';
import { findEnableTimesIntervals } from '../../../utils/findEnableTimeIntervals';
import { MeetingRoomBooking } from '../../../types';

type MeetingRoomScheduleProps = {
    editable?: boolean;
    date: Dayjs;
    onCellClick: (startTime: string, endTime: string, meetingRoomName: string) => void;
    onEditClick?: (booking: MeetingRoomBooking, meetingRoomName?: string) => void;
    onDeleteClick?: (bookingId: string) => void;
};
export const MeetingRoomSchedule: React.FC<MeetingRoomScheduleProps> = ({
    date,
    onCellClick,
    editable,
    onDeleteClick,
    onEditClick,
}) => {
    const [MR1Bookings, setMR1Bookings] = useState<MeetingRoomBooking[]>([]);
    const [MR2Bookings, setMR2Bookings] = useState<MeetingRoomBooking[]>([]);
    const [MR3Bookings, setMR3Bookings] = useState<MeetingRoomBooking[]>([]);

    const { colorScheme } = useTelegram();

    const formattedDate = date.format('YYYY-MM-DD');

    const { isLoading, data: bookingsResponse } = meetingRoomBookingApi.useListQuery({
        filters: { date: formattedDate },
        offset: 0,
        include: [
            MEETING_ROOM_BOOKING_LIST_INCLUDE.USERS,
            MEETING_ROOM_BOOKING_LIST_INCLUDE.MEETING_ROOMS,
        ],
        includeCount: true,
    });

    const MR1Intervals = findEnableTimesIntervals(MR1Bookings, formattedDate);
    const MR2Intervals = findEnableTimesIntervals(MR2Bookings, formattedDate);
    const MR3Intervals = findEnableTimesIntervals(MR3Bookings, formattedDate);

    const mapListResponseToState = useCallback(() => {
        if (bookingsResponse) {
            const MR1 = bookingsResponse.data
                .filter((booking) => booking.meetingRoom?.name === 'MR1')
                .map(({ user: _user, meetingRoom: _mr, ...bookingInfo }) => bookingInfo);
            const MR2 = bookingsResponse.data
                .filter((booking) => booking.meetingRoom?.name === 'MR2')
                .map(({ user: _user, meetingRoom: _mr, ...bookingInfo }) => bookingInfo);
            const MR3 = bookingsResponse.data
                .filter((booking) => booking.meetingRoom?.name === 'MR3')
                .map(({ user: _user, meetingRoom: _mr, ...bookingInfo }) => bookingInfo);

            return { MR1, MR2, MR3 };
        }

        return undefined;
    }, [bookingsResponse]);

    useEffect(() => {
        const mappedResponse = mapListResponseToState();

        if (mappedResponse) {
            setMR1Bookings(mappedResponse.MR1);
            setMR2Bookings(mappedResponse.MR2);
            setMR3Bookings(mappedResponse.MR3);
        }
    }, [mapListResponseToState]);

    if (isLoading) {
        return <CircularProgress size={40} />;
    }

    return (
        <div>
            <Grid container position="relative" justifyContent="center" mb="20px" overflow="auto">
                <Grid item xs={12} flexDirection="row" display="flex" mt="20px" ml="50px">
                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
                        <p css={styles.meetingRoomNameTextStyles({ colorScheme })}>MR1</p>
                    </Grid>
                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
                        <p css={styles.meetingRoomNameTextStyles({ colorScheme })}>MR2</p>
                    </Grid>
                    <Grid item xs={4} display="flex" alignItems="center" justifyContent="center">
                        <p css={styles.meetingRoomNameTextStyles({ colorScheme })}>MR3</p>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container position="relative" overflow="auto">
                <ScheduleTimeline />
                {MR1Intervals.map((i) => (
                    <EmptyRow
                        key={JSON.stringify(i)}
                        isTimeRowClickable
                        enableEndTime={dayjs(i.endTime).format('HH:mm:ss')}
                        enableStartTime={dayjs(i.startTime).format('HH:mm:ss')}
                        meetingRoomDateValue={formattedDate}
                        meetingRoomName="MR1"
                        allMeetingRooms
                        onCellClick={onCellClick}
                    />
                ))}
                {MR2Intervals.map((i) => (
                    <EmptyRow
                        key={Number(new Date(`${i.startTime}`))}
                        enableStartTime={dayjs(`${i.startTime}`).format('HH:mm:ss')}
                        enableEndTime={dayjs(`${i.endTime}`).format('HH:mm:ss')}
                        meetingRoomDateValue={formattedDate}
                        isTimeRowClickable
                        meetingRoomName="MR2"
                        allMeetingRooms
                        onCellClick={onCellClick}
                    />
                ))}
                {MR3Intervals.map((i) => (
                    <EmptyRow
                        isTimeRowClickable
                        key={JSON.stringify(i)}
                        enableStartTime={dayjs(i.startTime).format('HH:mm:ss')}
                        enableEndTime={dayjs(i.endTime).format('HH:mm:ss')}
                        meetingRoomDateValue={formattedDate}
                        meetingRoomName="MR3"
                        allMeetingRooms
                        onCellClick={onCellClick}
                    />
                ))}
                {bookingsResponse?.data.map((booking) => {
                    const { user, meetingRoom, ...bookingInfo } = booking;
                    return (
                        <BookedTimeCell
                            onEditClick={(bookingToEdit) =>
                                onEditClick?.(bookingToEdit, meetingRoom?.name)
                            }
                            onDeleteClick={onDeleteClick}
                            editable={editable}
                            allMeetingRooms
                            booking={bookingInfo}
                            key={bookingInfo.id}
                            meetingRoomName={meetingRoom?.name || ''}
                            user={user!}
                        />
                    );
                })}
            </Grid>
        </div>
    );
};
