import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './interceptor';
import { OFFICE_SPACES_ROUTES, STORE_TAGS } from '../constants';
import { OfficeSpaceListRequestBody, OfficeSpaceListResponse, UpdateDeskBody } from './types';

export const officeSpaceApi = createApi({
    reducerPath: 'officeSpaceApi',
    baseQuery,
    tagTypes: [STORE_TAGS.OFFICE_SPACES],
    endpoints: (builder) => ({
        list: builder.query<OfficeSpaceListResponse, OfficeSpaceListRequestBody>({
            query: (body) => ({
                url: OFFICE_SPACES_ROUTES.LIST,
                method: 'POST',
                body,
            }),
            keepUnusedDataFor: 0,
            providesTags: [STORE_TAGS.OFFICE_SPACES],
        }),
        oneSpaceById: builder.query<OfficeSpaceListResponse, { id: string; include?: string[] }>({
            query: ({ id, include }) => ({
                url: OFFICE_SPACES_ROUTES.LIST,
                method: 'POST',
                body: {
                    filters: {
                        id: [id],
                    },
                    include,
                },
            }),
            providesTags: [STORE_TAGS.OFFICE_SPACES],
        }),
        oneSpaceByName: builder.query<
            OfficeSpaceListResponse,
            { name: string; include?: string[] }
        >({
            query: ({ name, include }) => ({
                url: OFFICE_SPACES_ROUTES.LIST,
                method: 'POST',
                body: {
                    filters: {
                        name: [name],
                    },
                    include,
                },
            }),
            providesTags: [STORE_TAGS.OFFICE_SPACES],
        }),
        updateOfficeSpace: builder.mutation<unknown, UpdateDeskBody>({
            query: (body) => ({
                url: OFFICE_SPACES_ROUTES.UPDATE_OFFICE_SPACE_ROUTE,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: [STORE_TAGS.OFFICE_SPACES],
        }),
    }),
});
