import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { usersApi } from '../../../api/usersApi';
import { GetUsersResponse } from '../../../api/types';

type UsersSelectProps = {
    search?: string;
    searchFields?: string[];
    label: string;
    onChange: (option: { value: string; label: string } | null) => void;
};

export const UsersSelect: React.FC<UsersSelectProps> = ({
    search,
    searchFields,
    label,
    onChange,
}) => {
    const [options, setOptions] = useState<AutoCompleteOptions>([]);

    const [getUsers, { isLoading }] = usersApi.useLazyListQuery();

    const fetchUsers = useCallback(async () => {
        getUsers({ search, searchFields })
            .unwrap()
            .then((response) => {
                const mappedResponse = mapResponseToState(response.data);
                setOptions(mappedResponse);
            });
    }, [search, searchFields]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <Autocomplete
            loading={isLoading}
            disablePortal
            options={options}
            renderInput={(params) => <TextField {...params} label={label} />}
            onChange={(_event, option) => onChange(option)}
        />
    );
};

type AutoCompleteOptions = { label: string; value: string }[];

function mapResponseToState(data: GetUsersResponse['data']): AutoCompleteOptions {
    return data.map((item) => ({
        label: `${item.name} ${item.surname}`,
        value: String(item.telegramUserId),
    }));
}
