/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSearchParams } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { useEnhancedNavigate } from '../../../hooks/useEnhancedNavigate';
import { APP_ROUTES, MAX_TODAY_TIME } from '../../../constants';
import { useTelegram } from '../../../hooks/useTelegram';
import { useStyles } from '../../../hooks/useStyles';
import { BookInfoModal } from './bookInfoModal';
import { useLazyGetBookedOfficeSpacesQuery } from '../../../api/rootApi';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { setEditedUserSpace } from '../../../store/reducers/officeSpace';

import styles from './styles';

const ONE_DAY = 1;

const TWO_MONTHS = 2;

function BookPeriodLayout() {
    let todayDate = dayjs();

    const tomorrow = dayjs().add(1, 'day');

    const [startDatePickerOpen, setStartDatePickerOpen] = useState(false);
    const [endDatePickerOpen, setEndDatePickerOpen] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [minDateError, setMinDateError] = useState(false);

    const { colorScheme, tgUserId } = useTelegram();

    const dispatch = useDispatch();

    const [queryParams] = useSearchParams();

    const startDateState = queryParams.get('startDateState') ?? undefined;

    const endDateState = queryParams.get('endDateState') ?? undefined;

    const isMyBookings = Boolean(queryParams.get('isMyBookings'));

    useEffect(() => {
        if (startDateState || endDateState) {
            setStartDate(dayjs(startDateState));
            setEndDate(dayjs(endDateState));
        } else {
            setStartDate(dayjs(todayDate));
            setEndDate(dayjs(tomorrow));
        }
    }, []);

    const [getBookedOfficeSpaces] = useLazyGetBookedOfficeSpacesQuery();

    useEffect(() => {
        if (startDate) {
            getBookedOfficeSpaces({
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate?.format('YYYY-MM-DD'),
            });
        }
    }, [startDate, endDate]);

    const { scrollNavigate } = useEnhancedNavigate();

    const minEndDate = startDate?.add(1, 'day');

    const bookedSpaces = useAppSelector((store) => store.officeSpaceReducer.bookedSpaces);

    const editSpace = useAppSelector((store) => store.officeSpaceReducer.editedUserSpace);

    const userBookings = bookedSpaces.filter((s) => {
        if (s.spaceUser.telegramUserId === tgUserId) {
            return true;
        }
        return false;
    });

    const isUserHaveOtherBookings = userBookings.some((b) => b.userSpaceId !== editSpace?.id);

    const setPeriodHandler = useCallback(() => {
        if (userBookings.length > 0 && isUserHaveOtherBookings) {
            setModalActive(true);
        } else if (startDate && endDate) {
            const startDateString = startDate?.format('YYYY-MM-DD');

            const endDateString = endDate?.format('YYYY-MM-DD');

            dispatch(setEditedUserSpace({ newEditedUserSpace: editSpace }));

            scrollNavigate({
                top: 0,
                left: 0,
                path: `${
                    APP_ROUTES.HOME_PAGE
                }?startDate=${startDateString}&endDate=${endDateString}&periodActive=${true}${
                    editSpace ? `&spaceId=${editSpace.id}&spaceName=${editSpace.name}` : ''
                }${isMyBookings ? `&isMyBookings=${isMyBookings}` : ``}`,
                replace: true,
                behavior: 'smooth',
            });
        }
    }, [userBookings]);

    const todayHours = todayDate.get('hours');

    if (todayHours >= MAX_TODAY_TIME) {
        todayDate = todayDate.add(ONE_DAY, 'day');
    }

    const maxEndDate = dayjs().add(1, 'year');

    const classes = useStyles({ colorScheme });

    return (
        <div css={styles.formStyles({ colorScheme })}>
            <h3 css={styles.headerTextStyles({ colorScheme })}>Put your info</h3>
            <div css={styles.inputWrapperStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className={classes.root}
                        open={startDatePickerOpen}
                        onOpen={() => setStartDatePickerOpen(true)}
                        onClose={() => setStartDatePickerOpen(false)}
                        css={styles.datePickerStyles}
                        minDate={todayDate}
                        maxDate={maxEndDate}
                        showToolbar={false}
                        label="Start date"
                        inputFormat="DD.MM.YYYY"
                        value={startDate}
                        PaperProps={{
                            className: classes.root,
                            sx: {
                                '& .PrivatePickersSlideTransition-root': {
                                    minHeight: '230px',
                                },
                            },
                        }}
                        onChange={(newValue: React.SetStateAction<dayjs.Dayjs | null>) => {
                            setStartDate(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                InputLabelProps={{ className: 'inputLabel' }}
                                sx={{
                                    width: '100%',
                                }}
                                {...params}
                                onClick={(e) => {
                                    setStartDatePickerOpen(true);
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </div>
            <div css={styles.inputWrapperStyles}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        className={classes.root}
                        open={endDatePickerOpen}
                        onOpen={() => setEndDatePickerOpen(true)}
                        onClose={() => setEndDatePickerOpen(false)}
                        css={styles.datePickerStyles}
                        showToolbar={false}
                        PaperProps={{
                            className: classes.root,
                            sx: {
                                '& .PrivatePickersSlideTransition-root': {
                                    minHeight: '230px',
                                },
                            },
                        }}
                        label="End date"
                        inputFormat="DD.MM.YYYY"
                        value={endDate}
                        onAccept={() => {
                            setMinDateError(false);
                        }}
                        onError={(reason) => {
                            if (reason === 'minDate') {
                                setMinDateError(true);
                            }
                        }}
                        minDate={minEndDate}
                        maxDate={startDate?.add(TWO_MONTHS, 'month')}
                        onChange={(newValue: React.SetStateAction<dayjs.Dayjs | null>) => {
                            setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                InputLabelProps={{ className: 'inputLabel' }}
                                sx={{
                                    width: '100%',
                                }}
                                {...params}
                                onClick={(e) => {
                                    setEndDatePickerOpen(true);
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
                {minDateError && (
                    <p css={styles.errorTextStyles}>Minimum 2 days should be chosen</p>
                )}
            </div>
            {startDate && endDate && !minDateError && (
                <div css={styles.btnWrapperStyles}>
                    <button
                        disabled={minDateError}
                        css={styles.setPeriodBtnStyles}
                        onClick={setPeriodHandler}
                        type="button"
                    >
                        Set a period
                    </button>
                </div>
            )}
            <BookInfoModal open={modalActive} setOpen={setModalActive} />
        </div>
    );
}

export default BookPeriodLayout;
