import {
    Control,
    FieldValues,
    Path,
    UseFormGetValues,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { ControlledDatePicker } from '../ControlledDatePicker/ControlledDatePicker';

export type DatePickerConfig<T extends FieldValues> = {
    name: Path<T>;
    label?: string;
    minDate?: string;
    disabled?: boolean;
};

export type ControlledDateRangePickerProps<T extends FieldValues> = {
    control: Control<T>;
    config: [DatePickerConfig<T>, DatePickerConfig<T>];
    watch: UseFormWatch<T>;
    setValue: UseFormSetValue<T>;
    getValues: UseFormGetValues<T>;
};

export function ControlledDateRangePicker<T extends FieldValues>({
    control,
    config,
    getValues,
    setValue,
    watch,
}: ControlledDateRangePickerProps<T>) {
    useEffect(() => {
        const startDate = getValues(config[0].name);
        const endDate = getValues(config[1].name);

        const isEndDateBefore = dayjs(endDate).isBefore(startDate);

        if ((startDate && dayjs(startDate).isValid() && !endDate) || isEndDateBefore) {
            setValue(config[1].name, startDate, { shouldDirty: true });
        }
    }, [watch(config[0].name), watch(config[1].name)]);

    return (
        <>
            <ControlledDatePicker control={control} {...config[0]} />
            <ControlledDatePicker control={control} {...config[1]} />
        </>
    );
}
