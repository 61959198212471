import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTelegram } from './hooks/useTelegram';
import { Router } from './routes';

import './index.css';
import { usersApi } from './api/usersApi';
import { setUser } from './store/reducers/user';

function App() {
    const { tg, tgUserId } = useTelegram();

    const [getUser] = usersApi.useLazyOneUserQuery();

    const dispatch = useDispatch();

    useEffect(() => {
        tg.ready();
        tg.expand();
        if (tgUserId) {
            getUser({ telegramUserId: tgUserId })
                .unwrap()
                .then((response) => {
                    dispatch(setUser(response.data[0]));
                });
        }
    }, []);

    return (
        <div className="mainLayout">
            <Router />
        </div>
    );
}

export default App;
