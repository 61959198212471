import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './interceptor';
import { API_ROUTE, STORE_TAGS, USERS_ROUTES } from '../constants';
import { ChangeUserBody, GetUsersRequestBody, GetUsersResponse } from './types';

export const usersApi = createApi({
    reducerPath: 'usersApi',
    baseQuery,
    tagTypes: [STORE_TAGS.USERS, STORE_TAGS.USER],
    endpoints: (builder) => ({
        list: builder.query<GetUsersResponse, GetUsersRequestBody>({
            query: (body) => ({
                url: USERS_ROUTES.GET_USERS,
                method: 'POST',
                body,
            }),
            providesTags: [STORE_TAGS.USERS],
        }),
        update: builder.mutation<void, ChangeUserBody>({
            query: ({ id, ...body }) => ({
                url: `${API_ROUTE}/api/v1/users/${id}`,
                method: 'PATCH',
                body,
            }),
            invalidatesTags: [STORE_TAGS.USERS],
        }),
        oneUser: builder.query<GetUsersResponse, { telegramUserId: number }>({
            query: ({ telegramUserId }) => ({
                url: USERS_ROUTES.GET_USERS,
                method: 'POST',
                body: {
                    filters: {
                        telegramUserId: [telegramUserId],
                    },
                },
            }),
            providesTags: [STORE_TAGS.USERS],
        }),
    }),
});
