/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const calendarStyles = css({
    position: 'absolute',
    top: '10px',
    right: '10%',
    zIndex: 3,
    '& .MuiPickersDay-root': {
        fontWeight: 600,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
    },
    '& .MuiDayPicker-weekDayLabel': {
        fontWeight: 600,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
    },
    '& .MuiPickersCalendarHeader-label': {
        fontWeight: 600,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        color: '#000000',
    },
    '& .MuiPickerStaticWrapper-content': {
        borderRadius: '5px',
    },
});

const datePickerWrapperStyles = css({
    position: 'relative',
    display: 'flex',
    width: '100vw',
});

const styles = {
    calendarStyles,
    datePickerWrapperStyles,
};

export default styles;
