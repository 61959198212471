/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const timeRowStyles = () => {
    return css({
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    });
};

const timeCellStyles = ({
    formattedTime,
    colorScheme,
    allMeetingRooms,
    meetingRoomName,
}: {
    formattedTime: string;
    colorScheme: string;
    allMeetingRooms?: boolean;
    meetingRoomName: string;
}) => {
    let borderRadius;
    let borderWidth;
    const bgColor = colorScheme === 'dark' ? '#1F2023' : '#FFFFFF';
    const color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
    const borderColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';

    if (formattedTime === '7 am' && !allMeetingRooms) {
        borderRadius = '0px 5px 0px 0px';
        borderWidth = [
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
        ];
    }

    if (formattedTime === '10 pm' && !allMeetingRooms) {
        borderWidth = [
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
        ];
        borderRadius = '0px 0px 5px 0px';
    }

    if (formattedTime !== '10 pm' && formattedTime !== '7 am') {
        borderWidth = [
            '0.5px 1px 0.5px 0.5px',
            '0.5px 1px 0.5px 0.5px',
            '0.5px 1px 0.5px 0.5px',
            '0.5px 1px 0.5px 0.5px',
        ];
    }

    if (allMeetingRooms && meetingRoomName === 'MR3' && formattedTime === '7 am') {
        borderRadius = '0px 5px 0px 0px';
        borderWidth = [
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
        ];
    } else if (allMeetingRooms && meetingRoomName !== 'MR3' && formattedTime === '7 am') {
        borderWidth = [
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
            '1px 1px 0.5px 0.5px',
        ];
    }

    if (allMeetingRooms && meetingRoomName === 'MR3' && formattedTime === '10 pm') {
        borderWidth = [
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
        ];
        borderRadius = '0px 0px 5px 0px';
    } else if (allMeetingRooms && meetingRoomName !== 'MR3' && formattedTime === '10 pm') {
        borderWidth = [
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
            '0.5px 1px 1px 0.5px',
        ];
    }

    return css(
        mq({
            borderWidth,
            borderStyle: 'solid',
            borderColor,
            borderRadius,
            minHeight: '69px !important',
            flexGrow: 1,
            maxWidth: '100%',
            background: bgColor,
            color,
            transition: 'all 0.3s ease-out',
        }),
    );
};

const formattedTimeCellStyles = ({
    formattedTime,
    colorScheme,
}: {
    formattedTime: string;
    colorScheme: string;
}) => {
    let borderRadius;
    let borderWidth;
    let bgColor;
    let color;
    let borderColor;
    let borderTop;
    let borderLeft;
    let borderBottom;

    if (colorScheme === 'dark') {
        bgColor = '#1F2023';
        color = '#FFFFFF';
        borderColor = '#383B40';
    } else {
        bgColor = '#FFFFFF';
        color = '#000000';
        borderColor = '#C4C4C4';
    }

    if (formattedTime === '7 am') {
        borderRadius = '5px 0px 0px 0px';
        borderWidth = '1px 0.5px 0.5px 1px';
    }

    if (formattedTime === '10 pm') {
        borderRadius = '0px 0px 0px 5px';
        borderWidth = '0.5px 0.5px 1px 1px';
    }

    if (formattedTime !== '10 pm' && formattedTime !== '7 am') {
        borderWidth = '0.5px 0.5px 0.5px 1px';
    }

    return css({
        display: 'flex',
        alignItems: 'center',
        zIndex: 2,
        justifyContent: 'center',
        height: '69px',
        minWidth: '50px',
        fontWeight: 600,
        fontSize: '8px',
        lineHeight: '10px',
        borderWidth,
        borderStyle: 'solid',
        borderColor,
        borderRadius,
        background: bgColor,
        color,
        transition: 'all 0.3s ease-out',
        borderTop,
        borderLeft,
        borderBottom,
    });
};

const styles = {
    timeRowStyles,
    timeCellStyles,
    formattedTimeCellStyles,
};

export default styles;
