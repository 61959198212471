import { createApi } from '@reduxjs/toolkit/query/react';
import dayjs from 'dayjs';
import {
    OFFICE_SPACES_ROUTES,
    USERS_ROUTES,
    STORE_TAGS,
    GOOGLE_ROUTES,
    OFFICE_SPACES_ROUTE,
} from '../constants';
import { setAllMeetingRoomUsers } from '../store/reducers/meetingRooms';
import { setBookedSpaces } from '../store/reducers/officeSpace';
import { baseQuery } from './interceptor';
import {
    ChangeUserBody,
    CreateBookingPeriodBody,
    GetMeetingRoomUsersResponse,
    BookMeetingRoomBody,
    GetBookedOfficeSpacesResponse,
    BookOfficeSpaceBody,
    RegisterUserBody,
    EditOfficeSpaceBody,
    EditMeetingRoomBooking,
    RemoveMeetingRoomBookingBody,
    GetAllBookingsResponse,
    GetUsersResponse,
    GetUsersRequestBody,
    GetOfficeSpaceByIdResponse,
} from './types';

export const rootApi = createApi({
    baseQuery,
    tagTypes: Object.values(STORE_TAGS),
    endpoints: (builder) => ({
        getUsers: builder.mutation<GetUsersResponse, GetUsersRequestBody>({
            query: (body) => ({
                url: USERS_ROUTES.GET_USERS,
                method: 'POST',
                body: {
                    ...body,
                },
            }),
            invalidatesTags: [STORE_TAGS.USERS, STORE_TAGS.USER],
        }),

        changeUser: builder.mutation<{ message: string }, ChangeUserBody>({
            query: ({ id, name, surname, queryId, isAdmin, status, team }) => ({
                url: USERS_ROUTES.CHANGE_USER_ROUTE,
                method: 'PATCH',
                body: { id, name, surname, queryId, isAdmin, status, team },
            }),
            invalidatesTags: [STORE_TAGS.USER],
        }),

        createBookingPeriod: builder.mutation<{ message: string }, CreateBookingPeriodBody>({
            query: ({ startDate, endDate, queryId, chatId, telegramUserId }) => ({
                url: OFFICE_SPACES_ROUTES.CREATE_BOOKING_PERIOD_ROUTE,
                method: 'POST',
                body: { startDate, endDate, queryId, chatId, telegramUserId },
            }),
        }),

        getMeetingRoomUsers: builder.query<
            GetMeetingRoomUsersResponse[],
            { date: string; meetingRoomId: string; endDate?: string }
        >({
            query: ({ date, meetingRoomId, endDate }) => ({
                url: OFFICE_SPACES_ROUTES.GET_MEETING_ROOM_USERS_ROUTE,
                method: 'GET',
                params: { date, meetingRoomId, endDate },
            }),

            providesTags: [STORE_TAGS.MEETING_ROOM],
        }),

        bookMeetingRoom: builder.mutation<{ message: string }, BookMeetingRoomBody>({
            query: ({
                meetingRoomId,
                telegramUserId,
                date,
                startTime,
                endTime,
                queryId,
                spaceName,
                allDay,
                isRepeated,
                repeatValue,
                repeatEndDate,
                isRepeatAlways,
                /*  userEmail, */
            }) => ({
                url: OFFICE_SPACES_ROUTES.BOOK_MEETING_ROOM_ROUTE,
                method: 'POST',
                body: {
                    meetingRoomId,
                    telegramUserId,
                    date,
                    startTime,
                    endTime,
                    queryId,
                    spaceName,
                    allDay,
                    isRepeated,
                    repeatValue,
                    repeatEndDate,
                    isRepeatAlways,
                    /*     userEmail, */
                },
            }),
            invalidatesTags: [STORE_TAGS.MEETING_ROOM, STORE_TAGS.ALL_BOOKINGS],
        }),

        getBookedOfficeSpaces: builder.query<
            GetBookedOfficeSpacesResponse[],
            { startDate: string; endDate?: string }
        >({
            query: ({ startDate, endDate }) => ({
                url: OFFICE_SPACES_ROUTES.GET_BOOKED_OFFICE_SPACES_ROUTE,
                method: 'GET',
                params: {
                    startDate: dayjs(startDate).format('YYYY-MM-DD'),
                    endDate: dayjs(endDate).format('YYYY-MM-DD'),
                },
            }),

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (data) {
                    dispatch(setBookedSpaces({ newBookedSpaces: data }));
                }
            },
            providesTags: [STORE_TAGS.OFFICE_SPACES],
        }),

        bookOfficeSpace: builder.mutation<{ message: string }, BookOfficeSpaceBody>({
            query: ({ queryId, spaceName, userId, startDate, endDate }) => ({
                url: OFFICE_SPACES_ROUTES.BOOK_OFFICE_SPACE_ROUTE,
                method: 'POST',
                body: { queryId, spaceName, userId, startDate, endDate },
            }),
            invalidatesTags: (_res, err, _arg) => {
                if (err) {
                    return [];
                }
                return [STORE_TAGS.OFFICE_SPACES, STORE_TAGS.ALL_BOOKINGS];
            },
        }),
        getOfficeSpaceById: builder.query<GetOfficeSpaceByIdResponse, { id: string }>({
            query: (params) => ({
                url: `${OFFICE_SPACES_ROUTE}/${params.id}`,
                method: 'GET',
            }),
            providesTags: [STORE_TAGS.OFFICE_SPACES],
        }),

        removeOfficeSpace: builder.mutation<{ message: string }, { userSpaceId: string }>({
            query: ({ userSpaceId }) => ({
                url: OFFICE_SPACES_ROUTES.REMOVE_OFFICE_SPACE_ROUTE,
                method: 'DELETE',
                body: { userSpaceId },
            }),
            invalidatesTags: [STORE_TAGS.OFFICE_SPACES, STORE_TAGS.ALL_BOOKINGS],
        }),

        registerUser: builder.mutation<{ message: string }, RegisterUserBody>({
            query: ({ telegramUserName, telegramUserId, name, surname, queryId }) => ({
                url: USERS_ROUTES.REGISTER_USER_ROUTE,
                method: 'POST',
                body: { telegramUserName, telegramUserId, name, surname, queryId },
            }),
            invalidatesTags: [STORE_TAGS.USER],
        }),

        editOfficeSpace: builder.mutation<{ message: string }, EditOfficeSpaceBody>({
            query: ({ spaceName, bookingId, startDate, endDate }) => ({
                url: `/office-spaces/bookings/${bookingId}`,
                method: 'PATCH',
                body: { spaceName, startDate, endDate },
            }),
            invalidatesTags: [STORE_TAGS.OFFICE_SPACES, STORE_TAGS.ALL_BOOKINGS],
        }),

        getAllMeetingRoomsUsers: builder.query<
            GetMeetingRoomUsersResponse[],
            { date: string; endDate?: string; repeatValue?: string }
        >({
            query: ({ date, endDate, repeatValue }) => ({
                url: OFFICE_SPACES_ROUTES.GET_ALL_MEETING_ROOMS_USERS_ROUTE,
                method: 'GET',
                params: { date, endDate, repeatValue },
            }),

            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                if (data) {
                    dispatch(setAllMeetingRoomUsers({ newMeetingRoomUsers: data }));
                }
            },
            providesTags: [STORE_TAGS.ALL_MEETING_ROOMS],
        }),

        getParticularBooking: builder.query<GetBookedOfficeSpacesResponse, { id: string }>({
            query: ({ id }) => ({
                url: OFFICE_SPACES_ROUTES.GET_PARTICULAR_BOOKING_ROUTE,
                method: 'GET',
                params: { id },
            }),
        }),

        editMeetingRoomBooking: builder.mutation<{ message: string }, EditMeetingRoomBooking>({
            query: ({
                id,
                startTime,
                endTime,
                allDay,
                isRepeated,
                repeatValue,
                repeatEndDate,
                isRepeatAlways,
                isEditCurrent,
                editDate,
                /*   userEmail, */
            }) => ({
                url: OFFICE_SPACES_ROUTES.EDIT_MEETING_ROOM_BOOKING_ROUTE,
                method: 'PATCH',
                body: {
                    id,
                    startTime,
                    endTime,
                    allDay,
                    isRepeated,
                    repeatValue,
                    repeatEndDate,
                    isRepeatAlways,
                    isEditCurrent,
                    editDate,
                    /*    userEmail, */
                },
            }),
            invalidatesTags: [
                STORE_TAGS.MEETING_ROOM,
                STORE_TAGS.ALL_MEETING_ROOMS,
                STORE_TAGS.ALL_BOOKINGS,
            ],
        }),

        removeMeetingRoomBooking: builder.mutation<
            { message: string },
            RemoveMeetingRoomBookingBody
        >({
            query: ({ id, removeCurrent, removeAll, removeDate, removeUpcoming }) => ({
                url: OFFICE_SPACES_ROUTES.REMOVE_MEETING_ROOM_BOOKING_ROUTE,
                method: 'DELETE',
                body: { id, removeCurrent, removeAll, removeDate, removeUpcoming },
            }),
            invalidatesTags: [
                STORE_TAGS.MEETING_ROOM,
                STORE_TAGS.ALL_MEETING_ROOMS,
                STORE_TAGS.ALL_BOOKINGS,
            ],
        }),

        getParticularMeetingRoomBooking: builder.query<GetMeetingRoomUsersResponse, { id: string }>(
            {
                query: ({ id }) => ({
                    url: OFFICE_SPACES_ROUTES.GET_PARTICULAR_MEETING_ROOM_BOOKING_ROUTE,
                    method: 'GET',
                    params: { id },
                }),
            },
        ),

        getAllBookings: builder.query<
            GetAllBookingsResponse,
            {
                telegramUserId: number;
                limit: number;
                offset: number;
                historyOffset: number;
                historyLimit: number;
            }
        >({
            query: ({ telegramUserId, limit, offset, historyOffset, historyLimit }) => ({
                url: OFFICE_SPACES_ROUTES.GET_ALL_BOOKINGS_ROUTE,
                method: 'GET',
                params: { telegramUserId, limit, offset, historyOffset, historyLimit },
            }),

            providesTags: [STORE_TAGS.ALL_BOOKINGS],
        }),

        removeAllBookings: builder.mutation<
            { message: string },
            {
                bookingIds: string[];
                removeAll?: boolean;
                isHistory?: boolean;
                isMeetingRoom?: boolean;
                isOfficeSpace?: boolean;
            }
        >({
            query: ({ bookingIds, removeAll, isHistory, isMeetingRoom, isOfficeSpace }) => ({
                url: OFFICE_SPACES_ROUTES.REMOVE_ALL_BOOKINGS_ROUTE,
                method: 'DELETE',
                body: { bookingIds, removeAll, isHistory, isMeetingRoom, isOfficeSpace },
            }),
            invalidatesTags: [
                STORE_TAGS.MEETING_ROOM,
                STORE_TAGS.ALL_MEETING_ROOMS,
                STORE_TAGS.ALL_BOOKINGS,
            ],
        }),

        getGoogleAuth: builder.query<GetMeetingRoomUsersResponse, { telegramUserId: number }>({
            query: ({ telegramUserId }) => ({
                url: GOOGLE_ROUTES.GET_GOOGLE_AUTH_TOKEN,
                method: 'GET',
                params: { telegramUserId },
            }),
        }),

        googleLogout: builder.mutation<GetMeetingRoomUsersResponse, { telegramUserId: number }>({
            query: ({ telegramUserId }) => ({
                url: GOOGLE_ROUTES.GOOGLE_LOGOUT_ROUTE,
                method: 'POST',
                body: { telegramUserId },
            }),
        }),
    }),
});

export const {
    useGetOfficeSpaceByIdQuery,
    useChangeUserMutation,
    useCreateBookingPeriodMutation,
    useLazyGetMeetingRoomUsersQuery,
    useBookMeetingRoomMutation,
    useLazyGetBookedOfficeSpacesQuery,
    useBookOfficeSpaceMutation,
    useRemoveOfficeSpaceMutation,
    useRegisterUserMutation,
    useEditOfficeSpaceMutation,
    useGetAllMeetingRoomsUsersQuery,
    useLazyGetParticularBookingQuery,
    useEditMeetingRoomBookingMutation,
    useRemoveMeetingRoomBookingMutation,
    useLazyGetParticularMeetingRoomBookingQuery,
    useLazyGetAllBookingsQuery,
    useRemoveAllBookingsMutation,
    useLazyGetAllMeetingRoomsUsersQuery,
    useLazyGetGoogleAuthQuery,
    useGoogleLogoutMutation,
    useGetUsersMutation,
} = rootApi;
