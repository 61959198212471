/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const headerWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    height: '50px',
});

const chosenDateStyles = css({
    '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
            color: '#3366FF',
            transition: 'all 0.3s ease-out',
            cursor: 'pointer',
        },
    },
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'center',
    transition: 'all 0.3s ease-out',
});

const goBackBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                background: '#3366FF',
                transition: 'all 0.3s ease-out',
            },
            '&:hover .arrowIcon': {
                stroke: 'white',
                transition: 'all 0.3s ease-out',
            },
        },
        minWidth: '50px',
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        border: colorScheme === 'dark' ? '1px solid #383B40' : '1px solid #C4C4C4',
        cursor: 'pointer',
        background: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
        transition: 'all 0.3s ease-out',
        position: 'relative',
    });
};

const checkBoxWrapperStyles = ({ isDisable }: { isDisable: boolean }) => {
    const textHoverObj = {
        color: '#FFFFFF',
        transition: 'all 0.3s ease-out',
    };

    const hoverObj = {
        color: '#FFFFFF',
        transition: 'all 0.3s ease-out',
        background: '#3366FF',
    };
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover .checkBoxText': !isDisable ? textHoverObj : {},
            '&:hover .checkBox': !isDisable ? hoverObj : {},
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '10px 0px',
        justifyContent: 'flex-end',
    });
};

const checkboxStyles = ({
    checked,
    colorScheme,
    isDisable,
}: {
    checked: boolean;
    colorScheme: string;
    isDisable: boolean;
}) => {
    let textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
    let bgColor;
    const hoverObj = {
        background: '#3366FF',
        color: '#FFFFFF',
        transition: 'all 0.3s ease-out',
    };

    if (colorScheme === 'dark') {
        bgColor = '#1F2023';
    } else {
        bgColor = '#FFFFFF';
    }

    if (checked) {
        textColor = '#FFFFFF';
        bgColor = '#3366FF';
    }

    if (isDisable) {
        bgColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
        textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
    }

    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': !isDisable ? hoverObj : {},
        },
        '-webkit-appearance': 'none',
        appearance: 'none',
        minWidth: '50px',
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'all 0.3s ease-out',
        color: textColor,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '8px',
        lineHeight: '10px',
        border: colorScheme === 'dark' ? '1px solid #383B40' : '1px solid #C4C4C4',
        background: bgColor,
    });
};

const tableHeaderStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 10px',
        height: '50px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        backgroundColor: colorScheme === 'dark' ? '#27292D' : '#E8E8E8',
        borderRadius: '5px',
    });
};

const backDateBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                background: '#3366FF',
                transition: 'all 0.3s ease-out',
            },
            '&:hover .arrowIcon': {
                stroke: 'white',
                transition: 'all 0.3s ease-out',
            },
        },
        position: 'absolute',
        left: '0px',
        minWidth: '50px',
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        cursor: 'pointer',
        background: colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4',
        transition: 'all 0.3s ease-out',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
    });
};

const headerInfoWrapperStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
});

const meetingRoomNameStyles = css({
    fontSize: '8px',
    fontWeight: 600,
    textAlign: 'center',
});

const nextDateBtnStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                background: '#3366FF',
                transition: 'all 0.3s ease-out',
            },
            '&:hover .arrowIcon': {
                stroke: 'white',
                transition: 'all 0.3s ease-out',
            },
        },
        position: 'absolute',
        right: '0px',
        minWidth: '50px',
        minHeight: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        cursor: 'pointer',
        background: colorScheme === 'dark' ? '#2D2F34' : '#C4C4C4',
        transition: 'all 0.3s ease-out',
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
    });
};

const checkboxLabelStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
    });
};

const checkboxTextStyles = ({
    colorScheme,
    checked,
    isDisable,
}: {
    colorScheme: string;
    checked: boolean;
    isDisable: boolean;
}) => {
    let textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';

    if (checked) {
        textColor = '#FFFFFF';
    }

    if (isDisable) {
        textColor = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
    }
    return css(
        mq({
            position: 'absolute',
            left: [
                'calc(50px / 2 - 27px / 2)',
                'calc(50px / 2 - 27px / 2)',
                'calc(50px / 2 - 27px / 2)',
                'calc(50px / 2 - 27px / 2)',
            ],
            color: textColor,
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '8px',
            lineHeight: '10px',
            transition: 'all 0.3s ease-out',
        }),
    );
};

const styles = {
    chosenDateStyles,
    headerWrapperStyles,
    goBackBtnStyles,
    checkBoxWrapperStyles,
    checkboxStyles,
    tableHeaderStyles,
    backDateBtnStyles,
    headerInfoWrapperStyles,
    meetingRoomNameStyles,
    nextDateBtnStyles,
    checkboxLabelStyles,
    checkboxTextStyles,
};

export default styles;
