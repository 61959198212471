/** @jsxImportSource @emotion/react */
import React, { ReactElement } from 'react';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

interface RemoveModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    itemsCount: number;
    onDeleteBookingsHandler: () => void;
}

export function RemoveModal({
    setOpen,
    open,
    itemsCount,
    onDeleteBookingsHandler,
}: RemoveModalProps): ReactElement {
    const { colorScheme } = useTelegram();

    const onCloseHandler = () => {
        setOpen(false);
    };
    return (
        <Dialog
            onClose={() => setOpen(false)}
            open={open}
            css={styles.dialogStyles({ colorScheme })}
        >
            <div css={styles.modalWrapperStyles}>
                <div css={styles.headerWrapperStyles}>
                    <div css={styles.closeBtnWrapperStyles}>
                        <IconButton
                            css={styles.closeBtnStyles({ colorScheme })}
                            onClick={onCloseHandler}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <p css={styles.modalInfoTextStyles({ colorScheme })}>
                        Are you sure you want to delete {itemsCount} items?
                    </p>
                </div>
                <div css={styles.btnWrapperStyles}>
                    <button
                        type="button"
                        css={styles.confirmBtnStyles}
                        onClick={onDeleteBookingsHandler}
                    >
                        Yes
                    </button>
                    <button
                        type="button"
                        css={styles.cancelBtnStyles({ colorScheme })}
                        onClick={onCloseHandler}
                    >
                        No
                    </button>
                </div>
            </div>
        </Dialog>
    );
}
