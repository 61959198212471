import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import { useTelegram } from '../../../hooks/useTelegram';

export const FullPageLoader: React.FC = () => {
    const { colorScheme } = useTelegram();

    return (
        <Stack
            height="100vh"
            overflow="hidden"
            justifyContent="center"
            alignItems="center"
            bgcolor={colorScheme === 'dark' ? '#27292D' : '#E8E8E8'}
        >
            <CircularProgress />
        </Stack>
    );
};
