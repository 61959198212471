/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const wrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        padding: '20px 20px 70px 20px',
        height: '100vh',
        overflowY: 'scroll',
        background: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
    });
};

const tabsWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100vh',
        backgroundColor: colorScheme === 'dark' ? '#1F2023' : '#FFFFFF',
    });
};

const deleteBtnStyles = css({
    '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
            opacity: '0.7',
            transition: 'all 0.3s ease-out',
        },
    },
    height: '50px',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    background: '#EF3340',
    borderRadius: '5px',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    padding: '20px 0px',
    transition: 'all 0.3s ease-out',
});

const appBarStyles = css({
    top: 'auto',
    bottom: '20px',
    width: 'calc(100% - 40px)',
    height: '50px',
    left: '20px',
    background: '#EF3340',
    borderRadius: '5px',
});

const styles = {
    wrapperStyles,
    tabsWrapperStyles,
    deleteBtnStyles,
    appBarStyles,
};

export default styles;
