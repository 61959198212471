/** @jsxImportSource @emotion/react */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, CircularProgress } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    useBookMeetingRoomMutation,
    useLazyGetAllMeetingRoomsUsersQuery,
} from '../../../api/rootApi';
import { APP_ROUTES, MEETING_ROOMS_TIMES } from '../../../constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import TimeRow from '../../common/timeRow';
import { useTelegram } from '../../../hooks/useTelegram';
import { ConfirmModal } from '../bookMeetingRoomLayout/confirmModal';
import { setAllDayBooking, setAllMeetingRoomUsers } from '../../../store/reducers/meetingRooms';
import MeetingRoomTimetableHeader from './meetingRoomTimetableHeader';
import TableHeader from '../../common/tableHeader';
import BookedTimeCell from '../../common/bookedTimeCell';
import EmptyRow from '../../common/timeRow/emptyRow';
import { findEnableTimesIntervals } from '../../../utils/findEnableTimeIntervals';

import styles from './styles';
import { meetingRoomBookingApi } from '../../../api/meetingRoomBookingApi';
import { MEETING_ROOM_BOOKING_LIST_INCLUDE } from '../../../api/types';
import { MeetingRoomBooking } from '../../../types';
import { FullPageLoader } from '../../common/FullPageLoader/FullPageLoader';
import { useEnhancedNavigate } from '../../../hooks/useEnhancedNavigate';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

export const FAKE_ID = '1';

function MeetingRoomTimetable() {
    const [calendarActive, setCalendarActive] = useState(false);
    const [calendarValue, setCalendarValue] = useState<Dayjs>(dayjs());
    const [checked, setChecked] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [startTimeValue, setStartTimeValue] = useState<Dayjs | null | undefined>();
    const [endTimeValue, setEndTimeValue] = useState<Dayjs | null | undefined>();
    const [dateState, setDateState] = useState<string | undefined>(undefined);
    const [meetingRoomIdState, setMeetingRoomIdState] = useState<string | undefined>(undefined);

    const [bookings, setBookings] = useState<MeetingRoomBooking[]>([]);

    const { tg, user, queryId, tgUserId, colorScheme } = useTelegram();

    const [queryParams] = useSearchParams();

    const [bookMeetingRoom] = useBookMeetingRoomMutation();

    const { scrollNavigate } = useEnhancedNavigate();

    const meetingRoomName = queryParams.get('meetingRoomName') ?? undefined;
    const chosenDate = queryParams.get('date') ?? undefined;

    const {
        isLoading,
        data: bookingsResponse,
        refetch,
    } = meetingRoomBookingApi.useListQuery({
        filters: {
            date: calendarValue.format('YYYY-MM-DD'),
            meetingRoomName: [meetingRoomName as string],
        },
        include: [
            MEETING_ROOM_BOOKING_LIST_INCLUDE.MEETING_ROOMS,
            MEETING_ROOM_BOOKING_LIST_INCLUDE.USERS,
        ],
    });

    const meetingRoomId = queryParams.get('meetingRoomId') ?? undefined;

    const isMyBookings = Boolean(queryParams.get('isMyBookings'));

    const userInfo = useAppSelector((store) => store.userReducer.user);

    const dispatch = useDispatch();

    const mapListResponseToState = useCallback(() => {
        if (bookingsResponse) {
            const mappedResponse = bookingsResponse.data.map(
                ({ user: _user, meetingRoom: _mr, ...bookingInfo }) => bookingInfo,
            );
            setBookings(mappedResponse);
        }
    }, [bookingsResponse]);

    useEffect(() => {
        if (chosenDate && meetingRoomName) {
            setCalendarValue(dayjs(chosenDate));
            setDateState(chosenDate);
            setMeetingRoomIdState(meetingRoomId);
        }
    }, []);

    const [getAllMeetingRoomUsers, { isFetching: isFetchingGetAllMeetingRoomUsers }] =
        useLazyGetAllMeetingRoomsUsersQuery();

    useEffect(() => {
        if (dateState && meetingRoomIdState) {
            dispatch(setAllMeetingRoomUsers({ newMeetingRoomUsers: [] }));
            getAllMeetingRoomUsers({ date: dateState });
        }
    }, [dateState, meetingRoomIdState]);

    const onSendData = useCallback(() => {
        if (
            queryId &&
            startTimeValue &&
            endTimeValue &&
            meetingRoomName &&
            meetingRoomId &&
            tgUserId &&
            dateState
        ) {
            const startTimeString = startTimeValue.format('HH:mm:ss');
            const endTimeString = endTimeValue.format('HH:mm:ss');

            const data = {
                queryId,
                meetingRoomId,
                telegramUserId: tgUserId,
                date: dateState,
                startTime: startTimeString,
                endTime: endTimeString,
                spaceName: meetingRoomName,
                allDay: checked,
            };

            bookMeetingRoom(data);
        }
    }, [startTimeValue, endTimeValue]);

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData);
        return () => {
            tg.offEvent('mainButtonClicked', onSendData);
        };
    }, [onSendData]);

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Confirm',
        });
    }, []);

    useEffect(() => {
        if (!startTimeValue || !endTimeValue || modalActive) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [startTimeValue, endTimeValue, modalActive]);

    const meetingRoomUsers = useAppSelector(
        (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
    ).filter((b) => {
        const roomNumber = meetingRoomName?.split(' ')[2];
        if (b.userMeetingRoomInfo.spaceName === `MR${roomNumber}`) {
            return true;
        }
        return false;
    });

    const times = MEETING_ROOMS_TIMES.map((t) => {
        return <TimeRow key={t} time={t} />;
    });

    const isAuthorHaveBookings = meetingRoomUsers.some((m) => m.telegramUserId === tgUserId);

    const onCancel = () => {
        setModalActive(false);
        setChecked(false);
    };

    const onConfirm = () => {
        const minTime = dayjs(`${dateState} 07:00:00`);
        const maxTime = dayjs(`${dateState} 23:00:00`);
        setStartTimeValue(minTime);
        setEndTimeValue(maxTime);

        if (userInfo && tgUserId && dateState && meetingRoomId && meetingRoomName) {
            dispatch(
                dispatch(
                    setAllDayBooking({
                        newMeetingRoomUser: {
                            id: FAKE_ID,
                            name: userInfo.name,
                            surname: userInfo.surname,
                            telegramUserName: user,
                            telegramUserId: tgUserId,
                            userMeetingRoomInfo: {
                                id: userInfo.id,
                                spaceName: `MR${meetingRoomName.split(' ')[2]}`,
                                roomId: meetingRoomId,
                                date: dateState,
                                endTime: '23:00:00',
                                startTime: '07:00:00',
                                isRepeated: false,
                                repeatValue: null,
                                repeatEndDate: null,
                                isRepeatAlways: false,
                            },
                        },
                    }),
                ),
            );
        }

        setModalActive(false);
    };

    const bookedTimeCell = bookingsResponse?.data.map(
        ({ user: bookingUser, meetingRoom, ...bookingInfo }) => {
            return (
                <BookedTimeCell
                    meetingRoomName={meetingRoomName as string}
                    booking={bookingInfo}
                    user={bookingUser!}
                    key={bookingInfo.id}
                />
            );
        },
    );

    const enableTimeRows = findEnableTimesIntervals(bookings, dateState);

    const handleCellClick = (startTime: string, endTime: string) => {
        const nowDate = dayjs().format('YYYY-MM-DD');

        const formattedStartTime = dayjs(`${nowDate} ${startTime}`).format('HH:mm');

        const formattedEndTime = dayjs(`${nowDate} ${endTime}`).format('HH:mm');

        const formattedDate = calendarValue.format('YYYY-MM-DD');

        scrollNavigate({
            top: 0,
            left: 0,
            path: APP_ROUTES.BOOK_MEETING_ROOM,
            replace: true,
            behavior: 'smooth',
            queryParams: {
                bookingStartTime: formattedStartTime,
                bookingEndTime: formattedEndTime,
                bookingDate: formattedDate,
                meetingRoomName: meetingRoomName as string,
            },
        });
    };

    const enableRows = enableTimeRows.map((i) => {
        return (
            <EmptyRow
                key={Number(new Date(`${i.startTime}`))}
                enableStartTime={dayjs(`${i.startTime}`).format('HH:mm:ss')}
                enableEndTime={dayjs(`${i.endTime}`).format('HH:mm:ss')}
                meetingRoomDateValue={dateState}
                isTimeRowClickable
                meetingRoomName={meetingRoomName as string}
                onCellClick={handleCellClick}
            />
        );
    });

    useEffect(() => {
        refetch();
    }, [calendarValue]);

    useEffect(() => {
        mapListResponseToState();
    }, [mapListResponseToState]);

    if (isLoading) {
        return <FullPageLoader />;
    }

    return (
        <div css={styles.wrapperStyles({ colorScheme })}>
            {isFetchingGetAllMeetingRoomUsers ? (
                <Grid item padding={7} display="flex" justifyContent="center" xs={12}>
                    <CircularProgress />
                </Grid>
            ) : (
                <div>
                    <MeetingRoomTimetableHeader
                        isMyBookings={isMyBookings}
                        setChecked={setChecked}
                        checked={checked}
                        isAuthorHaveBookings={isAuthorHaveBookings}
                        setModalActive={setModalActive}
                        setStartTimeValue={setStartTimeValue}
                        setEndTimeValue={setEndTimeValue}
                        userInfo={userInfo}
                        dateState={dateState}
                        meetingRoomId={meetingRoomId}
                        meetingRoomName={meetingRoomName}
                    />
                    <Grid>
                        <TableHeader
                            dateState={dateState}
                            setDateState={setDateState}
                            setChecked={setChecked}
                            setCalendarActive={setCalendarActive}
                            calendarActive={calendarActive}
                            calendarValue={calendarValue}
                            setCalendarValue={setCalendarValue}
                            meetingRoomName={meetingRoomName}
                        />
                        <Grid container position="relative">
                            {times}
                            {enableRows}
                            {bookedTimeCell}
                        </Grid>
                    </Grid>
                </div>
            )}
            <ConfirmModal
                onCancel={onCancel}
                open={modalActive}
                setOpen={setModalActive}
                onConfirm={onConfirm}
            />
        </div>
    );
}

export default MeetingRoomTimetable;
