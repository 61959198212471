/** @jsxImportSource @emotion/react */
import React from 'react';
import { SerializedStyles } from '@emotion/react';
import { useTelegram } from '../hooks/useTelegram';

function EditIcon({ editIconStyles }: { editIconStyles: SerializedStyles }) {
    const { colorScheme } = useTelegram();
    return (
        <svg
            css={editIconStyles}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.39254 2.55219L3.57712 8.7076C3.35754 8.94135 3.14504 9.40177 3.10254 9.72052L2.84045 12.0155C2.74837 12.8443 3.34337 13.4109 4.16504 13.2693L6.44587 12.8797C6.76462 12.823 7.21087 12.5893 7.43045 12.3484L13.2459 6.19302C14.2517 5.13052 14.705 3.91927 13.1396 2.43885C11.5813 0.972605 10.3984 1.48969 9.39254 2.55219Z"
                className="editIcon"
                stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.42188 3.57483C8.72646 5.52983 10.3131 7.02441 12.2823 7.22275"
                className="editIcon"
                stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.125 15.5834H14.875"
                className="editIcon"
                stroke={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default EditIcon;
