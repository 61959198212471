/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const wrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        marginTop: '15px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
    });
};

const checkboxWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginRight: '10px',
    alignItems: 'center',
});

const menuItemStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                background: '#3366FF',
                color: '#FFFFFF',
            },
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        width: '100%',
        padding: '5px',
        height: '22px !important',
        minHeight: '22px !important',
        maxHeight: '22px !important',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: `Montserrat !important`,
        fontStyle: 'normal !important',
        borderRadius: '3px',
    });
};

const selectWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        marginTop: '5px',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        '& .inputLabel': {
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiFormHelperText-root': {
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiInputBase-root': {
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
            height: '30px',
            width: '80px',
            fontFamily: `Montserrat !important`,
            fontStyle: 'normal !important',
        },

        '& .MuiInputBase-input': {
            display: 'flex',
            alignItems: 'center',
            padding: '0px 8px',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colorScheme === 'dark' ? '#383B40' : '#C4C4C4',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
        },

        '& .MuiFormLabel-root': {
            color: colorScheme === 'dark' ? '#383B40 !important' : '#000000 !important',
        },

        '& .Mui-disabled .MuiOutlinedInput-input': {
            '-webkit-text-fill-color':
                colorScheme === 'dark' ? '#383B40 !important' : '#000000 !important',
        },

        '& .MuiSelect-select': {
            paddingRight: '0px !important',
        },

        '& .MuiSvgIcon-root': {
            right: '2px !important',
            fontSize: '22px !important',
        },

        '& .MuiIconButton-root': {
            paddingRight: '0px !important',
            paddingLeft: '0px !important',
        },

        '& .MuiMenu-paper': {
            backgroundColor: 'green',
        },
    });
};

const selectTitleStyles = ({
    colorScheme,
    disabled,
}: {
    colorScheme: string;
    disabled?: boolean;
}) => {
    let color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';

    if (disabled) {
        color = '#383B40';
    }
    return css(
        mq({
            fontWeight: 600,
            fontSize: ['10px', '14px', '14px', '14px'],
            lineHeight: ['8px', '12px', '12px', '12px'],
            color,
            marginTop: '2px',
        }),
    );
};

const radioButtonsWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
});

const radioButtonWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
});

const radioBtnTitleStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '12px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        marginRight: '10px',
    });
};

const datePickerWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
});

const checkboxLabelStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '12px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        marginLeft: '10px',
    });
};

const repeatLabelStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '12px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        marginRight: '10px',
    });
};

const styles = {
    wrapperStyles,
    checkboxWrapperStyles,
    menuItemStyles,
    selectWrapperStyles,
    selectTitleStyles,
    radioButtonsWrapperStyles,
    radioButtonWrapperStyles,
    radioBtnTitleStyles,
    datePickerWrapperStyles,
    checkboxLabelStyles,
    repeatLabelStyles,
};

export default styles;
