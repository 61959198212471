import { ThemeProvider, createTheme } from '@mui/material';
import { useTelegram } from '../../../hooks/useTelegram';

export function MUIThemeProvider({ children }: { children: JSX.Element }) {
    const { colorScheme } = useTelegram();

    const theme = createTheme({ palette: { mode: colorScheme } });

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
