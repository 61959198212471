/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import OfficeSpace from './OfficeSpace/OfficeSpace';

import styles from './styles';
import { useTelegram } from '../../../hooks/useTelegram';
import { MeetingRoom } from './MeetingRoom/MeetingRoom';
import { rootApi } from '../../../api/rootApi';
import { GetBookedOfficeSpacesResponse } from '../../../api/types';

type OfficeMapProps = {
    config?: MapConfig;
    selectedSpace?: string;
    startDate?: string | null;
    endDate?: string | null;
    onOfficeSpaceClick?: (value: string) => void;
    onMeetingRoomClick?: () => void;
};

export type MapConfig = {
    id: string;
    options: {
        disabled?: boolean;
        isBooked?: boolean;
        isMyBook?: boolean;
        render?: React.ReactElement;
    };
}[];

export function OfficeMap({
    config,
    selectedSpace,
    endDate,
    startDate,
    onOfficeSpaceClick,
    onMeetingRoomClick,
}: OfficeMapProps) {
    const [mapConfig, setMapConfig] = useState<MapConfig | undefined>(config);
    const [getBookedOfficeSpaces] = rootApi.useLazyGetBookedOfficeSpacesQuery();
    const { colorScheme } = useTelegram();

    useEffect(() => {
        if (startDate) {
            const endDateToSend = startDate === endDate || !endDate ? undefined : endDate;

            const fetchbookedOfficeSpaces = async () => {
                const { data: response } = await getBookedOfficeSpaces({
                    startDate,
                    endDate: endDateToSend,
                });

                if (response) {
                    const mappedResponse = mapResponseToMapConfig(response);
                    setMapConfig((prev) => prev?.concat(mappedResponse));
                }
            };

            fetchbookedOfficeSpaces();
        }
    }, []);

    return (
        <div css={styles.officeMapWrapperStyles({ colorScheme })} key={JSON.stringify(mapConfig)}>
            <div css={styles.headerWrapperStyles}>
                <div css={styles.topManagementWrapperStyles}>
                    <div css={styles.topManagementRoomStyles({ colorScheme })}>
                        <p>TOP M.</p>
                    </div>
                    <MeetingRoom
                        id="MR1"
                        onClick={onMeetingRoomClick}
                        {...retrieveOptionsFromConfig({ config: mapConfig, id: 'MR1' })}
                    />
                </div>
                <div css={styles.enterStyles({ colorScheme })}>
                    <p className="enterText">Enter</p>
                </div>
                <div css={styles.wcStyles({ colorScheme })}>W/C</div>
                <div css={styles.kitchenLawAccWrapperStyles}>
                    <div css={styles.kitchenStyles({ colorScheme })}>Kitchen</div>
                    <div css={styles.lawAccStyles({ colorScheme })}>
                        <p>L&A</p>
                    </div>
                    {['MR2', 'MR3'].map((id) => (
                        <MeetingRoom
                            key={id}
                            id={id}
                            onClick={onMeetingRoomClick}
                            {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                        />
                    ))}
                </div>
            </div>
            <div css={styles.wrapperStyles}>
                <div css={styles.firstColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['37', '38', '39'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['36', '35', '34'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.secondColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['30', '31', '32', '33'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['29', '28', '27', '26'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.thirdColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['22', '23', '24', '25'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['21', '20', '19', '18'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.fourthColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['15', '16', '17'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['14', '13', '12', '11'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.fifthColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['8', '9', '10'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['5', '6', '7'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
                <div css={styles.sixthColumnWrapperStyles}>
                    <div css={styles.spaceLeftColumnStyles}>
                        {['3', '4'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                    <div css={styles.spaceRightColumnStyles}>
                        {['2', '1'].map((id) => (
                            <OfficeSpace
                                key={id}
                                id={id}
                                onClick={onOfficeSpaceClick}
                                {...retrieveOptionsFromConfig({ config: mapConfig, id })}
                                selected={selectedSpace}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div css={styles.windowWrapperStyles}>
                <div css={styles.windowStyles({ colorScheme })}>Window</div>
            </div>
        </div>
    );
}

function retrieveOptionsFromConfig({ config, id }: { config?: MapConfig; id?: string }) {
    const options = config?.find((record) => record.id === id);
    return options?.options;
}

function mapResponseToMapConfig(data: GetBookedOfficeSpacesResponse[]): MapConfig {
    return data.map((item) => ({
        id: item.name,
        options: {
            isBooked: true,
        },
    }));
}
