import { z } from 'zod';

export const addNewBookingSchema = z.object({
    userId: z.string(),
    spaceName: z.string(),
    startDate: z.string().nullable(),
    endDate: z.string().nullable(),
    isRepeatAlways: z.boolean().optional(),
});

export type AddNewBookingSchema = z.infer<typeof addNewBookingSchema>;
