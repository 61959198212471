/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { useTelegram } from '../../../hooks/useTelegram';
import { styles } from './styles';
import NextDateIcon from '../../../assets/nextDateIcon';
import BackDateIcon from '../../../assets/backDateIcon';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import Calendar from '../calendar';

type ScheduleDatePickerProps = {
    label?: string;
    date: Dayjs;
    onChange: (value: Dayjs) => void;
};

export const ScheduleDatePicker: React.FC<ScheduleDatePickerProps> = ({
    label,
    date,
    onChange,
}) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

    const [isPrevButtonVisible, setIsPrevButtonVisible] = useState<boolean>(false);
    const [isNextButtonVisible, setIsNextButtonVisible] = useState<boolean>(false);

    const { colorScheme } = useTelegram();
    const calendarRef = useRef<HTMLDivElement>(null);

    const handleNextClick = () => {
        const newDate = date.add(1, 'day');
        onChange(newDate);
    };

    const handlePrevClick = () => {
        const newDate = date.subtract(1, 'day');
        onChange(newDate);
    };

    const handleOpenCalendar = () => {
        setIsCalendarOpen(true);
    };

    const handleCloseCalendar = () => {
        setIsCalendarOpen(false);
    };

    const handleCalendarValueChange = (value: Dayjs | null) => {
        if (value) onChange(value);
    };

    useOnClickOutside(calendarRef, handleCloseCalendar);

    useEffect(() => {
        const today = dayjs();

        const isPrevVisible = date
            .set('hours', 0)
            .set('minutes', 0)
            .set('milliseconds', 0)
            .isAfter(today.set('hours', 0).set('minutes', 0).set('milliseconds', 0));

        const isNextVisible = date
            .set('hours', 0)
            .set('minutes', 0)
            .set('milliseconds', 0)
            .isBefore(today.add(1, 'year'));

        setIsNextButtonVisible(isNextVisible);
        setIsPrevButtonVisible(isPrevVisible);
    }, [date]);

    return (
        <div css={styles.headerStyles({ colorScheme })}>
            <button
                type="button"
                css={styles.backButtonStyles({ colorScheme, isVisible: isPrevButtonVisible })}
                onClick={handlePrevClick}
            >
                <BackDateIcon />
            </button>
            <div css={styles.dateLabelStyles} ref={calendarRef}>
                <p onClick={handleOpenCalendar} css={styles.dateStyles}>
                    {date?.format('DD.MM.YYYY')}
                </p>
                {label && <div css={styles.labelStyles}>{label}</div>}
                {isCalendarOpen && (
                    <Calendar
                        value={date}
                        onChange={handleCalendarValueChange}
                        onClose={handleCloseCalendar}
                    />
                )}
            </div>
            <button
                type="button"
                css={styles.nextButtonStyles({ colorScheme, isVisible: isNextButtonVisible })}
                onClick={handleNextClick}
            >
                <NextDateIcon />
            </button>
        </div>
    );
};
