import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { officeSpaceReducer } from './reducers/officeSpace';
import { meetingRoomsReducer } from './reducers/meetingRooms';
import { allBookingsReducer } from './reducers/allBookings';
import { userReducer } from './reducers/user';
import { rootApi } from '../api/rootApi';
import { rtkQueryErrorMiddleware } from './middlewares/errorMiddleware';
import { officeSpaceApi } from '../api/officeSpaceApi';
import { officeSpaceBookingApi } from '../api/officeSpaceBookingApi';
import { usersApi } from '../api/usersApi';
import { meetingRoomBookingApi } from '../api/meetingRoomBookingApi';

const combinedReducer = combineReducers({
    officeSpaceReducer,
    meetingRoomsReducer,
    allBookingsReducer,
    userReducer,
    [rootApi.reducerPath]: rootApi.reducer,
    [officeSpaceApi.reducerPath]: officeSpaceApi.reducer,
    [officeSpaceBookingApi.reducerPath]: officeSpaceBookingApi.reducer,
    [meetingRoomBookingApi.reducerPath]: meetingRoomBookingApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
});

export const store = configureStore({
    reducer: combinedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            rootApi.middleware,
            officeSpaceApi.middleware,
            officeSpaceBookingApi.middleware,
            meetingRoomBookingApi.middleware,
            usersApi.middleware,
            rtkQueryErrorMiddleware,
        ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
