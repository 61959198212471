/* eslint-disable @typescript-eslint/no-magic-numbers */
import dayjs from 'dayjs';
import { MAX_TIME, MEETING_ROOMS_TIMES, MIN_TIME } from '../constants';
import { MeetingRoomBooking } from '../types';

const minMax = require('dayjs/plugin/minMax');

dayjs.extend(minMax);

interface EnableInterval {
    startTime: Date;
    endTime: Date;
}

export const findEnableTimesIntervals = (bookings: MeetingRoomBooking[], chosenDate?: string) => {
    // Определяем начальную и конечную даты
    const startOfDay = new Date(`${chosenDate}T07:00:00`);
    const endOfDay = new Date(`${chosenDate}T23:00:00`);

    // Создаем массив интервалов времени с 7:00 до 23:00
    const intervals = [];
    let current = startOfDay;
    while (current < endOfDay) {
        intervals.push({
            startTime: new Date(current.getTime()),
            endTime: new Date(current.getTime() + 60 * 60 * 1000),
        });
        current = new Date(current.getTime() + 60 * 60 * 1000);
    }

    const sortedBookings = bookings.sort(
        (a, b) =>
            Number(new Date(`${chosenDate}T${a.startTime}`)) -
            Number(new Date(`${chosenDate}T${b.startTime}`)),
    );

    const freeIntervals = [];

    let prevEndTime = new Date(`${chosenDate}T07:00:00`);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sortedBookings.length; i++) {
        const currStartTime = new Date(`${chosenDate}T${bookings[i].startTime}`);
        const currEndTime = new Date(`${chosenDate}T${bookings[i].endTime}`);
        if (
            currStartTime > prevEndTime &&
            currStartTime.getHours() >= MIN_TIME &&
            currEndTime.getHours() <= MAX_TIME
        ) {
            // проверка на интересующий нас временной промежуток
            freeIntervals.push({
                startTime: prevEndTime,
                endTime: currStartTime,
            });
        }

        // @ts-ignore
        const maxDate = dayjs.max(dayjs(prevEndTime), dayjs(currEndTime));

        prevEndTime = new Date(`${maxDate.format('YYYY-MM-DD')}T${maxDate.format('HH:mm:ss')}`);
    }

    if (prevEndTime < endOfDay) {
        freeIntervals.push({ startTime: prevEndTime, endTime: endOfDay });
    }

    const enableIntervals: EnableInterval[] = [];

    freeIntervals
        .filter((interval) => {
            const start = new Date(interval.startTime);
            const end = new Date(interval.endTime);
            const duration = (end.getTime() - start.getTime()) / (1000 * 60);
            return duration >= 15;
        })
        .forEach((interval) => {
            MEETING_ROOMS_TIMES.forEach((time) => {
                const intervalStartTime = dayjs(interval.startTime);
                const intervalEndTime = dayjs(interval.endTime);
                const nextTimeDayJs = dayjs()
                    .set('hour', time + 1)
                    .set('minutes', 0)
                    .set('seconds', 0);
                const timeDayJs = dayjs().set('hour', time).set('minutes', 0).set('seconds', 0);
                const prevTimeDayJs = dayjs()
                    .set('hour', time - 1)
                    .set('minutes', 0)
                    .set('seconds', 0);

                if (time === intervalStartTime.get('hour') && time < intervalEndTime.get('hour')) {
                    enableIntervals.push({
                        startTime: interval.startTime,
                        endTime: new Date(
                            `${nextTimeDayJs.format('YYYY-MM-DD')}T${nextTimeDayJs.format(
                                'HH:mm:ss',
                            )}`,
                        ),
                    });
                }

                if (
                    time > intervalStartTime.get('hour') &&
                    time === intervalEndTime.get('hour') &&
                    intervalEndTime.get('minutes') === 0 &&
                    intervalStartTime.get('minutes') === 0
                ) {
                    enableIntervals.push({
                        startTime: new Date(
                            `${prevTimeDayJs.format('YYYY-MM-DD')}T${prevTimeDayJs.format(
                                'HH:mm:ss',
                            )}`,
                        ),
                        endTime: interval.endTime,
                    });
                }

                if (time > intervalStartTime.get('hour') && time === intervalEndTime.get('hour')) {
                    enableIntervals.push({
                        startTime: new Date(
                            `${timeDayJs.format('YYYY-MM-DD')}T${timeDayJs.format('HH:mm:ss')}`,
                        ),
                        endTime: interval.endTime,
                    });
                }

                if (time > intervalStartTime.get('hour') && time < intervalEndTime.get('hour')) {
                    enableIntervals.push({
                        startTime: new Date(
                            `${timeDayJs.format('YYYY-MM-DD')}T${timeDayJs.format('HH:mm:ss')}`,
                        ),
                        endTime: new Date(
                            `${nextTimeDayJs.format('YYYY-MM-DD')}T${nextTimeDayJs.format(
                                'HH:mm:ss',
                            )}`,
                        ),
                    });
                }

                if (
                    time === intervalStartTime.get('hour') &&
                    time === intervalEndTime.get('hour')
                ) {
                    enableIntervals.push({
                        startTime: interval.startTime,
                        endTime: interval.endTime,
                    });
                }
            });
        });

    return enableIntervals;
};
