import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_STATUS, USER_TEAM } from '../../constants';
import { RootState } from '..';

export interface User {
    id: string;
    name: string;
    surname: string;
    telegramUserName: string;
    telegramUserId: number | null;
    createdAt: string;
    updatedAt: string;
    isAuth: boolean;
    team: USER_TEAM[] | null;
    isAdmin: boolean;
    status: USER_STATUS;
    email: string | null;
}

interface UserState {
    user: User;
}

const initialState: UserState = {
    user: {
        id: '',
        name: '',
        surname: '',
        telegramUserName: '',
        telegramUserId: null,
        createdAt: '',
        updatedAt: '',
        isAuth: false,
        email: '',
        team: null,
        isAdmin: false,
        status: USER_STATUS.PENDING,
    },
};

interface SetUserPayload {
    id: string;
    name: string;
    surname: string;
    telegramUserName: string;
    telegramUserId: number | null;
    team: USER_TEAM[] | null;
    isAdmin: boolean;
    status: USER_STATUS;
}

interface SetUserAuthPayload {
    isAuth: boolean;
    email: string;
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload }: PayloadAction<SetUserPayload>) => {
            state.user.id = payload.id;
            state.user.name = payload.name;
            state.user.surname = payload.surname;
            state.user.telegramUserName = payload.telegramUserName;
            state.user.telegramUserId = payload.telegramUserId;
            state.user.isAdmin = payload.isAdmin;
            state.user.status = payload.status;
            state.user.team = payload.team;
        },
        setUserAuth: (state, { payload: { isAuth, email } }: PayloadAction<SetUserAuthPayload>) => {
            state.user.isAuth = isAuth;
            state.user.email = email;
        },
    },
});

export const {
    reducer: userReducer,
    actions: { setUser, setUserAuth },
} = userSlice;

export const getUserSelector = (state: RootState) => state.userReducer.user;
