import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './interceptor';
import { API_ROUTE, STORE_TAGS } from '../constants';
import {
    CreateMeetingRoomBookingBody,
    MeetingRoomBookingListResponse,
    MeetingRoomBookingsListBody,
} from './types';

export const meetingRoomBookingApi = createApi({
    reducerPath: 'meetingRoomBookingApi',
    baseQuery,
    tagTypes: [STORE_TAGS.ALL_BOOKINGS],
    endpoints: (builder) => ({
        list: builder.query<MeetingRoomBookingListResponse, MeetingRoomBookingsListBody>({
            query: (body) => ({
                url: `${API_ROUTE}/api/v1/meeting-rooms/bookings/list`,
                method: 'POST',
                body,
            }),
            providesTags: [STORE_TAGS.ALL_BOOKINGS],
            keepUnusedDataFor: 0,
        }),
        create: builder.mutation<void, CreateMeetingRoomBookingBody>({
            query: (body) => ({
                url: `${API_ROUTE}/api/v1/meeting-rooms/bookings`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [STORE_TAGS.ALL_BOOKINGS],
        }),
        delete: builder.mutation<void, { ids: string[] }>({
            query: (body) => ({
                url: `${API_ROUTE}/api/v1/meeting-rooms/bookings`,
                method: 'DELETE',
                body,
            }),
            invalidatesTags: [STORE_TAGS.ALL_BOOKINGS],
        }),
    }),
});
