/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const wrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minWidth: '100%',
});

const topManagementWrapperStyles = css(
    mq({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: ['72px', '70px', '80px', '80px'],
    }),
);

const topManagementRoomStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            '& p': {
                textAlign: 'center',
                fontWeight: 600,
                fontSize: ['8px', '8px', '10px', '10px'],
                lineHeight: '12px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            width: ['41.49px', '36.47px', '50px', '50px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            borderRadius: '5px',
        }),
    );
};

const enterStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            '& .enterText': {
                transform: 'rotate(-90deg)',
                fontWeight: 600,
                fontSize: ['8px', '8px', '10px', '10px'],
                lineHeight: '12px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            width: ['16.6px', '21.88px', '30px', '30px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            borderRadius: '5px',
            marginRight: ['4.15px', '3.53px', '5px', '5px'],
        }),
    );
};

const wcStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            minWidth: ['202.98px', '192.94px', '220px', '220px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            fontSize: ['8px', '8px', '10px', '10px'],
            fontWeight: 600,
            lineHeight: '12px',
            marginRight: ['4.15px', '3.53px', '5px', '5px'],
            borderRadius: '5px',
        }),
    );
};

const kitchenLawAccWrapperStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
});

const kitchenStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            width: ['142.98px', '132.94px', '160px', '160px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            fontWeight: 600,
            fontSize: ['8px', '8px', '10px', '10px'],
            lineHeight: '12px',
            borderRadius: '5px',
        }),
    );
};

const lawAccStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css(
        mq({
            '& p': {
                textAlign: 'center',
                fontWeight: 600,
                fontSize: ['8px', '8px', '10px', '10px'],
                lineHeight: '12px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: colorScheme === 'dark' ? '#2D2F34' : '#DCDCDC',
            color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
            width: ['41.49px', '36.47px', '50px', '50px'],
            height: ['41.49px', '36.47px', '50px', '50px'],
            borderRadius: '5px',
            marginTop: ['4.15px', '3.53px', '5px', '5px'],
        }),
    );
};

const styles = {
    wrapperStyles,
    topManagementWrapperStyles,
    topManagementRoomStyles,
    enterStyles,
    wcStyles,
    kitchenLawAccWrapperStyles,
    kitchenStyles,
    lawAccStyles,
};

export default styles;
