/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const enableTimeRow = ({
    height,
    colorScheme,
    top,
    first,
    last,
    allMeetingRooms,
    meetingRoomName,
}: {
    height: string;
    top: string;
    colorScheme: string;
    first: boolean;
    last: boolean;
    allMeetingRooms?: boolean;
    meetingRoomName?: string;
}) => {
    let borderRadius;
    let width = 'calc(100% - 51.1px)';
    let left = '45.3px';

    if (allMeetingRooms && meetingRoomName === 'MR1') {
        left = '45.3px';
    } else if (allMeetingRooms && meetingRoomName === 'MR2') {
        left = 'calc(100% / 3 - 51.1px / 3 + 45.3px)';
    } else if (allMeetingRooms && meetingRoomName === 'MR3') {
        left = 'calc((100% / 3 - 51.1px / 3) * 2 + 45.3px)';
    }

    if (allMeetingRooms) {
        width = 'calc(100% / 3 - 51.1px / 3)';
    }

    const color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';

    if (first && meetingRoomName === 'MR3' && allMeetingRooms) {
        borderRadius = '0px 5px 0px 0px';
    }
    if (last && meetingRoomName === 'MR3' && allMeetingRooms) {
        borderRadius = '0px 0px 5px 0px';
    }

    if (first && !allMeetingRooms) {
        borderRadius = '0px 5px 0px 0px';
    }

    if (last && !allMeetingRooms) {
        borderRadius = '0px 0px 5px 0px';
    }

    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                background: '#3366FF',
                color,
                borderWidth: '0.5px 0.5px 0.5px 0.5px',
                cursor: 'pointer',
                transition: 'all 0.3s ease-out',
            },
        },
        position: 'absolute',
        top,
        left,
        width,
        margin: '0px 5px',
        height,
        borderRadius,
        zIndex: 1,
        transition: 'all 0.3s ease-out',
    });
};

const styles = {
    enableTimeRow,
};

export default styles;
