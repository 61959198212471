import {
    Table as MUITable,
    Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import React from 'react';

interface TableHeader {
    text: string;
    dataKey: string;
}

interface TableProps {
    count?: number;
    currentPage?: number;
    pageSize?: number;
    onPageChange?: (page: number) => void;
    onRowClick?: (row: Record<string, string | null>) => void;
    headers: TableHeader[];
    rows: Record<string, string | null>[];
}

export function Table({
    count,
    currentPage,
    pageSize,
    onPageChange,
    headers,
    rows,
    onRowClick,
}: TableProps) {
    const enablePagination = count && pageSize && currentPage !== undefined;

    const handlePageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        onPageChange?.(page);
    };

    return (
        <TableContainer sx={{ maxHeight: '100%' }}>
            <MUITable>
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell variant="head" key={header.dataKey} sx={{ fontWeight: 600 }}>
                                {header.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length ? (
                        rows.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    sx={{ cursor: 'pointer' }}
                                    key={JSON.stringify(row)}
                                    onClick={() => {
                                        onRowClick?.(row);
                                    }}
                                >
                                    {headers.map((header) => (
                                        <TableCell
                                            key={`${JSON.stringify(row)}-${header.dataKey}`}
                                            variant="body"
                                        >
                                            {row[header.dataKey]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Stack justifyContent="center" alignItems="center" py={3}>
                                    No results
                                </Stack>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </MUITable>
            {enablePagination ? (
                <TablePagination
                    component="div"
                    sx={{ py: 1 }}
                    page={currentPage}
                    count={count}
                    onPageChange={handlePageChange}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[]}
                />
            ) : null}
        </TableContainer>
    );
}
