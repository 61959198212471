/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import { Grid, Tooltip } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import BackArrowIcon from '../../../../assets/backArrowIcon';
import BackDateIcon from '../../../../assets/backDateIcon';
import NextDateIcon from '../../../../assets/nextDateIcon';
import { APP_ROUTES, MAX_TODAY_TIME } from '../../../../constants';
import { useEnhancedNavigate } from '../../../../hooks/useEnhancedNavigate';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';
import { useSetStorageValue } from '../../../../hooks/useSetStorageValue';
import { useTelegram } from '../../../../hooks/useTelegram';
import { setEditedUserSpace, setOfficeSpace } from '../../../../store/reducers/officeSpace';
import Calendar from '../../../common/calendar';

import styles from './styles';

interface MapHeaderProps {
    startDateState?: string;
    setStartDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
    endDateState?: string;
    isMeetingRoom: boolean;
    meetingRoomDateState?: string;
    setMeetingRoomDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
    editWorkPlaceActive: boolean;
    periodActive: boolean;
    editSpaceId?: string;
    setEditWorkPlaceActive: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditClosed: React.Dispatch<React.SetStateAction<boolean>>;
    isMyBookings: boolean;
}

function MapHeader({
    startDateState,
    setStartDateState,
    endDateState,
    isMeetingRoom,
    meetingRoomDateState,
    setMeetingRoomDateState,
    editWorkPlaceActive,
    periodActive,
    editSpaceId,
    setIsEditClosed,
    setEditWorkPlaceActive,
    isMyBookings,
}: MapHeaderProps) {
    const [calendarActive, setCalendarActive] = useState(false);
    const [calendarValue, setCalendarValue] = useState<Dayjs | null | undefined>();

    const [queryParams, setQueryParams] = useSearchParams();

    const setBookingDateStorageValue = useSetStorageValue('bookingDate');

    const { scrollNavigate } = useEnhancedNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        if (startDateState) {
            setCalendarValue(dayjs(startDateState));
        }
        if (isMeetingRoom && meetingRoomDateState) {
            setCalendarValue(dayjs(meetingRoomDateState));
        }
    }, [startDateState, meetingRoomDateState]);

    const { tg, colorScheme } = useTelegram();

    const goNextDateHandler = () => {
        if (isMeetingRoom) {
            const nextDay = dayjs(meetingRoomDateState).add(1, 'day').format('YYYY-MM-DD');
            setMeetingRoomDateState(nextDay);
        } else {
            const nextDay = dayjs(startDateState).add(1, 'day').format('YYYY-MM-DD');
            setStartDateState(nextDay);
            setIsEditClosed(false);
            dispatch(setOfficeSpace({ newSpace: '' }));
            dispatch(setEditedUserSpace({ newEditedUserSpace: null }));
            queryParams.set('startDate', nextDay);
            queryParams.delete('spaceId');
            queryParams.delete('spaceName');
            queryParams.delete('isClosed');
            queryParams.delete('endDate');
            setQueryParams(queryParams);
        }
    };

    const goPrevDateHandler = () => {
        if (isMeetingRoom) {
            const prevDay = dayjs(meetingRoomDateState).subtract(1, 'day').format('YYYY-MM-DD');
            setMeetingRoomDateState(prevDay);
        } else {
            const prevDay = dayjs(startDateState).subtract(1, 'day').format('YYYY-MM-DD');
            setStartDateState(prevDay);
            setIsEditClosed(false);
            dispatch(setOfficeSpace({ newSpace: '' }));
            dispatch(setEditedUserSpace({ newEditedUserSpace: null }));
            queryParams.set('startDate', prevDay);
            queryParams.delete('spaceId');
            queryParams.delete('spaceName');
            queryParams.delete('isClosed');
            queryParams.delete('endDate');
            setQueryParams(queryParams);
        }
    };

    const startDateString = dayjs(
        `${isMeetingRoom ? meetingRoomDateState : startDateState}`,
    ).format('DD.MM.YYYY');

    const onDateClickHandler = () => {
        setCalendarActive((prev) => !prev);
    };

    const today = dayjs();

    const todayHours = today.get('hour');

    if (todayHours > MAX_TODAY_TIME) {
        today.add(1, 'day');
    }

    const showBackBtn =
        dayjs(`${isMeetingRoom ? meetingRoomDateState : startDateState}`)
            .set('hours', 0)
            .set('minutes', 0)
            .set('milliseconds', 0)
            .isAfter(today.set('hours', 0).set('minutes', 0).set('milliseconds', 0)) &&
        !editWorkPlaceActive;

    const showNextBtn =
        dayjs(`${isMeetingRoom ? meetingRoomDateState : startDateState}`)
            .set('hours', 0)
            .set('minutes', 0)
            .set('milliseconds', 0)
            .isAfter(
                today.subtract(1, 'day').set('hours', 0).set('minutes', 0).set('milliseconds', 0),
            ) && !editWorkPlaceActive;

    const chosenStartDateDayJs = dayjs(startDateState);

    const chosenEndDateDayJs = dayjs(endDateState);

    const chosenStartDateDay = chosenStartDateDayJs.format('dddd');

    const chosenStartDateString = chosenStartDateDayJs.format('DD MMM');

    const chosenEndDateDay = chosenEndDateDayJs.format('dddd');

    const chosenEndDateString = chosenEndDateDayJs.format('DD MMM');

    const goBackHandler = () => {
        tg.MainButton.hide();
        dispatch(setOfficeSpace({ newSpace: '' }));

        scrollNavigate({
            top: 0,
            left: 0,
            path: `${
                APP_ROUTES.BOOK_PERIOD_PAGE
            }?startDateState=${startDateState}&endDateState=${endDateState}${
                isMyBookings ? `&isMyBookings=${isMyBookings}` : ``
            }`,
            replace: true,
            behavior: 'smooth',
        });
    };

    const goBackToMyBookings = () => {
        dispatch(setOfficeSpace({ newSpace: '' }));
        dispatch(setEditedUserSpace({ newEditedUserSpace: null }));
        scrollNavigate({
            top: 0,
            left: 0,
            path: `${APP_ROUTES.MY_BOOKINGS_ROUTE}`,
            replace: true,
            behavior: 'smooth',
        });
    };

    const calendarRef = useRef<HTMLDivElement>(null);

    const onClickOutsideCalendarHandler = (e: Event) => {
        setCalendarActive(false);
    };

    const handleCalendarValueChange = (value: Dayjs | null) => {
        setCalendarValue(value);

        if (value) {
            const newValueString = value.format('YYYY-MM-DD');

            if (isMeetingRoom) {
                setMeetingRoomDateState(newValueString);
            } else {
                setStartDateState(newValueString);
            }

            setBookingDateStorageValue(newValueString);

            setIsEditClosed(false);

            dispatch(setEditedUserSpace({ newEditedUserSpace: null }));
            queryParams.set('startDate', newValueString);
            queryParams.delete('endDate');
            queryParams.delete('isClosed');
            queryParams.delete('spaceId');
            queryParams.delete('spaceName');
            setQueryParams(queryParams);
            dispatch(setOfficeSpace({ newSpace: '' }));
        }
    };

    const handleCloseCalendar = () => {
        setCalendarActive(false);
    };

    useOnClickOutside(calendarRef, onClickOutsideCalendarHandler);

    return (
        <div>
            {isMyBookings && (
                <Tooltip
                    title="Back to my bookings"
                    placement="top"
                    componentsProps={{
                        tooltip: {
                            sx: {
                                backgroundColor: colorScheme === 'dark' ? '#383B40' : '#ffffff',
                                color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                fontWeight: 400,
                                fontSize: '8px',
                                bottom: '10px',
                                left: '40px',
                                position: 'relative',
                            },
                        },
                        popper: {
                            sx: {
                                height: '10px',
                            },
                        },
                    }}
                >
                    <button
                        type="button"
                        css={styles.goBackBtnStyles({ colorScheme, isMyBookings })}
                        onClick={goBackToMyBookings}
                    >
                        <BackArrowIcon />
                    </button>
                </Tooltip>
            )}
            {endDateState ? (
                <div css={styles.chosenDateWrapperStyles({ colorScheme })}>
                    {(periodActive || editWorkPlaceActive) && (
                        <Tooltip
                            title="Back to the calendar"
                            placement="top"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        backgroundColor:
                                            colorScheme === 'dark' ? '#383B40' : '#ffffff',
                                        color: colorScheme === 'dark' ? '#ffffff' : '#000000',
                                        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                        fontWeight: 400,
                                        fontSize: '8px',
                                        bottom: '10px',
                                        left: '40px',
                                        position: 'relative',
                                    },
                                },
                                popper: {
                                    sx: {
                                        height: '10px',
                                    },
                                },
                            }}
                        >
                            <button
                                type="button"
                                css={styles.goBackBtnStyles({ colorScheme })}
                                onClick={goBackHandler}
                            >
                                <BackArrowIcon />
                            </button>
                        </Tooltip>
                    )}

                    <div css={styles.periodInfoWrapperStyles}>
                        <p css={styles.weekDayTextStyles({ colorScheme })}>
                            {chosenStartDateDay}-{chosenEndDateDay}
                        </p>
                        <p css={styles.dateTextStyles({ colorScheme })}>
                            {chosenStartDateString}-{chosenEndDateString}
                        </p>
                    </div>
                </div>
            ) : (
                <Grid container position="relative" justifyContent="center" mb="20px">
                    <Grid item xs={12}>
                        <div css={styles.headerWrapperStyles({ colorScheme })}>
                            {showBackBtn && (
                                <button
                                    type="button"
                                    css={styles.backDateBtnStyles({ colorScheme })}
                                    onClick={goPrevDateHandler}
                                >
                                    <BackDateIcon />
                                </button>
                            )}
                            <div css={styles.headerInfoWrapperStyles} ref={calendarRef}>
                                {editWorkPlaceActive ? (
                                    <p css={styles.chosenDateStyles}>{startDateString}</p>
                                ) : (
                                    <p onClick={onDateClickHandler} css={styles.chosenDateStyles}>
                                        {startDateString}
                                    </p>
                                )}
                                <div css={styles.spaceTypeStyles}>
                                    {isMeetingRoom ? 'Book a meeting room' : 'Book a desk'}
                                </div>
                                {calendarActive && !editWorkPlaceActive && (
                                    <Calendar
                                        onChange={handleCalendarValueChange}
                                        onClose={handleCloseCalendar}
                                        value={calendarValue}
                                    />
                                )}
                            </div>
                            {showNextBtn && (
                                <button
                                    type="button"
                                    css={styles.nextDateBtnStyles({ colorScheme })}
                                    onClick={goNextDateHandler}
                                >
                                    <NextDateIcon />
                                </button>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default MapHeader;
