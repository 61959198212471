import { useNavigate } from 'react-router-dom';

type BehaviorType = 'auto' | 'smooth';

interface ScrollNavigateProps {
    top: number;
    left: number;
    path?: string;
    behavior?: BehaviorType;
    replace?: boolean;
    queryParams?: Record<string, string>;
    prevPagePathname?: string;
}

const RETURN = -1;

export function useEnhancedNavigate() {
    const navigate = useNavigate();
    const scrollNavigate = ({
        top,
        left,
        path,
        replace = false,
        behavior = 'smooth',
        queryParams,
        prevPagePathname,
    }: ScrollNavigateProps) => {
        window.scrollTo({ left, top, behavior });

        if (path) {
            let fullPath = path;

            if (queryParams) {
                const keys = Object.keys(queryParams);
                const queryString = `?${keys.map((key) => `${key}=${queryParams[key]}`).join('&')}`;
                fullPath = path + queryString;
            }

            navigate(fullPath, { replace, state: { prevPagePathname } });
        }
    };
    const navigateBack = () => {
        navigate(RETURN);
    };
    return { scrollNavigate, navigateBack };
}
