/** @jsxImportSource @emotion/react */
import React from 'react';
import dayjs from 'dayjs';
import { Divider, Tab, Tabs } from '@mui/material';
import { useTelegram } from '../../../../hooks/useTelegram';
import { TAB_VALUES } from '../../../../constants';
import { MIN_LIMIT, SelectedItem } from '..';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface MyBookingsTabsProps {
    setTabValue: React.Dispatch<React.SetStateAction<TAB_VALUES>>;
    tabValue: TAB_VALUES;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    setIsCheckedMyBookings: React.Dispatch<React.SetStateAction<boolean>>;
    setIsCheckedHistoryBookings: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedItems: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
    setExpiredSelectedItems: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
    setExpandedMyBookings: React.Dispatch<React.SetStateAction<boolean>>;
    setExpandedHistoryBookings: React.Dispatch<React.SetStateAction<boolean>>;
    setRemoveModeActive: React.Dispatch<React.SetStateAction<boolean>>;
    setExpiredRemoveModeActive: React.Dispatch<React.SetStateAction<boolean>>;
}

function MyBookingsTabs({
    tabValue,
    setTabValue,
    setLimit,
    setIsCheckedMyBookings,
    setIsCheckedHistoryBookings,
    setSelectedItems,
    setExpiredSelectedItems,
    setExpandedMyBookings,
    setExpandedHistoryBookings,
    setRemoveModeActive,
    setExpiredRemoveModeActive,
}: MyBookingsTabsProps) {
    const { colorScheme } = useTelegram();

    const handleChange = (event: React.SyntheticEvent, newValue: TAB_VALUES) => {
        setTabValue(newValue);
        setLimit(MIN_LIMIT);
        setIsCheckedMyBookings(false);
        setIsCheckedHistoryBookings(false);
        setSelectedItems([]);
        setExpiredSelectedItems([]);
        setExpandedMyBookings(true);
        setExpandedHistoryBookings(true);
        setRemoveModeActive(false);
        setExpiredRemoveModeActive(false);
    };
    return (
        <Tabs
            css={styles.tabsWrapperStyles({ colorScheme })}
            value={tabValue}
            onChange={handleChange}
            aria-label="lab API tabs example"
        >
            <Tab
                css={styles.tabStyles({ colorScheme, tabValue: TAB_VALUES.ALL })}
                label="All"
                value="All"
            />
            {tabValue !== TAB_VALUES.ALL && tabValue !== TAB_VALUES.MEETING_ROOM && (
                <Divider
                    css={styles.dividerStyles({ colorScheme, position: 'left' })}
                    orientation="vertical"
                />
            )}
            <Tab
                css={styles.tabStyles({ colorScheme, tabValue: TAB_VALUES.MEETING_ROOM })}
                label="Meeting room"
                value="Meeting room"
            />
            {tabValue !== TAB_VALUES.DESK && tabValue !== TAB_VALUES.MEETING_ROOM && (
                <Divider
                    css={styles.dividerStyles({ colorScheme, position: 'right' })}
                    orientation="vertical"
                />
            )}
            <Tab
                css={styles.tabStyles({ colorScheme, tabValue: TAB_VALUES.DESK })}
                label="Desk"
                value="Desk"
            />
        </Tabs>
    );
}

export default MyBookingsTabs;
