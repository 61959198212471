import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './interceptor';
import { API_ROUTE, STORE_TAGS } from '../constants';
import { OfficeSpaceBookingListBody, OfficeSpaceBookingListResponse } from './types';

export const officeSpaceBookingApi = createApi({
    reducerPath: 'officeSpaceBookingApi',
    baseQuery,
    tagTypes: [STORE_TAGS.ALL_BOOKINGS],
    endpoints: (builder) => ({
        list: builder.query<OfficeSpaceBookingListResponse, OfficeSpaceBookingListBody>({
            query: (body) => ({
                url: `${API_ROUTE}/api/v1/office-spaces/bookings/list`,
                method: 'POST',
                body,
            }),
            providesTags: [STORE_TAGS.ALL_BOOKINGS],
            keepUnusedDataFor: 0,
        }),
        create: builder.mutation<
            void,
            {
                queryId: string;
                spaceName: string;
                userId: string;
                startDate: string;
                endDate?: string | null;
                permanent?: boolean;
            }
        >({
            query: (body) => ({
                url: `${API_ROUTE}/api/v1/office-spaces/bookings`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [STORE_TAGS.ALL_BOOKINGS],
        }),
        delete: builder.mutation<void, { ids: string[] }>({
            query: (body) => ({
                url: `${API_ROUTE}/api/v1/office-spaces/bookings`,
                method: 'DELETE',
                body,
            }),
            invalidatesTags: [STORE_TAGS.ALL_BOOKINGS],
        }),
    }),
});
