/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TAB_VALUES } from '../../../../constants';

const tabsWrapperStyles = ({ colorScheme }: { colorScheme: string }) => {
    return css({
        '& .MuiTabs-flexContainer': {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        '& .Mui-selected': {
            background: '#004ED7',
            color: '#FFFFFF !important',
            zIndex: 2,
        },
        '& .MuiTabs-indicator': {
            display: 'none',
        },
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '50px',
        marginBottom: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    });
};

const dividerStyles = ({ colorScheme, position }: { colorScheme: string; position: string }) => {
    let left;

    if (position === 'left') {
        left = 'calc(100% / 3 - 0.5px)';
    } else {
        left = 'calc(100% / 3 * 2 - 0.5px)';
    }
    return css({
        position: 'absolute',
        left,
        zIndex: 1,
        width: '1px',
        height: '30px',
        top: '10px',
        background: '#C4C4C4',
    });
};

const tabStyles = ({ colorScheme, tabValue }: { colorScheme: string; tabValue: TAB_VALUES }) => {
    let borderRadius;
    let borderTopRightRadius;
    let borderBottomRightRadius;
    let borderTopLeftRadius;
    let borderBottomLeftRadius;

    if (tabValue === TAB_VALUES.ALL) {
        borderRadius = '5px';
        borderTopRightRadius = '0px';
        borderBottomRightRadius = '0px';
    }

    if (tabValue === TAB_VALUES.DESK) {
        borderRadius = '5px';
        borderTopLeftRadius = '0px';
        borderBottomLeftRadius = '0px';
    }
    return css({
        width: 'calc(100% / 3)',
        textTransform: 'none',
        background: colorScheme === 'dark' ? '#2D2F34' : '#E8E8E8',
        borderRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        height: '50px',
        color: colorScheme === 'dark' ? '#FFFFFF' : '#000000',
        fontSize: '10px',
        fontWeight: 600,
        lineHeight: '12px',
    });
};

const styles = {
    tabsWrapperStyles,
    dividerStyles,
    tabStyles,
};

export default styles;
