/** @jsxImportSource @emotion/react */
import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { TIMES } from '../../../../constants';
import { isTimeDisabled } from '../../../../utils/isTimeDisabled';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useStyles } from '../../../../hooks/useStyles';
import { useAppSelector } from '../../../../hooks/useAppSelector';

import styles from './styles';
import { MeetingRoomUser } from '../../../../store/reducers/meetingRooms';

interface EndTimePickerProps {
    setEndTimeValue: React.Dispatch<React.SetStateAction<Dayjs | null | undefined>>;
    endTimeValue: Dayjs | null | undefined;
    startTimeValue: Dayjs | null | undefined;
    chosenDateState?: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    endTimeError: string;
    editModeActive: boolean;
    checked: boolean;
    setChecked: React.Dispatch<React.SetStateAction<boolean>>;
    meetingRoomUsers: MeetingRoomUser[];
    disabled?: boolean;
}

function EndTimePicker({
    endTimeValue,
    setEndTimeValue,
    startTimeValue,
    chosenDateState,
    setError,
    endTimeError,
    editModeActive,
    checked,
    setChecked,
    meetingRoomUsers,
    disabled,
}: EndTimePickerProps) {
    const { colorScheme, tgUserId } = useTelegram();

    const classes = useStyles({ colorScheme });

    const editedMeetingRoomBooking = useAppSelector(
        (store) => store.meetingRoomsReducer.editedUserMeetingRoom,
    );

    const allMeetingRoomUsers = useAppSelector(
        (store) => store.meetingRoomsReducer.allMeetingRoomUsers,
    ).filter(
        (m) =>
            m.telegramUserId === tgUserId &&
            m.userMeetingRoomInfo.id !== editedMeetingRoomBooking?.userMeetingRoomInfo.id,
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            const nowDay = dayjs().format('YYYY-MM-DD');

            const newEndTime = dayjs(`${nowDay} ${e.target.value}:00`);

            setEndTimeValue(newEndTime);
            setChecked(false);
        }
    };

    const key = 'startTime';

    const uniqueAllBookings = [
        ...new Map(
            allMeetingRoomUsers.map((item) => [item.userMeetingRoomInfo[key], item]),
        ).values(),
    ];

    const uniqueMeetingRoomBookings = [
        ...new Map(meetingRoomUsers.map((item) => [item.userMeetingRoomInfo[key], item])).values(),
    ];

    return (
        <div
            css={styles.timePickerWrapperStyles({
                colorScheme,
                isEdited: !!disabled,
            })}
        >
            <TextField
                className={classes.root}
                select
                label="End Time"
                value={endTimeValue?.format('HH:mm')}
                variant="outlined"
                disabled={disabled}
                onChange={handleChange}
                SelectProps={{
                    MenuProps: {
                        PopoverClasses: classes,
                        autoFocus: false,
                    },
                }}
                InputLabelProps={{
                    className: 'inputLabel',
                    shrink: true,
                }}
            >
                {TIMES.map((t) => {
                    const isDisabled = isTimeDisabled(
                        t,
                        uniqueMeetingRoomBookings,
                        true,
                        startTimeValue,
                    );
                    const isDisabledInAllMeetingRoomUsers = isTimeDisabled(
                        t,
                        uniqueAllBookings,
                        true,
                        startTimeValue,
                    );
                    return (
                        <MenuItem
                            autoFocus={t === endTimeValue?.format('HH:mm')}
                            value={t}
                            disabled={isDisabled || isDisabledInAllMeetingRoomUsers}
                            key={t}
                            css={styles.menuItemStyles({ colorScheme })}
                        >
                            {t}
                        </MenuItem>
                    );
                })}
            </TextField>
            {endTimeError && <p css={styles.errorTextStyles}>{endTimeError}</p>}
        </div>
    );
}

export default EndTimePicker;
