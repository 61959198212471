/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const spaceStyles = ({
    isUserBooked,
    isBooked,
    isMyBook,
    isMeetingRoom,
    editWorkPlaceActive,
    colorScheme,
    isChosenSpace,
    isBlocked,
}: {
    isUserBooked: boolean;
    isBooked: boolean;
    isMyBook: boolean;
    isMeetingRoom: boolean;
    editWorkPlaceActive: boolean;
    colorScheme: string;
    isChosenSpace: boolean;
    isBlocked?: boolean;
}) => {
    let bgColor;
    let justifyContent;
    let fontSize;
    let color;
    let cursor;
    let border;

    if (isUserBooked || isMeetingRoom) {
        bgColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
        justifyContent = 'center';
        fontSize = ['8px', '8px', '10px', '10px'];
        color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
        cursor = 'default';
    } else {
        bgColor = 'inherit';
        justifyContent = 'center';
        fontSize = ['8px', '8px', '10px', '10px'];
        color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
        cursor = 'pointer';
    }

    if (editWorkPlaceActive) {
        bgColor = 'inherit';
        justifyContent = 'center';
        fontSize = ['8px', '8px', '10px', '10px'];
        color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
        cursor = 'pointer';
    }

    if (isBooked) {
        bgColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
        justifyContent = 'flex-end';
        fontSize = ['5px', '5px', '6.5px', '6.5px'];
        color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
        cursor = 'default';
    }

    if (isMyBook) {
        bgColor = '#3366FF';
        justifyContent = 'flex-end';
        fontSize = ['5px', '5px', '6.5px', '6.5px'];
        color = 'white';
        cursor = 'pointer';
    }

    if (isBlocked) {
        bgColor = colorScheme === 'dark' ? '#383B40' : '#C4C4C4';
        color = colorScheme === 'dark' ? '#FFFFFF' : '#000000';
        cursor = 'default';
    }

    if (colorScheme === 'dark') {
        border = '1px solid #383B40';
    } else {
        border = '1px solid #C4C4C4';
    }

    if (isChosenSpace) {
        border = '1px solid #3366FF';
    }

    return css(
        mq({
            '& .spaceName ': {
                fontSize,
                fontWeight: 600,
                lineHeight: '12px',
            },
            '& .userName': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                padding: '2px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
                minHeight: '20px',
                height: ['29.89px', '26.88px', '35px', '35px'],
                width: ['56.49px', '41.47px', '55px', '55px'],
            },
            '& .userName > p': {
                fontSize,
                fontWeight: 600,
                wordWrap: 'break-word',
                wordBreak: 'break-all',
                textAlign: 'center',
            },
            '& .userSurname': {
                position: 'absolute',
                left: ['-30%', '-35%', '-35%', '-35%'],
                bottom: ['20px', '14px', '20px', '20px'],
                fontSize,
                fontWeight: 600,
                lineHeight: ['6px', '6px', '7px', '7px'],
                transform: 'rotate(-90deg)',
                wordBreak: 'break-word',
                width: ['56.49px', '41.47px', '55px', '55px'],
                height: '25%',
                textAlign: 'center',
                padding: ['5px', '3px', '5px', '5px'],
                paddingTop: '0px',
            },
            '@media (hover: hover) and (pointer: fine)': {
                '&:hover': {
                    border:
                        (!isUserBooked && !isBooked && !isMyBook && !isMeetingRoom && !isBlocked) ||
                        (editWorkPlaceActive && !isBooked && !isBlocked)
                            ? [
                                  '1px solid inherit',
                                  '1px solid #3366FF',
                                  '1px solid #3366FF',
                                  '1px solid #3366FF',
                              ]
                            : [
                                  '1px solid inherit',
                                  '1px solid inherit',
                                  '1px solid inherit',
                                  '1px solid inherit',
                              ],
                },
            },
            position: 'relative',
            color,
            width: ['29.89px', '26.88px', '35px', '35px'],
            height: ['56.49px', '41.47px', '55px', '55px'],
            boxSizing: 'border-box',
            background: bgColor,
            cursor,
            display: 'flex',
            flexDirection: 'column',
            justifyContent,
            alignItems: 'center',
            borderRadius: '5px',
            border,
            marginBottom: ['4.15px', '3.45px', '5px', '5px'],
        }),
    );
};

const bookedTextStyles = css({
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '2px',
    wordBreak: 'break-all',
    width: '100%',
});

const styles = {
    spaceStyles,
    bookedTextStyles,
};

export default styles;
