/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { AccordionDetails, Divider } from '@mui/material';
import RepeatIcon from '../../../../../assets/repeatIcon';
import EditIcon from '../../../../../assets/editIcon';
import RemoveIcon from '../../../../../assets/removeIcon';
import { useTelegram } from '../../../../../hooks/useTelegram';
import Checkbox from '../../../../common/checkbox';
import { useEnhancedNavigate } from '../../../../../hooks/useEnhancedNavigate';
import { setEditedUserSpace } from '../../../../../store/reducers/officeSpace';
import { APP_ROUTES, REPEAT_VALUE } from '../../../../../constants';
import { setEditedUserMeetingRoom } from '../../../../../store/reducers/meetingRooms';
import {
    useRemoveMeetingRoomBookingMutation,
    useRemoveOfficeSpaceMutation,
} from '../../../../../api/rootApi';
import { SelectedItem } from '../..';
import { MyBooking } from '../../../../../store/reducers/allBookings';

import styles from './styles';

interface BookingsAccordionDetailsProps {
    item: any;
    isChecked: boolean;
    selectedItems: SelectedItem[];
    setSelectedItems?: React.Dispatch<React.SetStateAction<SelectedItem[]>>;
    setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    removeModeActive: boolean;
    otherRemoveActive: boolean;
    setRemoveRepeatBookingActive: React.Dispatch<React.SetStateAction<boolean>>;
    setRemoveRepeatBooking: React.Dispatch<React.SetStateAction<MyBooking | null>>;
}

function BookingsAccordionDetails({
    isChecked,
    item,
    setSelectedItems,
    setIsChecked,
    removeModeActive,
    otherRemoveActive,
    setRemoveRepeatBookingActive,
    setRemoveRepeatBooking,
    selectedItems,
}: BookingsAccordionDetailsProps) {
    const [isItemChecked, setIsItemChecked] = useState(false);

    const dispatch = useDispatch();

    const { scrollNavigate } = useEnhancedNavigate();

    const [removeOfficeSpaceBooking] = useRemoveOfficeSpaceMutation();

    const [removeMeetingRoomBooking] = useRemoveMeetingRoomBookingMutation();

    useEffect(() => {
        if (isChecked) {
            setIsItemChecked(isChecked);
        }
        const currentItem = selectedItems.find(
            (i) => i.id === item.id && (i.date === item.date || i.date === item.startDate),
        );
        if (currentItem) {
            setIsItemChecked(true);
        }
    }, [isChecked]);

    const { colorScheme, tgUserId } = useTelegram();

    const itemCheckBoxHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setIsItemChecked((prev) => !prev);
        if (e.currentTarget.checked && setSelectedItems) {
            const newItem = {
                id: item.id,
                date: item.isMeetingRoom ? item.date : item.startDate,
            };
            setSelectedItems((prev) => [...prev, newItem]);
        } else if (setSelectedItems) {
            setSelectedItems((prev) =>
                prev.filter((i) => {
                    if (item.isMeetingRoom && (i.id !== item.id || i.date !== item.date)) {
                        return true;
                    }
                    if (!item.isMeetingRoom && (i.id !== item.id || i.date !== item.startDate)) {
                        return true;
                    }
                    return false;
                }),
            );
            setIsChecked(false);
        }
    };

    const starDate = item.isMeetingRoom
        ? `${dayjs(item.date).format('DD MMM')}`
        : `${dayjs(item.startDate).format('DD MMM')}`;

    const endDate = item.endDate ? `${dayjs(item.endDate).format('DD MMM')}` : '';

    const spaceName = item.isMeetingRoom ? `Meeting room ${item.spaceName.split('')[2]}` : `Desk`;

    const bookInfo = item.isMeetingRoom
        ? `${dayjs(`${item.date} ${item.startTime}`).format('HH:mm a')} - ${dayjs(
              `${item.date} ${item.endTime}`,
          ).format('HH:mm a')}`
        : `${item.spaceName}`;

    let repeatValue = '';

    if (item.repeatValue === REPEAT_VALUE.DAY) {
        repeatValue = 'Daily';
    }

    if (item.repeatValue === REPEAT_VALUE.WEEK) {
        repeatValue = 'Weekly';
    }

    if (item.repeatValue === REPEAT_VALUE.MONTH) {
        repeatValue = 'Monthly';
    }

    const onEditHandler = () => {
        if (item.isMeetingRoom && tgUserId) {
            dispatch(
                setEditedUserMeetingRoom({
                    newEditedUserMeetingRoom: {
                        id: item.id,
                        telegramUserId: tgUserId,
                        userMeetingRoomInfo: {
                            id: item.id,
                            roomId: item.spaceId,
                            spaceName: item.spaceName,
                            date: item.date,
                            startTime: item.startTime,
                            endTime: item.endTime,
                            isRepeated: item.isRepeated,
                            repeatValue: item.repeatValue,
                            repeatEndDate: item.repeatEndDate,
                            isRepeatAlways: item.isRepeatAlways,
                        },
                    },
                }),
            );
            scrollNavigate({
                top: 0,
                left: 0,
                path: `${APP_ROUTES.BOOK_MEETING_ROOM}?bookingStartTime=${
                    item.startTime
                }&bookingEndTime=${item.endTime}&meetingRoomId=${item.spaceId}&bookingDate=${
                    item.date
                }&meetingRoomName=Meeting room ${
                    item.spaceName.split('')[2]
                }&isMyBookings=${true}&editedMeetingRoomBookingId=${item.id}`,
                replace: true,
                behavior: 'smooth',
            });
        } else {
            dispatch(
                setEditedUserSpace({
                    newEditedUserSpace: {
                        id: item.id,
                        name: item.spaceName,
                        startDate: item.startDate,
                        endDate: item.endDate,
                        isRepeatAlways: item.isRepeatAlways,
                    },
                }),
            );

            scrollNavigate({
                top: 0,
                left: 0,
                path: `${APP_ROUTES.HOME_PAGE}?startDate=${item.startDate}${
                    item.endDate ? `&endDate=${item.endDate}` : ''
                }&isMyBookings=${true}`,
                replace: true,
                behavior: 'smooth',
            });
        }
    };

    const removeItemHandler = () => {
        if (item.isMeetingRoom && item.isRepeated && item.isExpired === false) {
            setRemoveRepeatBookingActive(true);
            setRemoveRepeatBooking(item);
        } else if (item.isMeetingRoom) {
            removeMeetingRoomBooking({ id: item.id });
        } else {
            removeOfficeSpaceBooking({ userSpaceId: item.id });
        }
    };

    return (
        <AccordionDetails css={styles.accordionDetailsStyles} key={item.id}>
            <div css={styles.bookingWrapperStyles({ colorScheme })}>
                <div css={styles.bookingDateWrapperStyles}>
                    {item.isMeetingRoom || (!item.isMeetingRoom && !item.isRepeatAlways) ? (
                        <>
                            <p css={styles.dateTextStyles({ colorScheme })}>{starDate}</p>
                            <p css={styles.dateTextStyles({ colorScheme })}>{endDate}</p>
                        </>
                    ) : (
                        <p css={styles.dateTextStyles({ colorScheme })}>perm</p>
                    )}
                </div>
                <Divider
                    css={styles.dividerStyles({ colorScheme, isDetails: true })}
                    orientation="vertical"
                />
                <div css={styles.bookingInfoWrapperStyles}>
                    <div>
                        <p css={styles.bookingInfoTextStyles({ colorScheme, marginBottom: '5px' })}>
                            {spaceName}
                            {item.isRepeated && (
                                <>
                                    <RepeatIcon
                                        color={colorScheme === 'dark' ? '#FFFFFF' : '#000000'}
                                        repeatIconStyles={styles.repeatIconsStyles({ colorScheme })}
                                    />
                                    {repeatValue}
                                </>
                            )}
                        </p>
                        <p css={styles.bookingInfoTextStyles({ colorScheme })}>{bookInfo}</p>
                    </div>
                    {!item.isExpired && !removeModeActive && !otherRemoveActive && (
                        <button css={styles.editBtnStyles} type="button" onClick={onEditHandler}>
                            <EditIcon editIconStyles={styles.editIconStyles} />
                        </button>
                    )}
                </div>
                {!item.isExpired && !removeModeActive && !otherRemoveActive && (
                    <Divider
                        css={styles.dividerStyles({ colorScheme, isDetails: true })}
                        orientation="vertical"
                    />
                )}
                {removeModeActive && (
                    <div>
                        <Checkbox
                            isChecked={isItemChecked}
                            onChangeHandler={itemCheckBoxHandler}
                            wrapperStyles={styles.checkboxWrapperStyles}
                        />
                    </div>
                )}
                {!removeModeActive && !otherRemoveActive && (
                    <div css={styles.removeIconWrapperStyles}>
                        <button
                            type="button"
                            onClick={removeItemHandler}
                            css={styles.removeBtnStyles}
                        >
                            <RemoveIcon removeIconStyles={styles.removeIconStyles} />
                        </button>
                    </div>
                )}
            </div>
        </AccordionDetails>
    );
}

export default BookingsAccordionDetails;
