import { FormControl, Stack, FormControlLabel, FormGroup, Checkbox } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { USER_STATUS, USER_TEAM } from '../../../../constants';
import { GetUsersResponse } from '../../../../api/types';
import { useTelegram } from '../../../../hooks/useTelegram';
import { UserManagementFormSchema, userManagementFormSchema } from './userManagementForm.schema';
import { getObjectDifferences } from '../../../../utils/getObjectDifferences';
import { ControlledSwitch } from '../../../common/ControlledSwitch/ControlledSwitch';
import { Input } from '../../../common/input';
import { usersApi } from '../../../../api/usersApi';

type UserManagementFormProps = {
    user: GetUsersResponse['data'][number];
};

export function UserManagementForm({ user }: UserManagementFormProps) {
    const { colorScheme } = useTelegram();
    const [updateUser, { isLoading }] = usersApi.useUpdateMutation();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        formState: { dirtyFields },
        reset,
    } = useForm<UserManagementFormSchema>({
        resolver: zodResolver(userManagementFormSchema),
        defaultValues: {
            name: user.name,
            surname: user.surname,
            isAdmin: user.isAdmin,
            team: user.team,
        },
    });

    const onSubmit: SubmitHandler<UserManagementFormSchema> = async (data) => {
        const whitelistKeys = ['name', 'surname', 'isAdmin', 'team'];

        const updatedUser = {
            ...user,
            ...data,
        };

        const payload = getObjectDifferences(updatedUser, user, whitelistKeys);

        const isTeamChanged = Object.prototype.hasOwnProperty.call(payload, 'team');

        if (isTeamChanged && payload.team?.length) {
            payload.status = USER_STATUS.ACTIVE;
        }

        if (isTeamChanged && !payload.team?.length) {
            payload.status = USER_STATUS.PENDING;
        }

        await updateUser({ id: user.id, ...payload });
    };

    const handleTeamChange = (item: string, checked: boolean) => {
        const currentSelectedItems = getValues('team') ?? [];
        const updatedSelectedItems = checked
            ? [...currentSelectedItems, item]
            : currentSelectedItems.filter((i) => i !== item);
        if (updatedSelectedItems.length === 0) {
            setValue('team', null, { shouldDirty: true });
            return;
        }
        setValue('team', updatedSelectedItems, { shouldDirty: true });
    };

    useEffect(() => {
        reset({
            name: user.name,
            surname: user.surname,
            isAdmin: user.isAdmin,
            team: user.team,
        });
    }, [user]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth>
                <Stack gap={2}>
                    <Input
                        disabled
                        register={register}
                        name="name"
                        size="small"
                        variant="outlined"
                    />
                    <Input
                        disabled
                        register={register}
                        name="surname"
                        size="small"
                        variant="outlined"
                    />
                    <ControlledSwitch control={control} name="isAdmin" label="Admin" />
                    <FormGroup>
                        {Object.entries(USER_TEAM).map(([_, value], _index) => (
                            <FormControlLabel
                                sx={{
                                    color: colorScheme === 'dark' ? '#fff' : '#000',
                                    userSelect: 'none',
                                }}
                                label={value}
                                key={value}
                                control={
                                    <Checkbox
                                        checked={watch('team')?.includes(value)}
                                        onChange={(e) => {
                                            handleTeamChange(value, e.target.checked);
                                        }}
                                        disabled={isLoading}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                    <LoadingButton
                        loading={isLoading}
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={!Object.keys(dirtyFields).length}
                    >
                        Submit Changes
                    </LoadingButton>
                </Stack>
            </FormControl>
        </form>
    );
}
