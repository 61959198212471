/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsxImportSource @emotion/react */
import dayjs from 'dayjs';
import React from 'react';
import { useTelegram } from '../../../../hooks/useTelegram';

import styles from './styles';

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

interface OfficeSpaceProps {
    /* The name of the office space (e.g. 20, 21) */
    id: string;
    /* The name of the selected office space in case we have this functionality */
    selected?: string;
    /* If true then the onClick handler will not work */
    disabled?: boolean;
    /* Space inner content */
    render?: React.ReactElement;
    /* If true */
    isBooked?: boolean;
    /* If true */
    isMyBook?: boolean;
    /* Callback fired when user clicks the office space */
    onClick?: (id: string) => void;
}

function OfficeSpace({
    id,
    selected,
    onClick,
    render,
    disabled,
    isBooked,
    isMyBook,
}: OfficeSpaceProps) {
    const { colorScheme } = useTelegram();

    const stylesOptions = {
        active: Boolean(selected === id),
        disabled,
        isBooked,
        isMyBook,
        hoverStyles: Boolean(onClick),
        colorScheme,
    };

    const handleClick = () => {
        if (!disabled) {
            onClick?.(id);
        }
    };

    if (onClick) {
        return (
            <div
                onClick={handleClick}
                css={styles.spaceStyles(stylesOptions)}
                className="officeSpace"
            >
                {render ? (
                    <div className="userName">{render}</div>
                ) : (
                    <p className="spaceName">{id}</p>
                )}
            </div>
        );
    }

    return (
        <div css={styles.spaceStyles(stylesOptions)} className="officeSpace">
            {render ? <div className="userName">{render}</div> : <p className="spaceName">{id}</p>}
        </div>
    );
}

export default OfficeSpace;
