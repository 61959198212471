import { z } from 'zod';

export const manageDeskBookingsFiltersSchema = z.object({
    userId: z.string().nullable().optional(),
    deskNumber: z.string().nullable().optional(),
    startDate: z.string().nullable().optional(),
    endDate: z.string().nullable().optional(),
});

export type ManageDeskBookingsFiltersSchema = z.infer<typeof manageDeskBookingsFiltersSchema>;
