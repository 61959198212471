/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '../../../../assets/closeIcon';
import { useTelegram } from '../../../../hooks/useTelegram';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { setEditedUserSpace } from '../../../../store/reducers/officeSpace';

import styles from './styles';

interface BookInfoModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
    spaceName: string;
    onDeleteWorkPlaceHandler: () => void;
    setEditWorkPlaceActive: React.Dispatch<React.SetStateAction<boolean>>;
    isMyBooking: boolean;
    setIsMyBooking: React.Dispatch<React.SetStateAction<boolean>>;
    startDateState?: string;
    endDateState?: string;
    setStartDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
    setEndDateState: React.Dispatch<React.SetStateAction<string | undefined>>;
    setSpaceIdState: React.Dispatch<React.SetStateAction<string | null>>;
    setIsEditClosed: React.Dispatch<React.SetStateAction<boolean>>;
}

export function BookInfoModal({
    setOpen,
    open,
    spaceName,
    setEditWorkPlaceActive,
    onDeleteWorkPlaceHandler,
    isMyBooking,
    setIsMyBooking,
    startDateState,
    endDateState,
    setEndDateState,
    setStartDateState,
    setSpaceIdState,
    setIsEditClosed,
}: BookInfoModalProps): ReactElement {
    const [infoText, setInfoText] = useState('');
    const [title, setTitle] = useState('');

    const { tgUserId, colorScheme } = useTelegram();

    const bookedSpaces = useAppSelector((store) => store.officeSpaceReducer.bookedSpaces);

    const editedSpaceId = useAppSelector((store) => store.officeSpaceReducer.editedUserSpace?.id);

    const editedSpace = bookedSpaces.find(
        (s) => s.spaceUser.telegramUserId === tgUserId && s.userSpaceId !== editedSpaceId,
    );

    const dispatch = useDispatch();

    const [queryParams, setQueryParams] = useSearchParams();

    const onEditBtnClickHandler = () => {
        if (editedSpace) {
            queryParams.set('startDate', editedSpace.startDate || '');

            if (editedSpace.endDate) {
                queryParams.set('endDate', editedSpace.endDate);
            } else {
                queryParams.delete('endDate');
            }

            queryParams.set('spaceId', editedSpace.userSpaceId);

            queryParams.set('spaceName', editedSpace.name);

            queryParams.set('isEdited', 'true');

            setQueryParams(queryParams);

            setIsEditClosed(false);

            setSpaceIdState(editedSpace.userSpaceId);

            setStartDateState(editedSpace.startDate);

            if (editedSpace.endDate) {
                setEndDateState(editedSpace.endDate);
            }

            dispatch(
                setEditedUserSpace({
                    newEditedUserSpace: {
                        id: editedSpace.userSpaceId,
                        name: editedSpace.name,
                        startDate: editedSpace.startDate,
                        endDate: editedSpace.endDate,
                        isRepeatAlways: editedSpace.isRepeatAlways,
                    },
                }),
            );

            setEditWorkPlaceActive(true);

            setOpen(false);
        }
    };

    const startDateString = dayjs(editedSpace?.startDate).format('DD.MM.YYYY');
    const endDateString = dayjs(editedSpace?.endDate).format('DD.MM.YYYY');

    useEffect(() => {
        if (isMyBooking && editedSpace?.spaceUser.telegramUserId === tgUserId) {
            setInfoText('What would you like to do?');
            if (editedSpace?.isRepeatAlways) {
                setTitle(`${spaceName}`);
            } else if (editedSpace?.endDate) {
                setTitle(`${spaceName} - ${startDateString}-${endDateString}`);
            } else {
                setTitle(`${spaceName} - ${startDateString}`);
            }
        } else if (editedSpace?.spaceUser.telegramUserId === tgUserId) {
            if (editedSpace?.isRepeatAlways) {
                setInfoText(`You have already booked a DESK.
        You can’t make 2 or more bookings on the same day.`);
            } else {
                setInfoText(
                    `You have already booked a DESK on
    ${editedSpace?.endDate ? `${startDateString}-${endDateString}` : `${startDateString}`}.
    You can’t make 2 or more bookings on the same day.`,
                );
            }
            if (editedSpace?.isRepeatAlways) {
                setTitle(`${spaceName}`);
            } else if (editedSpace?.endDate) {
                setTitle(`${spaceName} - ${startDateString}-${endDateString}`);
            } else {
                setTitle(`${spaceName} - ${startDateString}`);
            }
        }
    }, [isMyBooking, editedSpace]);

    const onCloseHandler = () => {
        setOpen(false);
    };

    return (
        <Dialog
            onClose={() => setOpen(false)}
            open={open}
            css={styles.dialogStyles({ colorScheme })}
        >
            <div css={styles.modalWrapperStyles}>
                <div css={styles.headerWrapperStyles}>
                    <div css={styles.closeBtnWrapperStyles}>
                        <IconButton
                            css={styles.closeBtnStyles({ colorScheme })}
                            onClick={onCloseHandler}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div>
                        <h3 css={styles.headerTextStyles}>{title}</h3>
                    </div>
                </div>
                <div>
                    <p css={styles.modalInfoTextStyles({ colorScheme })}>{infoText}</p>
                </div>
                <div css={styles.btnWrapperStyles}>
                    <button
                        type="button"
                        css={styles.editBtnStyles}
                        onClick={onEditBtnClickHandler}
                    >
                        Edit
                    </button>
                    <button
                        type="button"
                        css={styles.cancelBtnStyles({ colorScheme })}
                        onClick={onDeleteWorkPlaceHandler}
                    >
                        Delete
                    </button>
                </div>
            </div>
        </Dialog>
    );
}
