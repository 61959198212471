import React from 'react';
import { MEETING_ROOMS_TIMES } from '../../../../constants';
import TimeRow from '../../timeRow';

export const ScheduleTimeline: React.FC = () => {
    return (
        <>
            {MEETING_ROOMS_TIMES.map((t) => (
                <TimeRow time={t} key={t} allMeetingRooms />
            ))}
        </>
    );
};
