import dayjs from 'dayjs';
import { MeetingRoomUser } from '../store/reducers/meetingRooms';

export const getMeetingRoomTimeDiff = (
    roomName: string,
    allMeetingRoomUsers: MeetingRoomUser[],
) => {
    const timeDiffMeetingRoom = allMeetingRoomUsers.reduce((acc, current) => {
        if (current.userMeetingRoomInfo.spaceName === roomName) {
            const startTime = dayjs(
                `${current.userMeetingRoomInfo.date} ${current.userMeetingRoomInfo.startTime}`,
            );
            const endTime = dayjs(
                `${current.userMeetingRoomInfo.date} ${current.userMeetingRoomInfo.endTime}`,
            );

            acc += endTime.diff(startTime);
        }
        return acc;
    }, 0);

    return timeDiffMeetingRoom;
};
