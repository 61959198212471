/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import facepaint from 'facepaint';
import { BREAKPOINTS } from '../../../../constants';

const mq = facepaint(BREAKPOINTS.map((bp) => `@media (min-width: ${bp}px)`));

const wrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    position: 'relative',
    justifyContent: 'space-between',
});

const firstColumnWrapperStyles = css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
});

const spaceRightColumnStyles = css({
    display: 'flex',
    flexDirection: 'column',
});

const spaceLeftColumnStyles = css(
    mq({
        display: 'flex',
        flexDirection: 'column',
        marginRight: ['4.15px', '3.65px', '5px', '5px'],
    }),
);

const secondColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    }),
);

const thirdColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    }),
);

const fourthColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    }),
);

const emptyRowStyles = css(
    mq({
        width: ['29.89px', '26.88px', '35px', '35px'],
        height: ['56.49px', '41.47px', '55px', '55px'],
        backgroundColor: 'inherit',
        marginBottom: ['4.15px', '3.45px', '5px', '5px'],
    }),
);

const fifthColumnWrapperStyles = css(
    mq({
        marginLeft: ['20px', '24px', '20px', '20px'],
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100%',
        alignItems: 'flex-end',
    }),
);

const sixthColumnWrapperStyles = css({
    marginLeft: ['20px', '24px', '20px', '20px'],
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100%',
    alignItems: 'flex-end',
});

const seventhColumnWrapperStyles = css({
    marginLeft: ['20px', '24px', '20px', '20px'],
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    alignItems: 'flex-end',
});

const meetingRoomStyles = ({ isMeetingRoom }: { isMeetingRoom: boolean }) => {
    return css({
        '@media (hover: hover) and (pointer: fine)': {
            '&:hover': {
                opacity: isMeetingRoom ? '0.7' : 'none',
            },
        },
        cursor: isMeetingRoom ? 'pointer' : 'default',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '50px',
        minHeight: '50px',
        backgroundColor: '#e0d5f5',
        color: 'black',
        border: isMeetingRoom ? '1px solid #8342ff' : '1px solid white',
    });
};

const thirdMeetingSpaceStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '50px',
    minHeight: '50px',
    backgroundColor: '#e0d5f5',
    color: 'black',
    border: '1px solid white',
    borderTop: 'none',
});

const bottomEnterStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    backgroundColor: '#51ad51',
    color: 'white',
    border: '1px solid white',
    borderRight: '1px solid white',
    height: '50px',
    marginTop: '50px',
    minWidth: '50px',
    fontSize: '12px',
});

const closeBtnStyles = css({
    position: 'absolute',
    top: '-50px',
    left: '-10px',
});

const styles = {
    wrapperStyles,
    firstColumnWrapperStyles,
    spaceRightColumnStyles,
    spaceLeftColumnStyles,
    secondColumnWrapperStyles,
    thirdColumnWrapperStyles,
    fourthColumnWrapperStyles,
    emptyRowStyles,
    fifthColumnWrapperStyles,
    sixthColumnWrapperStyles,
    seventhColumnWrapperStyles,
    meetingRoomStyles,
    thirdMeetingSpaceStyles,
    bottomEnterStyles,
    closeBtnStyles,
};

export default styles;
